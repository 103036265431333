import { useMemo } from 'react'

export const useSumAddedProductsBy = (
    items,
    course,
    percent,
    personalPercent,
    personalPercentAdmdin,
    purchaseCourse
) => {
    const total = useMemo(
        () =>
            items?.reduce(function (sum, { price, status, quantity, preQuantity }) {
                return sum + price * quantity
            }, 0),
        [items]
    )

    if (!items || !items.length) {
        return 0
    }

    if (personalPercentAdmdin) {
        return (total * (purchaseCourse || course) * personalPercentAdmdin).toFixed(2)
    }

    if (personalPercent) {
        return (total * (purchaseCourse || course) * personalPercent).toFixed(2)
    }

    if (course && percent) {
        return (total * (purchaseCourse || course) * percent).toFixed(2)
    }

    return 0
}
