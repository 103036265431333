import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { Tag, Modal, message, Alert, notification } from 'antd'
import AddOrderInfo from '../../components/AddOrderInfo/AddOrderInfo'
import OrderInfo from '../../components/OrderInfo/OrderInfo'
import Loader from '../../components/shared/Loader'
import { getOpenPurchases } from '../../redux/actions/usersAction'
import { PageInner } from '../../components/shared/PageInner'
import { useOrdersByPurchase } from '../../hooks/useOrdersByPurchase'
import TitlePage from '../../components/shared/TitlePage'
import { useFormula } from '../../hooks/useFormula'
import AddScreenshotReceipt from '../../components/AddScreenshotReceipt/AddScreenshotReceipt'
import ProductList from '../../components/ProductList/ProductList'

const Wrapper = styled.section`
    .ant-tag {
        padding: 10px;
        border-radius: 4px;
        font-size: 14px;
    }
`

const PurchaseInfo = styled.div`
    text-align: center;
    margin-bottom: 16px;
`

function PurchaseAddPage() {
    const dispatch = useDispatch()

    const params = useParams()
    const token = useSelector((state) => state.token)
    const { openPurchases } = useSelector((state) => state.users)
    const { isLoading: isLoadingPurchases } = useSelector((state) => state.users)

    const { isLoadingFormula, formula, errorFormula } = useFormula()

    const [isLoading, setIsLoading] = useState(false)

    const [isModalScreenshotVisible, setIsModalScreenshotVisible] = useState(false)

    const [isLoadingOrder, orders, setOrder] = useOrdersByPurchase(params.id, token)

    const selectPurchase = openPurchases.filter((item) => item._id === params.id)[0]

    const showModalScreenShot = () => {
        setIsModalScreenshotVisible(true)
    }

    const handleCancelModalScreenShot = () => setIsModalScreenshotVisible(false)

    const handleUpdateOrder = useCallback(
        (newOrder) => {
            setOrder(newOrder)
        },
        [setOrder]
    )

    const openNotification = (placement) => {
        notification.info({
            description: `Вы находитесь в черном списке.
                Обратитесь к администратору для выяснения обстоятельств!`,
            duration: 20,
            placement
        })
    }

    const onFinish = async (info) => {
        setIsLoading(true)

        try {
            const res = await axios.post(
                `/order/create/${params.id}`,
                { info },
                {
                    headers: { Authorization: token }
                }
            )

            if (res?.data?.status === 'error-isBlackList') {
                setIsLoading(false)

                openNotification('topLeft')
                return
            }

            setIsLoading(false)
            setOrder([res.data.newOrder])
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        dispatch(getOpenPurchases(token))
    }, [token, dispatch])

    if (isLoadingOrder || isLoadingFormula || isLoadingPurchases) {
        return <Loader />
    }

    if (errorFormula) {
        return <div className='container'>Что-то пошло не так...</div>
    }

    if (selectPurchase && selectPurchase.status === false && orders && orders.length === 0) {
        return (
            <div className='container' style={{ marginTop: 30 }}>
                Вы не участвовали в данной закупке
            </div>
        )
    }

    return (
        <Wrapper>
            <div className='container'>
                <PageInner>
                    <PurchaseInfo>
                        <TitlePage>{selectPurchase && selectPurchase.title}</TitlePage>

                        <Tag color='magenta'>
                            Дата "СТОПа" -{' '}
                            {selectPurchase &&
                                selectPurchase.dateEnd &&
                                format(new Date(selectPurchase.dateEnd), 'dd.MM.yyyy')}
                        </Tag>
                    </PurchaseInfo>

                    {orders.length !== 0 ? (
                        <OrderInfo
                            order={orders}
                            handleUpdateOrder={handleUpdateOrder}
                            showModalScreenShot={showModalScreenShot}
                            purchaseStatus={selectPurchase && selectPurchase.status}
                        />
                    ) : (
                        <AddOrderInfo onFinish={onFinish} isLoading={isLoading} />
                    )}

                    <ProductList
                        orders={orders}
                        formula={formula}
                        selectPurchase={selectPurchase}
                        purchaseId={params.id}
                    />
                </PageInner>
            </div>

            <Modal
                title='Добавление фото чека:'
                visible={isModalScreenshotVisible}
                onCancel={handleCancelModalScreenShot}
                footer={null}
            >
                <AddScreenshotReceipt
                    order={orders[0]}
                    handleUpdateOrder={handleUpdateOrder}
                    handleCancel={handleCancelModalScreenShot}
                />
            </Modal>
        </Wrapper>
    )
}

export default PurchaseAddPage
