import React, { useState, useRef, useMemo, Fragment, useContext } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import { Table, Tag, Input, Space, Button, Tooltip, Select } from 'antd'
import { CarOutlined, PlusCircleOutlined, SearchOutlined, LinkOutlined } from '@ant-design/icons'
import { PageInner } from '../../components/shared/PageInner'
import OrderStatusInTable from '../../components/OrderStatusInTable/OrderStatusInTable'
import ModalEditOrderFromAdmin from '../../components/ModalEditOrderFromAdmin/ModalEditOrderFromAdmin'
import ModalAddCommenInOrder from '../../components/ModalAddCommenInOrder/ModalAddCommenInOrder'
import PopoverComment from '../../components/PopoverComment/PopoverComment'
import { getColumnSearchProps } from '../../utils/getColumnSearchProps'
import ModalAddTracker from '../../components/ModalAddTracker/ModalAddTracker'
import { usePurchase } from './usePurchase'
import ProfitSumProductsByManager from '../../components/ProfitSumProductsByManager/ProfitSumProductsByManager'
import ModalProductsByOrderAdmin from '../../components/ModalProductsByOrderAdmin/ModalProductsByOrderAdmin'
import { OrdersContext } from '.'
import { useTotalSumProfitByManager } from '../../hooks/useTotalSumProfitByManager'
import { useFormula } from '../../hooks/useFormula'
import Loader from '../../components/shared/Loader'

const { Option } = Select

const TitleWrap = styled.div`
    text-align: center;
`

const StyledSumProfit = styled.div`
    text-align: center;
    margin-top: 15px;
    font-size: 20px;
`

const StyledSelectWrap = styled.div`
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .ant-select {
        width: 100%;
    }

    h2 {
        margin-bottom: 10px;
    }
`

const TableWrap = styled.div`
    overflow-x: scroll;

    width: 100%;
    margin-top: 30px;

    table {
        .ant-table-cell {
            padding: 8px;
        }

        .ant-table-row {
            &.packed {
                background-color: #fff9a861;
            }

            &.paidFor {
                background-color: #ffd7d0a7;
            }

            &.completed {
                background-color: #affaea4b;
            }
        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background: #f5f1f1;
        }

        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #cacaca;
        }

        .ant-table-filter-column {
            svg {
                color: inherit;
                width: 16px;
                height: 16px;
            }
        }

        .ant-tooltip {
            z-index: -1 !important;
        }
    }
`

const ProductItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;

    .checkDefects {
        color: tomato;
        margin-left: 6px;
    }

    &.no {
        color: tomato;
    }

    &.yes {
        color: #33c733;
    }

    &.return {
        background-color: #000;
        color: #fff;
        border-radius: 2px;
        padding: 2px 4px;
    }

    &.yesMoscow {
        color: purple;
    }

    &.pending {
        background-color: #ffa99a;
        color: white;
        padding: 0 4px;
        border-radius: 2px;

        .checkDefects {
            color: white;
            margin-left: 6px;
        }

        .popoverPosition {
            svg {
                fill: white;
            }
        }
    }

    .linkIcon {
        margin-left: 6px;
    }
`

const ProductItemInStock = styled.div`
    position: relative;
    border-radius: 4px;
    background-color: ${(props) => (props.status === 'yes' ? '#66CDAA' : '#fff')};
    box-shadow: 1px 4px 9px -3px rgba(77, 77, 77, 0.2);
    border: 1px solid orange;

    p {
        position: absolute;
        z-index: 20;
        left: 6px;
        top: -8px;
        font-size: 8px;
        line-height: 8px;
        white-space: nowrap;
        padding: 2px 4px;
        border-radius: 2px;
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
        color: #389e0d;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 4px 6px;
        color: ${(props) => (props.status === 'yes' ? '#fff' : '#000')};
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    span {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        font-size: 8px;
        line-height: 8px;
        border-radius: 50%;
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const AddProductLink = styled.div`
    display: flex;
    align-items: center;

    a {
        color: inherit;
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }

        &:hover {
            color: #1890ff;
        }
    }
`

const AddTrackerWrap = styled.div`
    display: flex;
    align-items: center;

    span {
        &.delivery {
            margin-left: 6px;
        }
    }
`

const UserNameControl = styled.div`
    display: inline-flex;
    align-items: center;
`

const deliveryJSX = {
    belpochta: 'Белпочта',
    evropochta: 'Европочта',
    geoMinsk: 'Доставка'
}

function AdminTableOrdersPage() {
    const token = useSelector((state) => state.token)
    const auth = useSelector((state) => state.auth)

    const {
        isLoadingManagers,
        managers,
        selectedManager,
        onChangeSelectedManager,
        isLoadingOrders,
        orders,
        getOrders,
        onUpdateProduct,
        onUpdateProductStatus,
        onUpdatePreQuantity,
        onUpdateStatusOrder
    } = useContext(OrdersContext)

    const isLoading = useSelector((state) => state.admin.isLoading)
    const params = useParams()
    const history = useHistory()

    const search = window.location.search
    const paramsAll = new URLSearchParams(search)
    const titlePurshuase = paramsAll.get('title')

    const { isLoadingPurchase, purchase, getPurchase } = usePurchase(token, params.purchaseId)
    const { isLoadingFormula, formula, errorFormula } = useFormula()

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)

    const totalSumProfit = useTotalSumProfitByManager(orders, selectedManager?.item)

    const [page, setPage] = useState(1)
    const [backPage, setBackPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    function handleReset(clearFilters) {
        clearFilters()
        setSearchText('')
    }

    const onShowSizeChange = (current, size) => {
        setPageSize(size)
    }

    const columns = useMemo(
        () => [
            {
                title: '@',
                dataIndex: 'status',
                filters: [
                    {
                        text: 'Возврат',
                        value: 'return'
                    },
                    {
                        text: 'Уточняется',
                        value: 'pending'
                    },
                    {
                        text: 'Без статуса',
                        value: null
                    }
                ],

                onFilter: (value, record) => {
                    let products = [...record.products]
                    products = record.products.filter(function (product) {
                        return product.status === value
                    })

                    return products.length
                },

                render: (text, record, index) => {
                    return (
                        <OrderStatusInTable
                            orderId={record._id}
                            statusCurrent={text}
                            onUpdateStatusOrder={onUpdateStatusOrder}
                            ordersList={orders}
                        />
                    )
                }
            },

            {
                title: 'Номер',
                dataIndex: 'articleOrder',
                width: 50,
                ...getColumnSearchProps(
                    'articleOrder',
                    searchInput,
                    handleSearch,
                    handleReset,
                    searchedColumn,
                    searchText,
                    formula,
                    purchase,
                    selectedManager,
                    true
                )
            },

            {
                title: 'ФИО',
                dataIndex: 'userName',
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Поиск по тел и ФИО`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, 'userName')}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />

                        <Space>
                            <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm, 'userName')}
                                icon={<SearchOutlined />}
                                size='small'
                                style={{ width: 90 }}
                            >
                                Поиск
                            </Button>

                            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                                Сброс
                            </Button>
                        </Space>
                    </div>
                ),

                filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

                onFilter: (value, record) =>
                    record.info.phone.toString().toLowerCase().includes(value.toLowerCase()) ||
                    record.info.userName.toString().toLowerCase().includes(value.toLowerCase()),

                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current.select())
                    }
                },

                render: (text, record, index) => {
                    return (
                        <>
                            {record.isAdmin && (
                                <AddProductLink>
                                    <Tag color='green'>ByAdmin</Tag>

                                    <NavLink to={`/purchase/${params.id}/add-product/${record._id}`}>
                                        <PlusCircleOutlined />
                                    </NavLink>

                                    <ModalAddCommenInOrder order={record} />
                                    <ModalEditOrderFromAdmin order={record} />
                                </AddProductLink>
                            )}

                            <AddProductLink>
                                {record.info.userName.length >= 15 ? (
                                    <Tooltip title={`${record.info.userName}`}>
                                        <span>
                                            {record.info.userName.length >= 15 ? (
                                                <ModalProductsByOrderAdmin
                                                    orderId={record._id}
                                                    isAdmin={record.isAdmin}
                                                    userName={`${record.info.userName.substr(0, 15)}...`}
                                                    products={record?.products || []}
                                                    onUpdateProduct={onUpdateProduct}
                                                    onUpdateProductStatus={onUpdateProductStatus}
                                                    onUpdatePreQuantity={onUpdatePreQuantity}
                                                    getOrders={getOrders}
                                                />
                                            ) : (
                                                <ModalProductsByOrderAdmin
                                                    orderId={record._id}
                                                    isAdmin={record.isAdmin}
                                                    userName={record.info.userName}
                                                    products={record?.products || []}
                                                    onUpdateProduct={onUpdateProduct}
                                                    onUpdateProductStatus={onUpdateProductStatus}
                                                    onUpdatePreQuantity={onUpdatePreQuantity}
                                                    getOrders={getOrders}
                                                />
                                            )}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <ModalProductsByOrderAdmin
                                        orderId={record._id}
                                        isAdmin={record.isAdmin}
                                        userName={record.info.userName}
                                        products={record?.products || []}
                                        onUpdateProduct={onUpdateProduct}
                                        onUpdateProductStatus={onUpdateProductStatus}
                                        onUpdatePreQuantity={onUpdatePreQuantity}
                                        getOrders={getOrders}
                                    />
                                )}
                            </AddProductLink>

                            <span>{record.info.phone}</span>

                            <br />

                            <UserNameControl>
                                {!record.isAdmin && <ModalEditOrderFromAdmin order={record} />}
                                {!record.isAdmin && <ModalAddCommenInOrder order={record} />}
                            </UserNameControl>
                        </>
                    )
                }
            },

            {
                title: 'Адрес',
                dataIndex: 'address',
                render: (text, record, index) => {
                    return (
                        <>
                            {record?.info.address && record.info.address.length >= 15 ? (
                                <Tooltip title={`${record.info.address}`}>
                                    <span>
                                        {record.info.address.length >= 15
                                            ? `${record.info.address.substr(0, 15)}...`
                                            : record.info.address}
                                    </span>
                                </Tooltip>
                            ) : (
                                record.info.address
                            )}

                            <AddTrackerWrap>
                                <CarOutlined /> <span className='delivery'>{deliveryJSX[record.info.delivery]}</span>
                                {record.info.delivery === 'belpochta' || record.info.delivery === 'evropochta' ? (
                                    <ModalAddTracker order={record} />
                                ) : null}
                            </AddTrackerWrap>
                        </>
                    )
                }
            },

            {
                title: '№',
                dataIndex: 'productNumber',

                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Поиск по логину`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, 'userLogin')}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />

                        <Space>
                            <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm, 'userLogin')}
                                icon={<SearchOutlined />}
                                size='small'
                                style={{ width: 90 }}
                            >
                                Поиск
                            </Button>

                            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                                Сброс
                            </Button>
                        </Space>
                    </div>
                ),

                filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

                onFilter: (value, { author, info, products }) => {
                    const product = products && products.find((item) => item.productNumber === +value)
                    return (
                        product &&
                        product.productNumber &&
                        product.productNumber.toString().toLowerCase().includes(value.toLowerCase())
                    )
                },

                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current.select())
                    }
                },

                render: (text, record, index) => {
                    return record.products.map((item, productIndex) => (
                        <p key={`productNumber_${productIndex}`}>{item.productNumber}</p>
                    ))
                }
            },

            {
                title: 'Продукты',
                dataIndex: 'products',
                render: (text, record, index) => {
                    const arrProducts = text.map((item) => {
                        return { ...item, type: 'default' }
                    })

                    let arrProductsInStock = []

                    if (record?.productsInStock) {
                        record.productsInStock.map((item) => {
                            arrProductsInStock.push({
                                ...item,
                                type: 'inStock'
                            })
                        })
                    }

                    const allArr = [...arrProducts, ...arrProductsInStock]

                    return text.map((item) => (
                        <ProductItem key={item._id} className={item.status}>
                            {item.nameProduct.length >= 15 ? (
                                <Tooltip title={`${item.nameProduct}`}>
                                    <span>
                                        {item.nameProduct.length >= 15
                                            ? `${item.nameProduct.substr(0, 15)}...`
                                            : item.nameProduct}
                                    </span>
                                </Tooltip>
                            ) : (
                                item.nameProduct
                            )}

                            {item.checkDefects && <b className='checkDefects'>(Б)</b>}

                            {item.commentAdmin && (
                                <PopoverComment comment={item.commentAdmin} className='popoverPosition' />
                            )}

                            {item.productLink ? (
                                <a href={`${item.productLink}`} target='_blank' rel='noopener noreferrer'>
                                    <LinkOutlined className='linkIcon' />
                                </a>
                            ) : null}
                        </ProductItem>
                    ))
                }
            },

            {
                title: 'Цвет',
                dataIndex: 'products',
                render: (text, record, index) => {
                    return text.map((item) => (
                        <Fragment key={item._id}>
                            {item.color.length >= 10 ? (
                                <Tooltip title={`${item.color}`}>
                                    <span>
                                        {item.color.length >= 10 ? (
                                            <ProductItem key={item._id}>{`${item.color.substr(0, 10)}...`}</ProductItem>
                                        ) : (
                                            <ProductItem key={item._id}>{item.color}</ProductItem>
                                        )}
                                    </span>
                                </Tooltip>
                            ) : (
                                <ProductItem>{item.color}</ProductItem>
                            )}
                        </Fragment>
                    ))
                }
            },

            {
                title: 'Размер',
                dataIndex: 'products',
                render: (text, record, index) => {
                    return text.map((item) =>
                        item.size.length >= 8 ? (
                            <Tooltip title={`${item.size}`} key={item._id}>
                                <span>
                                    {item.size.length >= 8 ? (
                                        <ProductItem key={item._id}>{`${item.size.substr(0, 8)}...`}</ProductItem>
                                    ) : (
                                        <ProductItem key={item._id}>{item.size}</ProductItem>
                                    )}
                                </span>
                            </Tooltip>
                        ) : (
                            <ProductItem key={item._id}>{item.size}</ProductItem>
                        )
                    )
                }
            },

            {
                title: 'Кол-во',
                dataIndex: 'products',
                render: (text, record, index) => {
                    return text.map((item) => (
                        <ProductItem key={item._id}>
                            {!item.preQuantity ? `${item.quantity}` : `${item.preQuantity} из ${item.quantity}`}
                        </ProductItem>
                    ))
                }
            },

            {
                title: 'Цена рос.руб',
                dataIndex: 'products',
                width: 75,
                render: (text, record, index) => {
                    return text.map((item) => <ProductItem key={item._id}>{item.price}</ProductItem>)
                }
            },

            {
                title: 'Цена про-жи, бел.руб',
                dataIndex: 'products',
                width: 120,
                render: (text, record, index) => {
                    return text.map((item) => <ProductItem key={item._id}>{item.managerPrice}</ProductItem>)
                }
            },

            {
                title: 'Зар-ок',
                dataIndex: 'products',
                // width: 100,
                render: (text, record, index) => {
                    if (text.length !== 0) {
                        return <ProfitSumProductsByManager user={record?.author} products={text} />
                    }
                    return 0
                }
            }
        ],
        [params.id, searchText, searchedColumn, purchase, selectedManager?.id, orders]
    )

    const onChangeHandle = (pagination, filters, sorter, extra) => {
        if (extra.action === 'paginate') {
            setPage(pagination.current)
        }
        return
    }

    if (isLoading || isLoadingPurchase || isLoadingManagers || isLoadingOrders || isLoadingFormula) {
        return <Loader>Loading...</Loader>
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <TitleWrap>
                        <h2>Итоговая таблица по закупке - {purchase?.title}:</h2>
                    </TitleWrap>
                </div>
            </PageInner>

            <StyledSelectWrap>
                <h2>Заказы менеджера - {selectedManager?.managerName}</h2>

                {[managers].length > 0 && (
                    <Select defaultValue={selectedManager?.managerName} onChange={onChangeSelectedManager} size='large'>
                        {[{ _id: 'all', managerName: 'Все' }, ...managers].map((item) => (
                            <Option value={item.managerName} key={item._id} item={item}>
                                {item.managerName}
                            </Option>
                        ))}
                    </Select>
                )}
            </StyledSelectWrap>

            <StyledSumProfit>
                {totalSumProfit && <div>Итоговая сумма со всей закупки = {totalSumProfit}</div>}
            </StyledSumProfit>

            <PageInner>
                <div className='container'>
                    <TableWrap>
                        <Table
                            columns={columns}
                            dataSource={orders}
                            onChange={onChangeHandle}
                            rowKey='_id'
                            rowClassName={(record) => record?.status && `${record?.status}`}
                            pagination={{
                                pageSize: pageSize,
                                total: orders.length,
                                current: page,
                                onShowSizeChange: onShowSizeChange
                            }}
                        />
                    </TableWrap>
                </div>
            </PageInner>
        </section>
    )
}

export default AdminTableOrdersPage
