import React, { useEffect, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { NavLink, useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { Card, Popconfirm, message, Button, Space, Alert } from 'antd'
import { PageInner } from '../../components/shared/PageInner'
import Loader from '../../components/shared/Loader'

const StyledPageInner = styled(PageInner)`
    width: 100%;
    padding-top: 20px;
`

const Title = styled.h2`
    margin-bottom: 30px;
`

const CardList = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;

    .ant-card-head-title {
        margin-right: 20px;
    }

    .card {
        width: 100%;

        &.test {
            background: #ffc0b5;
        }
    }

    .cardLink {
        display: block;
        margin-top: 15px;
    }
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(33.333333% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;

    .fullWidth {
        width: 100%;
    }

    .rowBtns {
        display: flex;
        justify-content: space-between;

        .linkStop {
            color: tomato;
            text-align: center;
            display: block;
        }
    }

    @media (max-width: 420px) {
        max-width: 100%;
    }
`

const DateWrap = styled.div`
    font-size: 16px;
    margin-bottom: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 420px) {
            flex-direction: column;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
`

function ManagerOpenPurchase({ isManager }) {
    const token = useSelector((state) => state.token)
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [purchase, setPurchase] = useState([])

    const handleUpdateMessagePurchase = useCallback(
        (id, message) => {
            const newWarningMessage = !message ? null : message

            const newArr = purchase.map((item) => {
                if (item._id === id) {
                    const newPurchase = { ...item, warningMessage: newWarningMessage }
                    return newPurchase
                }
                return item
            })

            setPurchase(newArr)
        },
        [purchase]
    )

    const getPurchase = async () => {
        setIsLoading(true)

        try {
            const res = await axios.get(`/purchase/open`, {
                headers: { Authorization: token }
            })

            if (res?.data?.length) {
                const purchaseNoTest = res.data.filter((item) => !item.isTest)
                setPurchase(purchaseNoTest)
            }

            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log('err', err)
        }
    }

    async function confirm(id) {
        try {
            const { data } = await axios.patch(
                `/purchase/update/${id}`,
                { status: false },
                {
                    headers: { Authorization: token }
                }
            )

            message.success(data.msg)
            getPurchase()
        } catch (err) {
            console.log(err)
            message.success(err.response.data.msg)
        }
    }

    useEffect(() => {
        getPurchase()
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <StyledPageInner>
                        <Title>Открытые закупки:</Title>
                        {purchase.length !== 0 ? (
                            <CardList>
                                {purchase.map((item) => (
                                    <CardWrap isTest={item.isTest} key={item._id}>
                                        <Card
                                            title={item.title}
                                            extra={
                                                <NavLink to={`/manager/table-orders/${item._id}?title=${item.title}`}>
                                                    Перейти к таблице
                                                </NavLink>
                                            }
                                            className={`card ${item.isTest && 'test'}`}
                                        >
                                            <DateWrap>
                                                <p>
                                                    {' '}
                                                    <span>Начало сбора -</span>{' '}
                                                    {format(new Date(item.dateStart), 'dd.MM.yyyy')}
                                                </p>

                                                <p>
                                                    {' '}
                                                    <span>Окончание сбора - </span>{' '}
                                                    {format(new Date(item.dateEnd), 'dd.MM.yyyy')}
                                                </p>

                                                <p>
                                                    {' '}
                                                    <span>Доставка по Минску -</span> {item.deliveryDays}
                                                </p>

                                                <p>
                                                    {' '}
                                                    <span>Отправка почты -</span> {item.mailDays}
                                                </p>
                                            </DateWrap>

                                            {item.warningMessage && (
                                                <Alert message={item.warningMessage} type='warning' showIcon />
                                            )}
                                        </Card>
                                    </CardWrap>
                                ))}
                            </CardList>
                        ) : (
                            <p>В настоящее время открытых закупок нет.</p>
                        )}
                    </StyledPageInner>
                </div>
            </PageInner>
        </section>
    )
}

export default ManagerOpenPurchase
