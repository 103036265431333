import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { dispatchLogin } from '../../redux/actions/authAction'
import { useDispatch } from 'react-redux'
import { GoogleLogin } from 'react-google-login'
import IconGoogle from '../../assets/icons/IconGoogle'


const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    
    h2 {
        margin-bottom: 20px;
    }
`

const Warning = styled.div`
    color: tomato;
    font-size: 18px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
`

const Info = styled.div`
    color: #222;
    font-size: 14px;
    padding: 28px;  
    text-align: center;
`

const CustomBtnGoogle = styled.button`
    display: flex;
    align-items: center;
    min-height: 40px;
    border: 1px solid #bebebe;
    cursor: pointer;
    border-radius: 4px;
    transition: all .3s ease;

    p {
        padding: 0 20px;
    }

    span {
        width: 50px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #bebebe;
        background-color: #fff;
        border-radius: 4px;
    }

    &:hover {
        background-color: rgb(66, 133, 244);
        color: #fff;
    }

    svg {
        width: 22px;
        height: 22px;
    }
`

function LoginPagev2() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)

    const responseGoogle = async (response) => {
        setIsLoading(true)
        try {
            await axios.post('/user/google_login', { tokenId: response.tokenId })

            localStorage.setItem('firstLogin', true)

            setIsLoading(false)
            dispatch(dispatchLogin())

            history.push('/')

        } catch (err) {
            console.log('err', err)
            setIsLoading(false)
        }
    }

    return (
        <Wrapper>
            {/* <Warning>Сайт находится в тестовом режиме, просьба - не совершать никаких действий!</Warning> */}

            <h2>Вход:</h2>

            <GoogleLogin
                clientId='261724857733-kvajar80os98pv5g8fa4ojct8he12fhj.apps.googleusercontent.com'
                render={renderProps => (
                    <CustomBtnGoogle onClick={renderProps.onClick} disabled={renderProps.disabled}>
                        <span><IconGoogle /></span>
                        <p>Войти через Google</p>
                    </CustomBtnGoogle>
                )}
                buttonText='Войти через Google'
                onSuccess={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />

            <Info>
                Если у вас нет аккаунта в Google, то вы можете зарегистрировать его
                <a href='https://accounts.google.com/b/0/signup?hl=ru' target='_blank' rel="noreferrer"> здесь</a>
            </Info>
        </Wrapper>
    )
}

export default LoginPagev2
