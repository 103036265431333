import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import { Popover, Radio } from 'antd'
import { setOrdersAdmin } from '../../redux/actions/adminActions'
import { SettingOutlined } from '@ant-design/icons'

const Wrapper = styled.div`
    cursor: pointer;

    .popver {
        width: 100%;
        text-align: left;
    }
`

const CardBtns = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .ant-radio-group {
        margin-left: auto;
    }
`

const OrderStatusInTable = ({ orderId, statusCurrent, refProp, onUpdateStatusOrder, ordersList }) => {
    const dispatch = useDispatch()

    const token = useSelector((state) => state.token)
    const orders = useSelector((state) => state.admin.orders)

    const handleUpdate = async (value) => {
        try {
            await axios.patch(
                `/order/update/${orderId}`,
                { status: value },
                {
                    headers: { Authorization: token }
                }
            )

            if (onUpdateStatusOrder) {
                onUpdateStatusOrder(ordersList, orderId, value)
                return
            }

            const newArr = orders.map((item) => {
                if (item._id === orderId) {
                    const newOrder = { ...item, status: value }
                    return newOrder
                }
                return item
            })

            dispatch(setOrdersAdmin(newArr))
        } catch (err) {
            console.log(err)
        }
    }

    const onChangeStatus = (e) => {
        handleUpdate(e.target.value)
    }

    const content = (
        <CardBtns>
            <Radio.Group onChange={onChangeStatus} value={statusCurrent}>
                <Radio value={'default'}>Без статуса</Radio>
                <Radio value={'paidFor'}>Оплачен</Radio>
                <Radio value={'packed'}>Упакован</Radio>
                <Radio value={'completed'}>Выполнен</Radio>
            </Radio.Group>
        </CardBtns>
    )

    return (
        <Wrapper>
            <Popover content={content} placement='topLeft' title='Статус заказа:' className='popver'>
                <SettingOutlined />
            </Popover>
        </Wrapper>
    )
}

export default memo(OrderStatusInTable)
