
import React, { useState } from 'react'
import { Form, Input, Button, Space } from 'antd'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'


const FormWrap = styled.div`
    width: 100%;    
    margin: 30px 0;
   
   form {
       width: 100%;
       max-width: 500px;
       margin: 0 auto;
       
       display: flex;
       justify-content: center;
       align-items: center; 

        .ant-input-number {
            width: 100%;
        }
   }
`

function AddCategory({ addCategories }) {
    const token = useSelector(state => state.token)

    const [isLoading, setIsLoading] = useState(false)

    const [form] = Form.useForm();

    const onFinish = async ({ name }) => {
        setIsLoading(true)

        try {
            const { data } = await axios.post(`/category/create`, { name }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            addCategories(data.newCategory)
            form.resetFields()

        } catch (err) {
            console.log('err-AddCategory', err);
            setIsLoading(false)
        }
    }

    return (
        <FormWrap>
            <Form
                form={form}
                name="addCategory"
                onFinish={onFinish}
                initialValues={{}}
            >

                <Space direction='horizontal'>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input placeholder='Название категории' />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={isLoading}>
                            Создать категорию
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </FormWrap>
    )
}

export default AddCategory
