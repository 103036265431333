import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { NavLink, useHistory } from 'react-router-dom'
import { Card, Tag } from 'antd'
import styled from 'styled-components'
import { PageInner } from '../../components/shared/PageInner'
import Loader from '../../components/shared/Loader'
import { dowloadPurchaseImages } from '../../utils/dowloadPurchaseImages'
import Button from '../../components/shared/Button'

const StyledPageInner = styled(PageInner)`
    width: 100%;
`

const Title = styled.h2`
    margin-bottom: 30px;
`

const CardList = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;

    .ant-card-head-title {
        margin-right: 20px;
    }

    .card {
        width: 100%;
        max-width: 320px;
    }

    .card__done {
        border: 1px solid tomato;
        border-radius: 6px;
    }

    .test {
        background: #ffc0b5;
    }

    .cardLink {
        display: block;
        margin-top: 15px;
    }

    .ant-tag {
        &.done {
            margin-bottom: 12px;
            font-size: 16px;
            padding: 8px;
        }
    }
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(325px - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
`

const BtnsWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    button {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .linkStop {
        color: tomato;
    }

    .ant-popover-inner {
        max-width: 205px;
    }
`

function AdminAllPurchase() {
    const history = useHistory()

    const token = useSelector((state) => state.token)

    const [isLoading, setIsLoading] = useState(false)

    const [purchases, setPurchases] = useState([])

    useEffect(() => {
        const getPurchases = async () => {
            setIsLoading(true)
            try {
                const res = await axios.get('/purchase/closed', {
                    headers: { Authorization: token }
                })
                setPurchases(res.data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }
        getPurchases()
    }, [token])

    if (isLoading) {
        return <Loader />
    }

    return (
        <PageInner>
            <div className='container'>
                <StyledPageInner>
                    <Title>Крайние завершенные закупки:</Title>

                    {purchases.length !== 0 ? (
                        <CardList>
                            {purchases.map((item) => (
                                <CardWrap key={item._id}>
                                    <Card
                                        title={item.title}
                                        extra={
                                            <NavLink to={`/admin/table-orders/${item._id}?title=${item.title}`}>
                                                Перейти к таблице
                                            </NavLink>
                                        }
                                        className={`card card__done ${item.isTest ? 'test' : ''}`}
                                    >
                                        <Tag color='red' className='done'>
                                            Сбор завершен
                                        </Tag>

                                        <p>Начало сбора - {format(new Date(item.dateStart), 'dd.MM.yyyy')}</p>
                                        <p>Окончание сбора - {format(new Date(item.dateEnd), 'dd.MM.yyyy')}</p>

                                        <BtnsWrap>
                                            <ul className='linkList'>
                                                <li>
                                                    <NavLink to={`/admin/table-belpochta/${item._id}`}>
                                                        Таблица "Белпочта"
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink to={`/admin/table-samovivoz/${item._id}`}>
                                                        Таблица "Самовывоз"
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink to={`/admin/table-evropochta/${item._id}`}>
                                                        Таблица "Европочта"
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink to={`/admin/table-geoMinsk/${item._id}`}>
                                                        Таблица "Доставка по Минску"
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink to={`/admin/table-screenshots/${item._id}`}>
                                                        Таблица "Фото чеков"
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink to={`/admin/table-sms/${item._id}`}>
                                                        Таблица для SMS
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink to={`/admin/table-managers/${item._id}`}>
                                                        Менеджеры
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </BtnsWrap>

                                        <BtnsWrap>
                                            <Button
                                                variant='primary'
                                                onClick={() => dowloadPurchaseImages(item._id, item.title, token)}
                                            >
                                                Скачать картинки
                                            </Button>

                                            <Button
                                                variant='secondary'
                                                // className='fullWidth'
                                                onClick={() => history.push(`/admin/statistics/${item._id}`)}
                                            >
                                                Статистика
                                            </Button>
                                        </BtnsWrap>
                                    </Card>
                                </CardWrap>
                            ))}
                        </CardList>
                    ) : (
                        <p>Здесь пока ничего нет!</p>
                    )}
                </StyledPageInner>
            </div>
        </PageInner>
    )
}

export default AdminAllPurchase
