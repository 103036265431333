import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const MenuList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;

    @media (max-width: 1170px) {
        flex-wrap: wrap;
    }

    @media (max-width: 420px) {
        flex-direction: column;
        margin: 0;
    }
`

const MenuItem = styled.li`
    &:not(:last-child) {
        margin-right: 30px;

        @media (max-width: 420px) {
            margin-right: 0;
        }
    }
`

function MenuAdmin() {
    return (
        <MenuList>
            <MenuItem>
                <NavLink to='/admin/open-purchases'>Открытые</NavLink>
            </MenuItem>

            <MenuItem>
                <NavLink to='/admin/all-purchases'>Закрытые</NavLink>
            </MenuItem>

            <MenuItem>
                <NavLink to='/admin/create-purchase'>Открыть сбор</NavLink>
            </MenuItem>

            <MenuItem>
                <NavLink to='/admin/clients'>Клиенты</NavLink>
            </MenuItem>

            <MenuItem>
                <NavLink to='/admin/managers'>Менеджеры</NavLink>
            </MenuItem>

            <MenuItem>
                <NavLink to='/admin/links'>Ссылки</NavLink>
            </MenuItem>

            <MenuItem>
                <NavLink to='/admin-settings'>Настройки</NavLink>
            </MenuItem>

            <MenuItem>
                <NavLink to='/admin/in-stock'>Пристрой</NavLink>
            </MenuItem>
        </MenuList>
    )
}

export default MenuAdmin
