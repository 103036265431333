import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { Modal } from 'antd'
import ProductCardAdmin from '../ProductCardAdmin/ProductCardAdmin'
import { setProductsByOrder } from '../../redux/actions/adminActions'
import Button from '../shared/Button'

const UserName = styled.p`
    cursor: pointer;
    transition: color 0.2s linear;

    &:hover {
        color: #1890ff;
    }
`

const RowBtns = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    button,
    a {
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
`

const ProductsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(50% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
`

function ModalOrdersAdmin({ orderId, userName, isAdmin }) {
    const dispatch = useDispatch()
    const params = useParams()

    const productsByOrder = useSelector((state) => state.admin.productsByOrder)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const hadleShowModal = () => {
        setIsModalVisible(true)
        dispatch(setProductsByOrder(orderId))
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <UserName onClick={hadleShowModal}>{userName}</UserName>

            <Modal
                title='Продукты по текущему заказу:'
                visible={isModalVisible}
                footer={null}
                width={1200}
                closable={false}
            >
                <RowBtns>
                    {isAdmin && (
                        <Link to={`/demo-order/${orderId}`} target='_blank'>
                            Перейти к заказу
                        </Link>
                    )}

                    {isAdmin && (
                        <Button
                            variant='primary'
                            onClick={() => {
                                navigator.clipboard.writeText(`${window.location.origin}/demo-order/${orderId}`)
                                handleCancel()
                            }}
                        >
                            скопировать ссылку
                        </Button>
                    )}

                    <Button variant='secondary' onClick={handleCancel}>
                        Закрыть
                    </Button>
                </RowBtns>

                {productsByOrder.length !== 0 ? (
                    <ProductsList>
                        {productsByOrder.map((item) => (
                            <CardWrap key={item._id}>
                                <ProductCardAdmin item={item} paramId={params.id} orderId={orderId} />
                            </CardWrap>
                        ))}
                    </ProductsList>
                ) : (
                    <p>Здесь товаров нет.</p>
                )}

                {productsByOrder.length >= 3 && (
                    <RowBtns>
                        <Button variant='secondary' onClick={handleCancel}>
                            Закрыть
                        </Button>
                    </RowBtns>
                )}
            </Modal>
        </>
    )
}

export default ModalOrdersAdmin
