import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Excel } from 'antd-table-saveas-excel'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Table } from 'antd'
import { PageInner } from '../../components/shared/PageInner'
import Button from '../../components/shared/Button'
import { filterOrderByStatus } from '../../utils/filterOrderByStatus'
import TotalSumProductsBy from '../../components/TotalSumProductsBy/TotalSumProductsBy'
import { useFormula } from '../../hooks/useFormula'

const TitleWrap = styled.div`
    text-align: center;
    margin-bottom: 15px;

    h2 {
        margin-bottom: 15px;
    }
`

const TableWrap = styled.div`
    overflow-x: scroll;

    width: 100%;
    margin-top: 30px;

    table {
        .ant-table-cell {
            padding: 8px;
        }

        .ant-table-row {
            &.active {
                background-color: #affaea;
            }
        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background: #f5f1f1;
        }

        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #cacaca;
        }
    }
`

function AdminTableSamovivoz() {
    const token = useSelector((state) => state.token)
    const params = useParams()

    const { isLoadingFormula, formula, errorFormula } = useFormula()

    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [purchase, setPurchase] = useState([])

    const filteredOrders = orders.filter((item) => item.products.length > 0 || item?.productsInStock?.length > 0)
    const resultFiltered = filterOrderByStatus(filteredOrders, 'yes')

    const onChangeHandle = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    const columns = useMemo(
        () => [
            {
                title: 'Номер',
                dataIndex: 'articleOrder'
            },

            {
                title: 'ФИО',
                dataIndex: 'userName',
                render: (text, record, index) => record.info.userName
            },

            {
                title: 'Адрес',
                dataIndex: 'address',
                render: (text, record, index) => record.info.address
            },

            {
                title: 'Телефон',
                dataIndex: 'phone',
                render: (text, record, index) => record.info.phone
            },

            {
                title: 'Тип доставки',
                dataIndex: 'delivery',
                render: (text, record, index) => record.info.delivery
            },

            {
                title: 'Общая бел.руб',
                dataIndex: 'products',
                width: 75,
                render: (text, record, index) => {
                    if (text.length !== 0 || (record?.productsInStock && record?.productsInStock?.length > 0)) {
                        return (
                            <TotalSumProductsBy
                                order={record}
                                products={text}
                                productsInStock={record?.productsInStock}
                                formula={formula}
                                personalPercent={record?.author?.personalPercent}
                                personalPercentAdmdin={record.personalPercentAdmdin}
                                purchaseCourse={purchase && purchase[0]?.purchaseCourse}
                            />
                        )
                    }
                    return 0
                }
            }
        ],
        [formula, purchase]
    )

    useEffect(() => {
        const getPurchaseById = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/purchase/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })

                setPurchase(data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }

        getPurchaseById()
    }, [token, params.purchaseId])

    useEffect(() => {
        const getOrdersByBelpochta = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/order/samovivoz/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })

                setOrders(data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }

        getOrdersByBelpochta()
    }, [token, params.purchaseId])

    if (isLoadingFormula || isLoading) {
        return <div className='container'>Loading...</div>
    }

    if (errorFormula) {
        return <div className='container'>Что-то пошло не так... Попробуйте перезагрузить страницу.</div>
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <TitleWrap>
                        <h2>Заказы с типом доставки "Самовывоз":</h2>

                        <Button
                            variant='secondary'
                            onClick={() => {
                                const excel = new Excel()
                                excel
                                    .addSheet(`samovivoz-${purchase[0].title}`)
                                    .addColumns(columns)
                                    .addDataSource(resultFiltered)
                                    .saveAs(`samovivoz-${purchase[0].title}.xlsx`)
                            }}
                        >
                            Скачать ексель
                        </Button>
                    </TitleWrap>

                    {!isLoading ? (
                        <TableWrap>
                            <Table
                                columns={columns}
                                dataSource={resultFiltered}
                                onChange={onChangeHandle}
                                rowKey='_id'
                                rowClassName={(record) => record.completed && 'active'}
                            />
                        </TableWrap>
                    ) : (
                        <p>Loading....</p>
                    )}
                </div>
            </PageInner>
        </section>
    )
}

export default AdminTableSamovivoz
