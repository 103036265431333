import { getProfitSumProductsByManager } from '../utils/getProfitSumProductsByManager'

export const useTotalSumProfitByManager = (orders, user) => {
    const total = orders?.reduce(function (sum, { products, isManager }) {
        if (isManager) {
            const totalSumOneOrder = getProfitSumProductsByManager(products, user)

            return sum + totalSumOneOrder
        }

        return sum
    }, 0)

    return total.toFixed(2) || 0
}
