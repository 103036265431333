import { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const useGetOpenPurchase = () => {
    const token = useSelector((state) => state.token)

    const [isLoadingOpenPurchase, setIsLoadingOpenPurchase] = useState(false)
    const [isLoadingClosesPurchase, setIsLoadingClosesPurchase] = useState(false)

    const [openPurchases, setOpenPurchases] = useState([])
    const [closesPurchases, setClosesPurchases] = useState([])

    const [errorOpenPurchases, setErrorOpenPurchases] = useState(null)
    const [errorClosesPurchases, setErrorClosesPurchases] = useState(null)

    useEffect(() => {
        const getPurchase = async () => {
            setIsLoadingOpenPurchase(true)

            try {
                const { data } = await axios.get(`/purchase/open`)

                setOpenPurchases(data)
                setIsLoadingOpenPurchase(false)
            } catch (err) {
                setErrorOpenPurchases(err)
                setIsLoadingOpenPurchase(false)
            }
        }

        getPurchase()
    }, [])

    useEffect(() => {
        const getClosesPurchase = async () => {
            try {
                setIsLoadingClosesPurchase(true)

                const { data } = await axios.get(`/purchase/closed`, {
                    headers: {
                        Authorization: token
                    }
                })
                setClosesPurchases(data)
                setIsLoadingClosesPurchase(false)
            } catch (err) {
                setErrorClosesPurchases(err)
                setIsLoadingClosesPurchase(false)
            }
        }

        getClosesPurchase()
    }, [token])

    return {
        isLoadingOpenPurchase,
        openPurchases,
        setOpenPurchases,
        errorOpenPurchases,
        isLoadingClosesPurchase,
        closesPurchases,
        errorClosesPurchases
    }
}
