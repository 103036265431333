import React, { useState, memo, useMemo } from 'react'
import styled from 'styled-components'
import { Modal, Tag } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import NoPhoto from '../../../assets/photo.png'
import ProductCardUser from '../ProductCardUser/ProductCardUser'

const CardWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 99;
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
    border: 1px solid #fff;

    &.yes {
        border: 1px solid #87e8de;
    }

    &.no,
    &.pending {
        border: 1px solid tomato;
    }
`

const CardInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    cursor: pointer;
    transition: box-shadow 0.2s linear, border 0.2s linear;
    border: 1px solid #ffffff;
    padding: 15px;

    &:hover {
        box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.01);
        border: 1px solid #cecece;
    }

    .ant-tag {
        margin-bottom: 15px;
        text-align: center;
        margin-right: 0;
    }

    .anticon {
        position: absolute;
        right: 20px;
        bottom: 50%;
        transform: translateY(-50%);

        svg {
            width: 20px;
            height: 20px;
        }
    }

    @media (max-width: 380px) {
        padding: 8px;
    }
`

const CardImgWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 114px;
    overflow: hidden;
    margin: 0 auto;
    margin-right: 15px;

    img {
        width: 114px;
        height: 100px;
        border-radius: 6px;
        object-fit: cover;
        object-position: top;

        &.noPhoto {
            object-fit: contain;
        }
    }

    .ant-tag {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        text-align: center;
        margin-right: 0;
        padding: 6px 4px;
    }
`

const CardInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0;

        .ant-tag {
            padding: 0px 8px;
            margin: 0;
        }

        b {
            line-height: 16px;
            margin-right: 10px;
        }

        &.comment {
            color: tomato;
            opacity: 0.9;
            padding-top: 10px;
        }

        &.preQuantity {
            color: tomato;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        color: #1890ff;
    }
`

function ProductCardUserMini({ item, formula, personalPercentAdmdin, purchaseCourse }) {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const { personalPercent } = useSelector((state) => state.auth.user)

    const priceBY = useMemo(() => {
        const total = [item].reduce(function (sum, { price, status }) {
            return sum + price
        }, 0)

        if (personalPercent) {
            return (total * (purchaseCourse || formula?.course) * personalPercent).toFixed(2)
        }

        if (personalPercentAdmdin) {
            return (total * (purchaseCourse || formula?.course) * personalPercentAdmdin).toFixed(2)
        }

        return (total * (purchaseCourse || formula?.course) * formula?.percent).toFixed(2)
    }, [formula?.course, formula?.percent, item, personalPercent, personalPercentAdmdin, purchaseCourse])

    const showModal = () => {
        setIsModalVisible((prev) => !prev)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <CardWrapper className={item.status && item.status}>
                <CardInner
                    onClick={() => {
                        showModal(item)
                    }}
                >
                    <CardImgWrap>
                        <img
                            className='noPhoto'
                            src={`/images/${item.photo}`}
                            alt='Фото товара'
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = `${NoPhoto}`
                            }}
                        />

                        {item.status === 'yesMoscow' && (
                            <Tag color='purple'>
                                Выкуплено <br /> (в Москве)
                            </Tag>
                        )}
                        {item.status === 'yes' && (
                            <Tag color='green'>
                                Выкуплено <br /> (в Минске)
                            </Tag>
                        )}
                        {item.status === 'no' && <Tag color='red'>Не выкуплено</Tag>}
                        {item.status === 'pending' && <Tag color='red'>Не выкуплено</Tag>}
                        {item.status === 'return' && <Tag color='red'>Не выкуплено</Tag>}
                        {!item.status && <Tag color='default'>В обработке</Tag>}
                    </CardImgWrap>

                    <CardInfo>
                        <h4>{item.nameProduct}</h4>

                        {!item.preQuantity ? (
                            <p>
                                {' '}
                                <b>{item.quantity}</b> шт.
                            </p>
                        ) : (
                            <>
                                <p className='preQuantity'>Выкуплены не все товары!</p>

                                <p className='preQuantity'>
                                    <b>
                                        {!item.preQuantity
                                            ? `${item.quantity}`
                                            : `${item.preQuantity} из ${item.quantity}`}
                                    </b>{' '}
                                    шт.
                                </p>
                            </>
                        )}

                        <p>
                            {' '}
                            <b>{item.price}</b> рос. руб. за 1 шт
                        </p>

                        <p>
                            {' '}
                            <b>{priceBY}</b> бел. руб. за 1 шт
                        </p>

                        <p>
                            <b>Проверка на брак </b>
                            {item.checkDefects ? <Tag color='green'>Да</Tag> : <Tag color='red'>Нет</Tag>}
                        </p>
                    </CardInfo>

                    <ExclamationCircleOutlined />
                </CardInner>
            </CardWrapper>

            <Modal title='Подробнее о товаре:' visible={isModalVisible} footer={null} onCancel={handleCancel}>
                <ProductCardUser item={item} priceBY={priceBY} showModal={showModal} />
            </Modal>
        </>
    )
}

export default memo(ProductCardUserMini)
