import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { PageInner } from '../../components/shared/PageInner'
import { Tag, Modal, Alert, Popover, Button } from 'antd'
import { useFormula } from '../../hooks/useFormula'
import Loader from '../../components/shared/Loader'
import { useTotalSumOrderBy } from '../../hooks/useTotalSumOrderBy'
import { useSumProductsStatusYesBy } from '../../hooks/useSumProductsStatusYesBy'
import { useSumAddedProductsBy } from '../../hooks/useSumAddedProductsBy'
import ProductCardUserMini from './ProductCardUserMini/ProductCardUserMini'
import AddScreenshotReceipt from './AddScreenshotReceipt'
import ModalAddScreenshotReceipt from './ModalAddScreenshotReceipt'
import PaymentInfo from '../../components/PaymentInfo/PaymentInfo'
import ProductCardInStockMini from '../../components/ProductCardInStockMini/ProductCardInStockMini'
import { totalPriceProductInStockBY } from '../../utils/totalPriceProductInStockBY'

const deliveryJSX = {
    samovivoz: 'Самовывоз',
    belpochta: 'Белпочта',
    evropochta: 'Европочта',
    geoMinsk: 'Доставка по Минску'
}

const Wrapper = styled.section`
    .ant-tag {
        padding: 10px;
        border-radius: 4px;
        font-size: 14px;
    }
`

const CardWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 450px;
    background: #fff;
    transform-style: preserve-3d;
    border-radius: 15px;
    box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 15px;

    &:after {
        content: '';
        position: absolute;
        width: 96%;
        height: 30px;
        background-color: #fff;
        left: 2%;
        bottom: -7px;
        transform: translateZ(-1px);
        box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
        border-radius: 15px;
    }
`

const CardInner = styled.div`
    border: 1px solid #ededed;
    border-radius: 15px;
    height: 100%;
    padding: 4px 15px 20px 15px;
`

const CardTitle = styled.span`
    display: block;
    font-family: 'Caveat', cursive;
    color: #585a62;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
`

const CardDateWrap = styled.div`
    margin-bottom: 15px;

    p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0;
        border-bottom: 1px dotted #b3b3b3;
    }
`

const TrackerWrap = styled.div`
    margin-bottom: 12px;
`

const AddedProductsWrap = styled.div``

const ProductsWrap = styled.div``

const ProductsHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

const TitleWrap = styled.div`
    display: flex;
    align-items: center;

    h2 {
        margin-right: 10px;

        @media (max-width: 380px) {
            font-size: 20px;
        }
    }
`

const NoProduct = styled.p`
    color: tomato;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
`

const ProductsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;
    margin-top: 16px;
`

const CardProductWrap = styled.div`
    width: 100%;
    max-width: calc(33.333333% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;

    @media (max-width: 1180px) {
        max-width: calc(50% - 30px);
    }

    @media (max-width: 768px) {
        max-width: calc(100% - 30px);
    }

    @media (max-width: 380px) {
        max-width: calc(100% - 30px);
    }
`

const TotalSumWrap = styled.div`
    max-width: 500px;
    margin-top: 16px;
    margin-bottom: 16px;
`

const AddScreenshotReceiptWrap = styled.div`
    margin-top: 12px;
`

const AlerContent = styled.div`
    p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 2px 0;
        margin-bottom: 10px;

        &:not(:last-child) {
            border-bottom: 1px solid #ebebeb;
        }

        b {
            margin-left: 20px;

            &.widthFull {
                width: 100%;
                margin-left: 0;
            }
        }

        &.info {
            color: #1890ff;
        }
    }

    h4 {
        span {
            display: inline-block;
            margin-left: 16px;
        }
    }
`

function OpenClientOrder() {
    const params = useParams()
    const [order, setOrder] = useState({})

    const { isLoadingFormula, formula, errorFormula } = useFormula()

    const [isLoading, setIsLoading] = useState(false)
    const [isModalScreenshotVisible, setIsModalScreenshotVisible] = useState(false)

    const [isLoadingPurchase, setIsLoadingPurchase] = useState(false)
    const [purchase, setPurchase] = useState(null)

    const purchaseCourse = purchase && purchase[0].purchaseCourse

    const showModalScreenShot = () => {
        setIsModalScreenshotVisible(true)
    }

    const countProducts = useMemo(
        () =>
            order?.products?.reduce(function (sum, { quantity }) {
                return sum + quantity
            }, 0),
        [order]
    )

    const { productsStatusCheckDefects, sumProductsStatusCheckDefects, totalSumOrder } = useTotalSumOrderBy(
        order,
        order?.products,
        formula,
        null,
        order.personalPercentAdmdin,
        purchaseCourse
    )

    const sumProductsStatusYesBy = useSumProductsStatusYesBy(
        order?.products,
        formula?.course,
        formula?.percent,
        null,
        order.personalPercentAdmdin,
        purchaseCourse
    )
    const sumAddedProductsBy = useSumAddedProductsBy(
        order?.products,
        formula?.course,
        formula?.percent,
        null,
        order.personalPercentAdmdin,
        purchaseCourse
    )

    const sumProductsInStock = totalPriceProductInStockBY(order?.productsInStock)

    const isTotalSum = order && order?.products?.find((item) => !item.status)

    const isShowAddScreenshot = order?.info?.delivery !== 'samovivoz' && order?.info?.delivery !== 'geoMinsk'

    const handleCancelModalScreenShot = () => setIsModalScreenshotVisible(false)

    const deliveryContentJSX = {
        samovivoz: null,
        belpochta: (
            <p>
                Доставка: <b className='widthFull'>оплата почтовых услуг при получении</b>{' '}
            </p>
        ),
        evropochta: (
            <p>
                Доставка: <b className='widthFull'>{formula?.evropochtaPrice} бел.руб</b>{' '}
            </p>
        ),
        geoMinsk: (
            <p>
                Доставка: <b className='widthFull'>{formula?.geoMinskPrice} бел. руб</b>{' '}
            </p>
        )
    }

    const content = (
        <div style={{ position: 'relative' }}>
            {order?.screenshotReceipt && order?.screenshotReceipt?.pathPhoto ? (
                <img
                    src={`/public/screenshotReceipt/${order?.screenshotReceipt?.pathPhoto}`}
                    alt='Фото чека'
                    style={{ width: 300, height: 300, objectFit: 'cover' }}
                />
            ) : null}
        </div>
    )

    const contentInfo = <PaymentInfo typeDelivery={order?.info?.delivery} selectPurchase={purchase && purchase[0]} />

    const getOrders = async () => {
        setIsLoading(true)

        try {
            const { data } = await axios.get(`/order/client-order/${params.orderId}`)

            setOrder(data)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log('err-OpenClientOrder', err)
        }
    }

    useEffect(() => {
        if (params.orderId) {
            getOrders()
        }
    }, [params.orderId])

    useEffect(() => {
        const getPurchase = async () => {
            setIsLoadingPurchase(true)
            try {
                const res = await axios.get(`/purchase/${order.purchase}`)
                setPurchase(res.data)
                setIsLoadingPurchase(false)
            } catch (err) {
                console.log('err', err)
                setIsLoadingPurchase(false)
            }
        }

        if (order && order.purchase) {
            getPurchase()
        }
    }, [order])

    if (isLoading || isLoadingFormula || isLoadingPurchase) {
        return <Loader />
    }

    if (errorFormula) {
        return <div className='container'>Что-то пошло не так... Попробуйте перезагрузить страницу.</div>
    }

    return (
        <Wrapper>
            <div className='container'>
                <PageInner>
                    <CardWrap>
                        <CardInner>
                            <CardTitle>Ваши контакты:</CardTitle>

                            <TrackerWrap>
                                <Tag color='cyan'>
                                    Номер заказа: <b>{order && order.articleOrder}</b>
                                </Tag>
                            </TrackerWrap>

                            {order && order.trackerPochta ? (
                                <TrackerWrap>
                                    <Alert message={`Трек-номер: ${order.trackerPochta}`} type='warning' showIcon />
                                </TrackerWrap>
                            ) : null}

                            <CardDateWrap>
                                <p>
                                    ФИО - <span>{order?.info?.userName}</span>
                                </p>

                                <p>
                                    Телефон - <span>{order?.info?.phone}</span>
                                </p>

                                <p>
                                    Тип доставки - <span>{deliveryJSX[order?.info?.delivery]}</span>
                                </p>

                                {order?.info?.address && (
                                    <p>
                                        Адрес - <span>{order?.info?.address}</span>
                                    </p>
                                )}

                                {order?.info?.pochtaIndex && (
                                    <p>
                                        Индекс - <span>{order?.info?.pochtaIndex}</span>
                                    </p>
                                )}

                                {order?.info?.numberEvropochta && (
                                    <p>
                                        Номер отделения Европочты - <span>{order?.info?.numberEvropochta}</span>
                                    </p>
                                )}
                            </CardDateWrap>
                        </CardInner>
                    </CardWrap>

                    <AddedProductsWrap>
                        <ProductsWrap>
                            <ProductsHeader>
                                {order && (order?.products?.length > 0 || order?.productsInStock?.length > 0) && (
                                    <TitleWrap>
                                        <h2>Ваши добавленные товары:</h2>

                                        <Tag color='cyan'>
                                            <p>{countProducts + (order?.productsInStock?.length || 0)} шт</p>
                                        </Tag>
                                    </TitleWrap>
                                )}

                                {(order?.products?.length > 0 || order?.productsInStock?.length > 0) && (
                                    <TotalSumWrap>
                                        <Alert
                                            message={<h3>Детали оплаты:</h3>}
                                            description={
                                                <AlerContent>
                                                    {order?.productsInStock?.length > 0 && (
                                                        <p>
                                                            Добавлено товаров из наличия на сумму:
                                                            <b className='widthFull'>{sumProductsInStock} бел. руб</b>
                                                        </p>
                                                    )}

                                                    {order?.products?.length > 0 && (
                                                        <p>
                                                            Добавлено товаров на сумму:
                                                            <b className='widthFull'>{sumAddedProductsBy} бел. руб</b>
                                                        </p>
                                                    )}

                                                    {deliveryContentJSX[order?.info?.delivery]}

                                                    {order?.products?.length > 0 && sumProductsStatusCheckDefects > 0 && (
                                                        <p>
                                                            Проверка на брак:
                                                            <b className='widthFull'>
                                                                {productsStatusCheckDefects} шт. ={' '}
                                                                {sumProductsStatusCheckDefects} бел. руб
                                                            </b>
                                                        </p>
                                                    )}

                                                    {order?.products?.length > 0 && (
                                                        <h4 style={{ marginTop: 16 }}>
                                                            Выкуплено товаров на сумму:{' '}
                                                            <span>{sumProductsStatusYesBy}</span> бел. руб
                                                        </h4>
                                                    )}

                                                    {isTotalSum && (
                                                        <div style={{ marginTop: 15 }}>
                                                            <Alert
                                                                message='ВАЖНО:'
                                                                description={`Итоговая сумма появится после обработки всех ваших товаров.
                                                                Обработка товаров происходит по средам!`}
                                                                type='info'
                                                                showIcon
                                                            />
                                                        </div>
                                                    )}

                                                    {(!isTotalSum || (!isTotalSum && sumProductsInStock) > 0) && (
                                                        <>
                                                            <h3
                                                                style={{
                                                                    marginTop: 12,
                                                                    color: '#389e0d',
                                                                    marginBottom: 8
                                                                }}
                                                            >
                                                                Итоговая сумма:{' '}
                                                                {Number(sumProductsStatusYesBy) !== 0 ||
                                                                sumProductsInStock > 0
                                                                    ? +totalSumOrder + +sumProductsInStock
                                                                    : 0}{' '}
                                                                бел. руб
                                                            </h3>

                                                            {+sumProductsStatusYesBy !== 0 && (
                                                                <Popover
                                                                    content={contentInfo}
                                                                    title='Информация'
                                                                    trigger='click'
                                                                >
                                                                    <b>Нажмите для получения информации:</b>
                                                                    <Button type={'primary'} size={'large'}>
                                                                        Об оплате/доставке/самовывозе!
                                                                    </Button>
                                                                </Popover>
                                                            )}
                                                        </>
                                                    )}

                                                    <AddScreenshotReceiptWrap>
                                                        <AddScreenshotReceipt
                                                            screenshotReceipt={order?.screenshotReceipt}
                                                            content={content}
                                                            isShowAddScreenshot={isShowAddScreenshot}
                                                            showModalScreenShot={showModalScreenShot}
                                                            products={order?.products}
                                                        />
                                                    </AddScreenshotReceiptWrap>
                                                </AlerContent>
                                            }
                                            type='warning'
                                        />
                                    </TotalSumWrap>
                                )}
                            </ProductsHeader>

                            {order?.products?.length === 0 && order?.productsInStock?.length === 0 ? (
                                <ProductsHeader>
                                    <NoProduct>Здесь пока нет добавленных товаров</NoProduct>
                                </ProductsHeader>
                            ) : (
                                <>
                                    <ProductsList>
                                        {order?.products?.map((item) => (
                                            <CardProductWrap key={item._id}>
                                                <ProductCardUserMini
                                                    item={item}
                                                    formula={formula}
                                                    personalPercentAdmdin={order.personalPercentAdmdin}
                                                    purchaseCourse={purchaseCourse}
                                                />
                                            </CardProductWrap>
                                        ))}

                                        {/*{order?.productsInStock &&*/}
                                        {/*    order.productsInStock.length &&*/}
                                        {/*    order.productsInStock.map((item) => (*/}
                                        {/*        <CardProductWrap key={item._id}>*/}
                                        {/*            <ProductCardInStockMini item={item} />*/}
                                        {/*        </CardProductWrap>*/}
                                        {/*    ))}*/}

                                        {order?.productsInStock?.length > 0 &&
                                            order?.productsInStock.map((item) => (
                                                <CardProductWrap key={item._id}>
                                                    <ProductCardInStockMini item={item} />
                                                </CardProductWrap>
                                            ))}
                                    </ProductsList>
                                </>
                            )}
                        </ProductsWrap>
                    </AddedProductsWrap>
                </PageInner>
            </div>

            <Modal
                title='Добавление фото чека:'
                visible={isModalScreenshotVisible}
                onCancel={handleCancelModalScreenShot}
                footer={null}
            >
                <ModalAddScreenshotReceipt
                    order={order}
                    setOrder={setOrder}
                    handleCancel={handleCancelModalScreenShot}
                    getOrders={getOrders}
                />
            </Modal>
        </Wrapper>
    )
}

export default OpenClientOrder
