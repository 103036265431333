import React from 'react'
import styled from 'styled-components'
import { Button, Space, message, Popover, Alert, Image } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import Pointer from '../shared/Pointer'
import { Link } from 'react-router-dom'

const getColorCardForStatus = (status) => {
    if (status && status === 'booking') {
        return '#FFD700'
    }

    return '#00FF7F'
}

const Card = styled.div`
    position: relative;
    width: 100%;
    max-width: calc(25% - 20px);
    border: 2px solid #e7e4e4;
    box-shadow: 0px 5px 18px rgb(0 0 0 / 5%);
    border-radius: 9px;
    border-color: ${(props) => (props.status ? getColorCardForStatus(props.status) : 'transparent')};
    padding: ${(props) => (props.isAdmin ? '40px 15px 40px 15px' : '15px 15px 15px 15px')};
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: ${(props) => (props.isAdmin ? '80px' : '30px')};

    @media (max-width: 1024px) {
        max-width: calc(50% - 20px);
    }

    @media (max-width: 767px) {
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
`

const CardName = styled.h4`
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    margin: 10px 0;
`

const CardImages = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .swiper-container {
        margin-left: 0;
    }

    .cardImageWrap {
        width: 100px;
        height: 100px;
    }

    .ant-image {
        width: auto !important;
    }

    .ant-image-mask-info {
        display: none;
    }

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        cursor: zoom-in;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
`

const CardRow = styled.div`
    width: 100%;
    max-width: 210px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        margin-left: 10px;
    }
`

const CardDesc = styled.p`
    color: #1890ff;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
`

const AdminControl = styled.div`
    margin-top: 15px;

    button {
        &.booking {
            width: 100%;
            margin-top: 10px;
        }
    }
`

const Result = styled.div`
    margin-top: 15px;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: -80px;
    transform: translateX(-50%) rotate(-5deg);
    width: 100%;

    div {
        background-color: ${(props) => (props.status ? getColorCardForStatus(props.status) : 'transparent')};
        border-radius: 4px;
        padding: 15px;

        p {
            font-size: 18px;
            line-height: 18px;

            &:first-of-type {
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
                margin-bottom: 8px;
            }
        }
    }
`

const ClientControl = styled.div`
    margin-top: 16px;

    button {
        width: 100%;
    }
`

const ZoomInfo = styled.p`
    color: orange;
    margin-bottom: 6px;
`

function CardProductInStock({ item, onOpenModal, isAdmin, onDelete, onCancelBooking, onUpdateStatus, auth }) {
    const content = (
        <div>
            <b>Чтобы забронировать товар, напишите нам:</b>

            <p>* в инстаграм - sadovod_bel</p>

            <p>
                * или в телеграм -{' '}
                <a href={'https://t.me/Katerina_sad'} target={'_blank'}>
                    https://t.me/Katerina_sad
                </a>
            </p>
        </div>
    )

    return (
        <Card status={item.status} isAdmin={isAdmin}>
            {item?.photo?.length && (
                <div>
                    {!isAdmin && <ZoomInfo>Нажмите на фото для увеличения.</ZoomInfo>}

                    <CardImages>
                        <Swiper spaceBetween={10} slidesPerView={'auto'}>
                            {item.photo.map((photo, photoIndex) => (
                                <SwiperSlide className='cardImageWrap' key={photoIndex}>
                                    <Image width={200} src={photo} />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        {item.photo.length > 2 && <Pointer />}
                    </CardImages>
                </div>
            )}

            <Alert message={`Артикул: ${item.productNumber}`} type='info' showIcon />
            <CardName>{item.nameProduct}</CardName>

            <CardRow>
                Размер:
                <span>{item.size}</span>
            </CardRow>

            <CardRow>
                Цена в бел.руб:
                <span>{item.price}</span>
            </CardRow>

            <CardDesc>{item.comment}</CardDesc>

            {isAdmin && (
                <AdminControl>
                    <Space>
                        <Button onClick={() => onOpenModal('edit', item)} disabled={item.status}>
                            Редактировать
                        </Button>

                        <Button
                            danger
                            onClick={() => onDelete(item._id)}
                            disabled={item.status && item.status !== 'yes'}
                        >
                            Удалить
                        </Button>
                    </Space>
                </AdminControl>
            )}

            {isAdmin && (
                <AdminControl>
                    <Space>
                        <Button type='primary' onClick={() => onOpenModal('booking', item)} disabled={item.status}>
                            Добавить бронь
                        </Button>

                        <Button
                            type='primary'
                            onClick={() => onCancelBooking(item._id)}
                            danger
                            disabled={!item.status || item.status === 'yes'}
                        >
                            Снять бронь
                        </Button>
                    </Space>

                    <Button
                        className='booking'
                        type='primary'
                        onClick={() => onUpdateStatus(item._id, 'yes')}
                        disabled={item.status === 'yes'}
                    >
                        Отметить как "Продано"
                    </Button>
                </AdminControl>
            )}

            {!isAdmin && (
                <ClientControl>
                    <Popover content={content} title='Важно:' trigger='click'>
                        <Button type='primary' size='large'>
                            Забронировать
                        </Button>
                    </Popover>
                </ClientControl>
            )}

            {isAdmin && item.status && (
                <Result status={item.status}>
                    <div>
                        <p>Этот товар {item.status === 'booking' ? 'забронировали' : 'купили'}!</p>

                        <p>
                            Номер заказа:
                            <Link
                                to={`/admin/table-orders/${item?.order?.purchase?._id}?articleOrder=${item?.order?.articleOrder}&title=${item?.order?.purchase?.title}`}
                            >
                                {item?.order?.articleOrder}
                            </Link>
                        </p>
                        <p>Телефон: {item?.order?.info?.phone}</p>
                        <p>ФИО: {item?.order?.info?.userName}</p>
                    </div>
                </Result>
            )}
        </Card>
    )
}

export default CardProductInStock
