import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Table } from 'antd'
import { PageInner } from '../../components/shared/PageInner'
import EditStatusScreenshot from '../../components/EditStatusScreenshot/EditStatusScreenshot'
import TotalSumProductsBy from '../../components/TotalSumProductsBy/TotalSumProductsBy'
import { useFormula } from '../../hooks/useFormula'

const TitleWrap = styled.div`
    text-align: center;
    margin-bottom: 15px;

    h2 {
        margin-bottom: 15px;
    }
`

const TableWrap = styled.div`
    overflow-x: scroll;

    width: 100%;
    margin-top: 30px;

    table {
        .ant-table-cell {
            padding: 8px;
        }

        .ant-table-row {
            &.approved {
                background-color: #d4fff6;
            }

            &.rejected {
                background-color: #ffdcd7;
            }
        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background: #f5f1f1;
        }

        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #cacaca;
        }
    }
`

function AdminTableScreenshots() {
    const token = useSelector((state) => state.token)
    const params = useParams()

    const { isLoadingFormula, formula, errorFormula } = useFormula()

    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [purchase, setPurchase] = useState([])

    const ordersWithReceipt = orders.filter(
        ({ info, screenshotReceipt }) =>
            info.delivery !== 'samovivoz' && info.delivery !== 'geoMinsk' && screenshotReceipt
    )

    const handleUpdateOrders = (orderId, value) => {
        const newArr = orders.map((item) => {
            if (item._id === orderId) {
                const newOrder = {
                    ...item,
                    screenshotReceipt: { ...item.screenshotReceipt, status: value }
                }
                return newOrder
            }
            return item
        })

        setOrders(newArr)
    }

    const onChangeHandle = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    const columns = [
        {
            title: 'Номер',
            dataIndex: 'articleOrder'
        },

        {
            title: 'ФИО',
            dataIndex: 'userName',
            render: (text, record, index) => {
                if (record.author.userLogin) {
                    return (
                        <a href={`${record.author.userLogin}`} target='_blank' rel='noopener noreferrer'>
                            {record.info?.userName}
                        </a>
                    )
                }

                return record.info?.userName
            }
        },

        {
            title: 'Фото',
            dataIndex: 'screenshotReceipt',
            render: (text, record, index) => {
                return (
                    <a
                        href={`/public/screenshotReceipt/${record.screenshotReceipt?.pathPhoto}`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Фото
                    </a>
                )
            }
        },

        {
            title: 'Статус чека',
            dataIndex: 'screenshotReceipt',
            render: (text, record, index) => {
                return (
                    <EditStatusScreenshot
                        orderId={record._id}
                        statusCurrent={record?.screenshotReceipt?.status}
                        handleUpdateOrders={handleUpdateOrders}
                    />
                )
            }
        },

        {
            title: 'Адрес',
            dataIndex: 'address',
            render: (text, record, index) => record.info.address
        },

        {
            title: 'Телефон',
            dataIndex: 'phone',
            render: (text, record, index) => record.info.phone
        },

        {
            title: 'Тип доставки',
            dataIndex: 'delivery',
            render: (text, record, index) => record.info.delivery
        },

        {
            title: 'Общая бел.руб',
            dataIndex: 'products',
            width: 75,
            render: (text, record, index) => {
                if (text.length !== 0 || (record?.productsInStock && record?.productsInStock?.length > 0)) {
                    return (
                        <TotalSumProductsBy
                            order={record}
                            products={text}
                            productsInStock={record?.productsInStock}
                            formula={formula}
                            personalPercent={record?.author?.personalPercent}
                            personalPercentAdmdin={record.personalPercentAdmdin}
                            purchaseCourse={purchase && purchase[0]?.purchaseCourse}
                        />
                    )
                }
                return 0
            }
        }
    ]

    useEffect(() => {
        const getPurchaseById = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/purchase/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })

                setPurchase(data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }

        getPurchaseById()
    }, [token, params.purchaseId])

    useEffect(() => {
        const getOrders = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/order/all/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })

                setOrders(data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }

        getOrders()
    }, [token, params.purchaseId])

    if (isLoadingFormula || isLoading) {
        return <div className='container'>Loading...</div>
    }

    if (errorFormula) {
        return <div className='container'>Что-то пошло не так... Попробуйте перезагрузить страницу.</div>
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <TitleWrap>
                        <h2>Таблица фото чека:</h2>
                    </TitleWrap>

                    {!isLoading ? (
                        <TableWrap>
                            <Table
                                columns={columns}
                                dataSource={ordersWithReceipt}
                                onChange={onChangeHandle}
                                rowKey='_id'
                                rowClassName={(record) => record.screenshotReceipt?.status}
                            />
                        </TableWrap>
                    ) : (
                        <p>Loading....</p>
                    )}
                </div>
            </PageInner>
        </section>
    )
}

export default AdminTableScreenshots
