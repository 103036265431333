import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Button, Upload, message, Space } from 'antd'
import axios from 'axios'

const Wrapper = styled.div`
    .label {
        label {
            color: #1890ff;
            line-height: 16px;
            opacity: 0.6;
            white-space: normal;
            text-align: left;

            &:after {
                display: none;
            }
        }
    }
`

function ModalAddScreenshotReceipt({ order, handleCancel, getOrders }) {
    const [isLoading, setIsLoading] = useState(false)
    const [filePhoto, setFilePhoto] = useState(null)

    const [form] = Form.useForm()

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const onFinish = async (values) => {
        setIsLoading(true)

        let formData = new FormData()

        formData.append('screenshotReceipt', filePhoto)

        try {
            await axios.patch(`/order/add-screenshot/${order._id}/${order.articleOrder}`, formData, {
                headers: { 'content-type': 'multipart/form-data' }
            })

            setIsLoading(false)
            message.success('Фото успешно добавлено!')
            handleCancel()
            getOrders()
        } catch (err) {
            setIsLoading(false)
            console.log('err-AddScreenshotReceipt', err)
            message.error('Ошибка при добавлении чека')
        }
    }

    return (
        <Wrapper>
            <Form form={form} name='basic' initialValues={{}} onFinish={onFinish} size='large'>
                <Form.Item name='screenshotReceipt' getValueFromEvent={normFile}>
                    <Upload
                        disabled={filePhoto}
                        maxCount={1}
                        name='screenshotReceipt'
                        customRequest={({ file, onError, onSuccess, onProgress }) => {
                            onSuccess()
                            setFilePhoto(file)
                        }}
                        listType='picture-card'
                    >
                        Добавить фото
                    </Upload>
                </Form.Item>

                <Space>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                            {isLoading ? 'Загрузка...' : 'Сохранить'}
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type='secondry'
                            size='large'
                            disabled={isLoading}
                            onClick={() => {
                                setFilePhoto(null)
                                form.resetFields()
                            }}
                        >
                            Очистить
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </Wrapper>
    )
}

export default ModalAddScreenshotReceipt
