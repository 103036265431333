import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Popover, Button, Input, Form, message, Space } from 'antd'
import axios from 'axios'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setOrdersAdmin } from '../../redux/actions/adminActions'


const Wrapper = styled.div`
    display: inline-flex;
    padding: 0 8px;
`

const IconWrap = styled.span`
    cursor: pointer;
    transition: color .2s linear;

    &.trackerPochta {
        position: relative;
        color: tomato;

        &:after {
            position: absolute;
            content: '';
            top: -6px;
            left: 4px;
            width: 4px;
            height: 4px;
            background-color: tomato;
            border-radius: 50%;
        }
    }

    &:hover {
        color: #1890ff;
    }
`

const FormWrap = styled.div`
    width: 100%;
    max-width: 400px;   
`


function ModalAddTracker({ order }) {
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const token = useSelector(state => state.token)
    const orders = useSelector((state) => state.admin.orders)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async ({ trackerPochta }) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/order/add-trackerPochta/${order._id}`, { trackerPochta }, {
                headers: { Authorization: token }
            })

            const newArr = orders.map((item) => {
                if (item._id === order._id) {
                    const newOrder = { ...item, trackerPochta: trackerPochta }
                    return newOrder
                }
                return item
            })

            setIsLoading(false)
            message.success(data.msg)
            setIsModalVisible(false)
            dispatch(setOrdersAdmin(newArr))

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }

    const handleDeleteComment = async () => {
        setIsLoading(true)

        try {
            await axios.patch(`/order/add-trackerPochta/${order._id}`, { trackerPochta: null }, {
                headers: { Authorization: token }
            })

            const newArr = orders.map((item) => {
                if (item._id === order._id) {
                    const newOrder = { ...item, trackerPochta: null }
                    return newOrder
                }
                return item
            })

            setIsLoading(false)
            setIsModalVisible(false)
            message.success('Удаление успешно!')
            dispatch(setOrdersAdmin(newArr))

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }


    const content = (
        <FormWrap>
            <Form
                form={form}
                name={`addTracker_${order._id}`}
                onFinish={onFinish}
                size='large'
            >

                <Form.Item
                    name="trackerPochta"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <Input placeholder='Трекер почты' />
                </Form.Item>

                <Space>
                    <Button type="primary" htmlType="submit" size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>

                    <Button type="primary" onClick={handleDeleteComment} size='large' disabled={isLoading | !order.trackerPochta}>
                        {isLoading ? 'Загрузка...' : 'Удалить'}
                    </Button>
                </Space>
            </Form>
        </FormWrap>
    )

    useEffect(() => {
        form.setFieldsValue({
            trackerPochta: order.trackerPochta
        })
    }, [form, order.trackerPochta])

    return (
        <Wrapper>
            <Popover
                content={content}
                title="Трекер почты к заказу:"
                trigger="click"
                visible={isModalVisible}
                onVisibleChange={setIsModalVisible}
                overlayStyle={{ width: 400 }}
                forceRender
            >

                <IconWrap className={order.trackerPochta && 'trackerPochta'}>
                    <PlusCircleOutlined />
                </IconWrap>
            </Popover>
        </Wrapper>
    )
}

export default ModalAddTracker
