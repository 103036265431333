import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import { Popover, Radio } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

const Wrapper = styled.div`
    cursor: pointer;

    .popver {
        width: 100%;
        text-align: left;
    }
`

const CardBtns = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .ant-radio-group {
        margin-left: auto;
    }
`

const statusJSX = {
    pending: 'Ожидает',
    approved: 'Принят',
    rejected: 'Отклонить',
}

const EditStatusScreenshot = ({ orderId, statusCurrent, handleUpdateOrders }) => {

    const token = useSelector(state => state.token)

    const handleUpdate = async (value) => {
        try {
            await axios.patch(`/order/update-status-screenshot/${orderId}`, { status: value }, {
                headers: { Authorization: token }
            })

            handleUpdateOrders(orderId, value)

        } catch (err) {
            console.log(err);
        }
    }

    const onChangeStatus = e => {
        handleUpdate(e.target.value)
    }

    const content = (
        <CardBtns>
            <Radio.Group onChange={onChangeStatus} value={statusCurrent}>
                <Radio value={'pending'}>{statusJSX.pending}</Radio>
                <Radio value={'rejected'}>{statusJSX.rejected}</Radio>
                <Radio value={'approved'}>{statusJSX.approved}</Radio>
            </Radio.Group>
        </CardBtns>
    )

    return (
        <Wrapper>
            <Popover content={content} placement="topLeft" title="Статус заказа:" className='popver'>
                {statusJSX[statusCurrent]} <SettingOutlined />
            </Popover>

        </Wrapper>
    )
}

export default memo(EditStatusScreenshot)