import { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const useFormula = () => {
    // const token = useSelector(state => state.token)

    const [isLoadingFormula, setIsLoadingFormula] = useState(false)
    const [formula, setFormula] = useState(null)
    const [errorFormula, setErrorFormula] = useState(null)

    useEffect(() => {
        const getFormula = async () => {
            try {
                // const { data } = await axios.get(`/settings/formula`, {
                //     headers: { Authorization: token }
                // })

                const { data } = await axios.get(`/settings/formula`)

                setFormula(data)
                setIsLoadingFormula(false)
            } catch (err) {
                setErrorFormula(err.response?.data?.msg)
                setIsLoadingFormula(false)
            }
        }
        getFormula()
    }, [])

    return { isLoadingFormula, formula, errorFormula }
}
