import React from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import { Button } from 'antd'


const CardList = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 75px;

    @media (max-width: 1120px) {
        display: none;
    }
`

const CardListSlider = styled(CardList)`
   display: flex;
    margin-bottom: 30px;
`

const CardWrap = styled.div`
    position: relative;
    max-width: calc(200px - 20px);
    border-radius: 4px;

    overflow: hidden;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;

    img {
        width: 200px;
        height: 200px;
        object-fit: cover;
    }

    .addButton {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }   
`

function PopularProducts({ data, params, isLoading, onClick }) {
    return (
        <div>
            <div className='container'>
                {!isLoading
                    ? <CardListSlider>
                        <Swiper {...params}>
                            {data.map((item) =>
                                <CardWrap key={item._id}>
                                    <img src={`/images/${item.photo}`} alt='Фото товара' />

                                    <Button className='addButton' onClick={() => onClick(item.photo)}>Добавить себе</Button>
                                </CardWrap>
                            )}
                        </Swiper>
                    </CardListSlider>

                    : <p>loading...</p>
                }
            </div>
        </div>
    )
}

export default PopularProducts




