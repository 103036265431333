export const SET_ORDERS_ADMIN = 'SET_ORDERS_ADMIN';
export const SET_LOADED = 'SET_LOADED';
export const SET_OPEN_PURCHASES = 'SET_OPEN_PURCHASES';
export const SET_OPEN_PURCHASE_BY_ID = 'SET_OPEN_PURCHASE_BY_ID';
export const EDIT_INFO_ORDER_FROM_ADMIN = 'EDIT_INFO_ORDER_FROM_ADMIN';
export const SET_PRODUCTS_BY_ORDER = 'SET_PRODUCTS_BY_ORDER';
export const UPDATE_STATUS_PRODUCT = 'UPDATE_STATUS_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ADD_COMMENT_IN_PRODUCT = 'ADD_COMMENT_IN_PRODUCT';
export const ADD_ORDER_FROM_ADMIN = 'ADD_ORDER_FROM_ADMIN';
export const UPDATE_STATUS_PRE_QUANTITY = 'UPDATE_STATUS_PRE_QUANTITY';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';