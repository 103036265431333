import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Popover, Button, Input, Form, message, Space } from 'antd'

const { TextArea } = Input

const FormWrap = styled.div`
    width: 100%;
    max-width: 400px;   
`

function AddWarningMessage({ purchaseId, currentWarning, handleUpdateMessagePurchase }) {

    const [form] = Form.useForm()

    const token = useSelector(state => state.token)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async ({ warningMessage }) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/purchase/update_warning_message/${purchaseId}`, { warningMessage }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            message.success(data.msg)
            setIsModalVisible(false)
            handleUpdateMessagePurchase(purchaseId, warningMessage)

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }

    const handleDeleteMessage = async () => {
        setIsLoading(true)

        try {
            await axios.patch(`/purchase/update_warning_message/${purchaseId}`, { warningMessage: null }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            setIsModalVisible(false)
            message.success('Удаление успешно!')
            handleUpdateMessagePurchase(purchaseId)

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }

    const content = (
        <FormWrap>
            <Form
                form={form}
                name="addwarningMessage"
                onFinish={onFinish}
                size='large'
            >

                <Form.Item
                    name="warningMessage"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <TextArea rows={4} placeholder='Текст объявления' />
                </Form.Item>

                <Space>
                    <Button type="primary" htmlType="submit" size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>

                    <Button type="primary" onClick={handleDeleteMessage} size='large' disabled={isLoading || !currentWarning}>
                        {isLoading ? 'Загрузка...' : 'Удалить'}
                    </Button>
                </Space>
            </Form>
        </FormWrap>
    )

    useEffect(() => {
        form.setFieldsValue({
            warningMessage: currentWarning
        })
    }, [form, currentWarning])


    return (
        <Popover
            content={content}
            title="Объявление к заказу:"
            trigger="click"
            visible={isModalVisible}
            onVisibleChange={setIsModalVisible}
            overlayStyle={{ width: 400 }}
            forceRender
        >

            <Button className='fullWidth' danger type='dashed'>
                {currentWarning ? 'Редактировать объявление' : 'Добавить объявление'}
            </Button>
        </Popover>
    )
}

export default AddWarningMessage
