import React from 'react'
import { NavLink } from 'react-router-dom'

function NotFound() {
    return (
        <div className='container'>
            <p>У вас нет прав доступа или страница не найдена</p>

            <div>
                <NavLink to='/'>На главную</NavLink>
            </div>
        </div>
    )
}

export default NotFound
