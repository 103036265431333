import {
    SET_ORDERS_ADMIN,
    SET_LOADED,
    EDIT_INFO_ORDER_FROM_ADMIN,
    SET_PRODUCTS_BY_ORDER,
    UPDATE_STATUS_PRODUCT,
    DELETE_PRODUCT,
    ADD_COMMENT_IN_PRODUCT,
    ADD_ORDER_FROM_ADMIN,
    UPDATE_STATUS_PRE_QUANTITY,
    UPDATE_PRODUCT
} from '../types'

const initialState = {
    orders: [],
    ordersByAdmin: [],
    productsByOrder: [],
    isLoading: false
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDERS_ADMIN:
            return {
                ...state,
                orders: action.payload,
                isLoading: false
            }

        case ADD_ORDER_FROM_ADMIN: {
            return {
                ...state,
                orders: [...state.orders, action.payload]
            }
        }

        case SET_LOADED:
            return {
                ...state,
                isLoading: action.payload
            }

        case EDIT_INFO_ORDER_FROM_ADMIN: {
            const newOrders = state.orders.map((item) => {
                if (item._id === action.payload.orderId) {
                    return {
                        ...item,
                        info: action.payload.info,
                        personalPercentAdmdin: action.payload.personalPercentAdmdin
                    }
                }
                return item
            })

            return {
                ...state,
                orders: newOrders
            }
        }

        case SET_PRODUCTS_BY_ORDER:
            return {
                ...state,
                productsByOrder: state.orders.filter((item) => item._id === action.payload)[0]
                    .products
            }

        case UPDATE_STATUS_PRODUCT: {
            return {
                ...state,
                orders: state.orders.map((order) => {
                    if (order._id === action.payload.orderId) {
                        return {
                            ...order,
                            products: order.products.map((product) => {
                                if (product._id === action.payload.productId) {
                                    return { ...product, status: action.payload.status }
                                }
                                return product
                            })
                        }
                    }
                    return order
                })
            }
        }

        case UPDATE_PRODUCT: {
            return {
                ...state,
                orders: state.orders.map((order) => {
                    if (order._id === action.payload.orderId) {
                        return {
                            ...order,
                            products: order.products.map((product) => {
                                if (product._id === action.payload.productId) {
                                    return { ...product, ...action.payload.objValue }
                                }
                                return product
                            })
                        }
                    }
                    return order
                })
            }
        }

        case UPDATE_STATUS_PRE_QUANTITY: {
            return {
                ...state,
                orders: state.orders.map((order) => {
                    if (order._id === action.payload.orderId) {
                        return {
                            ...order,
                            products: order.products.map((product) => {
                                if (product._id === action.payload.productId) {
                                    return { ...product, preQuantity: action.payload.preQuantity }
                                }
                                return product
                            })
                        }
                    }
                    return order
                })
            }
        }

        case ADD_COMMENT_IN_PRODUCT: {
            return {
                ...state,
                orders: state.orders.map((order) => {
                    if (order._id === action.payload.orderId) {
                        return {
                            ...order,
                            products: order.products.map((product) => {
                                if (product._id === action.payload.productId) {
                                    return { ...product, commentAdmin: action.payload.commentAdmin }
                                }
                                return product
                            })
                        }
                    }
                    return order
                })
            }
        }

        case DELETE_PRODUCT: {
            return {
                ...state,
                orders: state.orders.map((order) => {
                    if (order._id === action.payload.orderId) {
                        return {
                            ...order,
                            products: order.products.filter(
                                (product) => product._id !== action.payload.productId
                            )
                        }
                    }
                    return order
                })
            }
        }

        default:
            return state
    }
}

export default adminReducer
