import React, { useState } from 'react'
import styled from 'styled-components'
import { Input, Space, Button, Popover } from 'antd'
const { TextArea } = Input
const ContentBlackList = styled.div`
    .ant-space,
    .ant-space-item {
        width: 100%;
    }

    button {
        width: 100%;
    }
`

const Inner = styled.div`
    display: inline;

    .ban {
        background-color: #000c17;
        color: white;
    }
`

const AddPenalty = ({ phone, isBan, addPenalty, user, deletePenalty, orderId }) => {
    const [isOpenField, setIsOpenField] = useState(false)
    const [valueNewPenalty, setValueNewPenalty] = useState('')

    const contentBlackList = (
        <ContentBlackList>
            {!isOpenField && (
                <Space>
                    {!isBan && (
                        <Button
                            danger
                            onClick={() => {
                                setIsOpenField(true)
                            }}
                        >
                            Забанить!
                        </Button>
                    )}

                    {isBan && (
                        <Button
                            type={'primary'}
                            onClick={() => {
                                deletePenalty(phone, user?._id, orderId)
                            }}
                        >
                            Разбанить!
                        </Button>
                    )}
                </Space>
            )}

            {isOpenField && (
                <Space direction={'vertical'}>
                    <TextArea
                        value={valueNewPenalty}
                        onChange={(e) => setValueNewPenalty(e.currentTarget.value)}
                        rows={4}
                        placeholder='Коментарий к бану'
                    />

                    <Button
                        onClick={() => addPenalty(phone, user?._id, valueNewPenalty, orderId)}
                        type={'primary'}
                        danger
                    >
                        Подтвердить бан
                    </Button>
                </Space>
            )}
        </ContentBlackList>
    )

    return (
        <Popover content={contentBlackList} title='Черный список' trigger='click'>
            <Inner>{phone && <Button className={isBan ? 'ban' : ''}>{phone}</Button>}</Inner>
        </Popover>
    )
}

export default AddPenalty
