

import { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'


export const useAllCategories = () => {
    const token = useSelector(state => state.token)

    const [isLoadingCategories, setIsLoadingCategories] = useState(false)
    const [categories, setCategories] = useState([])
    const [errorCategories, setErrorCategories] = useState(null)

    useEffect(() => {
        setIsLoadingCategories(true)
        const getFormula = async () => {
            try {
                const { data } = await axios.get(`/category/all`, {
                    headers: { Authorization: token }
                })

                setCategories(data)
                setIsLoadingCategories(false)

            } catch (err) {
                setErrorCategories(err)
                setIsLoadingCategories(false)
            }
        }
        getFormula()
    }, [token])


    return { isLoadingCategories, categories, setCategories, errorCategories }
}