import axios from 'axios'
import { SET_LOADED, SET_OPEN_PURCHASES, } from "../types"

export const fetchAllPurchase = async (token) => {
    const res = await axios.get('/purchase/all', {
        headers: { Authorization: token }
    })
    return res
}

export const setOpenPurchases = (items) => ({
    type: SET_OPEN_PURCHASES,
    payload: items,
})

export const getOpenPurchases = (token) => async (dispatch) => {
    dispatch({
        type: SET_LOADED,
        payload: true,
    });

    try {
        const res = await axios.get(`/purchase/all`, {
            headers: { Authorization: `${token}` }
        })
        dispatch(setOpenPurchases(res.data));

    } catch (err) {
        console.log('err', err);
    }
}
