import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { Table, Button, Space } from 'antd'
import { PageInner } from '../../components/shared/PageInner'
import { Excel } from 'antd-table-saveas-excel'
import { useFormula } from '../../hooks/useFormula'
import { totalPriceBY } from '../../utils/totalPriceBY'
import Loader from '../../components/shared/Loader'
import { totalPriceProductInStockBY } from '../../utils/totalPriceProductInStockBY'

const TitleWrap = styled.div`
    text-align: center;
    margin-bottom: 15px;

    h2 {
        margin-bottom: 15px;
    }
`

const TableWrap = styled.div`
    overflow-x: scroll;

    width: 100%;
    margin-top: 30px;

    table {
        .ant-table-cell {
            padding: 8px;
        }

        .ant-table-row {
            &.approved {
                background-color: #d4fff6;
            }

            &.rejected {
                background-color: #ffdcd7;
            }
        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background: #f5f1f1;
        }

        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #cacaca;
        }
    }
`

const TextForSMS = (articleOrder, isAdmin, sum) => {
    if (!isAdmin) {
        return `Заказ ${articleOrder} на сумму ${sum}р. приехал. Подробности на: sadovod-bel.by`
    }

    return `Заказ ${articleOrder} на сумму ${sum}р. приехал. Напишите нам в инсте sadovod_bel`
}

const columns = [
    {
        title: 'Телефон',
        dataIndex: 'phone'
    },

    {
        title: 'Текст',
        dataIndex: 'text'
    },

    {
        title: 'Ссылка',
        dataIndex: 'ссылка',
        render: (text, record, index) => {
            return (
                <Link to={`/demo-order/${record.id}`} target='_blank'>
                    Перейти к заказу
                </Link>
            )
        }
    },

    {
        title: 'Копирование',
        dataIndex: 'Копирование',
        render: (text, record, index) => {
            return (
                <Button
                    type='primary'
                    onClick={() => navigator.clipboard.writeText(`${window.location.origin}/order/${record.id}`)}
                >
                    Скопировать
                </Button>
            )
        }
    }
]

function AdminTableSMS() {
    const token = useSelector((state) => state.token)
    const params = useParams()

    const { formula, errorFormula } = useFormula()

    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([])

    const [isLoadingPurchase, setIsLoadingPurchase] = useState(false)
    const [purchase, setPurchase] = useState(null)

    const checkOrders = useMemo(
        () =>
            orders.filter((item) => {
                const checkProductsArr = item.products.filter(
                    (product) => product.status === 'yes' && !product?.isManager
                )
                return item.products.length !== 0 && checkProductsArr.length !== 0
            }),
        [orders]
    )

    const GetTotalSum = useMemo(
        () => (order, products, personalPercent, personalPercentAdmdin, productsInStock) => {
            const sumProducts = totalPriceBY(
                products,
                formula?.course,
                formula?.percent,
                personalPercent,
                personalPercentAdmdin,
                purchase[0].purchaseCourse
            )

            const sumProductsInStock = totalPriceProductInStockBY(productsInStock)

            const productsStatusCheckDefects = products.reduce(function (
                sum,
                { checkDefects, quantity, status, preQuantity }
            ) {
                if (checkDefects === false || status === 'no' || status === 'pending') {
                    return sum
                }

                if (!preQuantity) {
                    return sum + quantity
                }
                return sum + preQuantity

                // return sum + quantity
            },
            0)

            const sumProductsStatusCheckDefects = (
                formula?.checkDefectsPrice *
                (purchase[0].purchaseCourse || formula?.course) *
                formula?.percent *
                productsStatusCheckDefects
            ).toFixed(2)

            const getSumDelivery = () => {
                if (order) {
                    switch (order.info.delivery) {
                        case 'evropochta':
                            return formula?.evropochtaPrice

                        case 'geoMinsk':
                            return formula?.geoMinskPrice

                        default:
                            return 0.001
                    }
                }
            }

            const sumDelivery = getSumDelivery()

            const totalSumOrder = (
                +sumProducts +
                +sumProductsStatusCheckDefects +
                +sumDelivery +
                +sumProductsInStock
            ).toFixed(2)

            return totalSumOrder
        },
        [
            formula?.checkDefectsPrice,
            formula?.course,
            formula?.evropochtaPrice,
            formula?.geoMinskPrice,
            formula?.percent,
            purchase
        ]
    )

    const resultArr = useMemo(
        () =>
            checkOrders.map((item) => {
                return {
                    id: item._id,
                    phone: item.info.phone,
                    text: TextForSMS(
                        item.articleOrder,
                        item.isAdmin,
                        GetTotalSum(
                            item,
                            item.products,
                            item?.author?.personalPercent,
                            item.personalPercentAdmdin,
                            item?.productsInStock
                        )
                    ),
                    isAdmin: item.isAdmin,
                    resultSumm: GetTotalSum(
                        item,
                        item.products,
                        item?.author?.personalPercent,
                        item.personalPercentAdmdin
                    )
                }
            }),
        [checkOrders, GetTotalSum]
    )

    const saveNoAdmin = resultArr.filter((item) => !item.isAdmin)
    const saveYesAdmin = resultArr.filter((item) => item.isAdmin)

    const onChangeHandle = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    const onDownloadExel = (titleSheet, data, titleFile) => {
        const excel = new Excel()

        excel.addSheet(`${titleSheet}`).addColumns(columns).addDataSource(data).saveAs(`${titleFile}.xlsx`)
    }

    useEffect(() => {
        const getOrders = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/order/ref-purchase/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })

                setOrders(data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }
        getOrders()
    }, [token, params.purchaseId])

    useEffect(() => {
        const getPurchase = async () => {
            setIsLoadingPurchase(true)
            try {
                const res = await axios.get(`/purchase/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })
                setPurchase(res.data)
                setIsLoadingPurchase(false)
            } catch (err) {
                console.log('err', err)
                setIsLoadingPurchase(false)
            }
        }
        getPurchase()
    }, [token, params.purchaseId])

    if (errorFormula) {
        return <div className='conatiner'>Ошибка при получении формулы...</div>
    }

    if (isLoading || isLoadingPurchase) {
        return <Loader />
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <TitleWrap>
                        <h2>Таблица для СМС:</h2>
                    </TitleWrap>

                    <Space>
                        <Button
                            type='primary'
                            onClick={() => {
                                onDownloadExel('Пользовательские заказы', saveNoAdmin, 'Пользовательские заказы')
                            }}
                        >
                            Скачать пользовательские заказы
                        </Button>

                        <Button
                            type='primary'
                            onClick={() => {
                                onDownloadExel('Админские заказы', saveYesAdmin, 'Админские заказы')
                            }}
                        >
                            Скачать админские заказы
                        </Button>
                    </Space>

                    {!isLoading ? (
                        <TableWrap>
                            <Table
                                columns={columns}
                                dataSource={resultArr}
                                onChange={onChangeHandle}
                                rowKey='id'
                                rowClassName={(record) => record.screenshotReceipt?.status}
                            />
                        </TableWrap>
                    ) : (
                        <p>Loading....</p>
                    )}
                </div>
            </PageInner>
        </section>
    )
}

export default AdminTableSMS
