import React, { memo } from 'react'
import { Alert, Popover, Button } from 'antd'
import { useTotalSumOrderBy } from '../../hooks/useTotalSumOrderBy'
import { useSelector } from 'react-redux'
import { useSumProductsStatusYesBy } from '../../hooks/useSumProductsStatusYesBy'
import { useSumAddedProductsBy } from '../../hooks/useSumAddedProductsBy'
import styled from 'styled-components'
import PaymentInfo from '../PaymentInfo/PaymentInfo'
import { totalPriceProductInStockBY } from '../../utils/totalPriceProductInStockBY'

const AlerContent = styled.div`
    p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 2px 0;
        margin-bottom: 10px;

        &:not(:last-child) {
            border-bottom: 1px solid #ebebeb;
        }

        b {
            margin-left: 20px;

            &.widthFull {
                width: 100%;
                margin-left: 0;
            }
        }

        &.info {
            color: #1890ff;
        }
    }

    h4 {
        span {
            display: inline-block;
            margin-left: 16px;
        }
    }
`

function PaymentDetails({ order, products, formula, selectPurchase }) {
    const { personalPercent } = useSelector((state) => state.auth.user)

    const { productsStatusCheckDefects, sumProductsStatusCheckDefects, totalSumOrder } = useTotalSumOrderBy(
        order[0],
        products,
        formula,
        personalPercent,
        null,
        selectPurchase.purchaseCourse
    )

    const sumProductsStatusYesBy = useSumProductsStatusYesBy(
        products,
        formula?.course,
        formula?.percent,
        personalPercent,
        null,
        selectPurchase.purchaseCourse
    )
    const sumAddedProductsBy = useSumAddedProductsBy(
        products,
        formula?.course,
        formula?.percent,
        personalPercent,
        null,
        selectPurchase.purchaseCourse
    )

    const sumProductsInStock = totalPriceProductInStockBY(order[0]?.productsInStock)

    const isTotalSum = products.find((item) => !item.status)

    const content = <PaymentInfo typeDelivery={order[0].info.delivery} selectPurchase={selectPurchase} />

    const deliveryJSX = {
        samovivoz: null,
        belpochta: (
            <p>
                Доставка: <b className='widthFull'>оплата почтовых услуг при получении</b>{' '}
            </p>
        ),
        evropochta: (
            <p>
                Доставка: <b className='widthFull'>{formula?.evropochtaPrice} бел.руб</b>{' '}
            </p>
        ),
        geoMinsk: (
            <p>
                Доставка: <b className='widthFull'>{formula?.geoMinskPrice} бел. руб</b>{' '}
            </p>
        )
    }

    return (
        <Alert
            message={<h3>Детали оплаты:</h3>}
            description={
                <AlerContent>
                    {order[0]?.productsInStock?.length > 0 && (
                        <p>
                            Добавлено товаров из наличия на сумму:
                            <b className='widthFull'>{sumProductsInStock} бел. руб</b>
                        </p>
                    )}

                    {order[0]?.products?.length > 0 && (
                        <p>
                            Добавлено товаров на сумму: <b className='widthFull'>{sumAddedProductsBy} бел. руб</b>{' '}
                        </p>
                    )}

                    {deliveryJSX[order[0].info.delivery]}

                    {sumProductsStatusCheckDefects > 0 && (
                        <p>
                            Проверка на брак:
                            <b className='widthFull'>
                                {' '}
                                {productsStatusCheckDefects} шт. = {sumProductsStatusCheckDefects} бел. руб
                            </b>
                        </p>
                    )}

                    {order[0]?.products?.length > 0 && (
                        <h4 style={{ marginTop: 16 }}>
                            Выкуплено товаров на сумму: <span>{sumProductsStatusYesBy}</span> бел. руб
                        </h4>
                    )}

                    {/*{isTotalSum && <p className='info'>Итоговая сумма появится после обработки всех ваших товаров.</p>}*/}

                    {isTotalSum && (
                        <div style={{ marginTop: 15 }}>
                            <Alert
                                message='ВАЖНО:'
                                description={`Итоговая сумма появится после обработки всех ваших товаров. 
                                Обработка товаров происходит по средам!`}
                                type='info'
                                showIcon
                            />
                        </div>
                    )}

                    {(!isTotalSum || (!isTotalSum && sumProductsInStock) > 0) && (
                        <>
                            <h3 style={{ marginTop: 12, color: '#389e0d', marginBottom: 8 }}>
                                {/*Итоговая сумма: {Number(sumProductsStatusYesBy) !== 0 ? totalSumOrder : 0} бел. руб*/}
                                Итоговая сумма:{' '}
                                {Number(sumProductsStatusYesBy) !== 0 || sumProductsInStock > 0
                                    ? +totalSumOrder + +sumProductsInStock
                                    : 0}{' '}
                                бел. руб
                            </h3>

                            {+sumProductsStatusYesBy !== 0 && (
                                <Popover content={content} title='Информация' trigger='click'>
                                    <b>Нажмите для получения информации:</b>
                                    <Button type='primary' size='large'>
                                        Об оплате/доставке/самовывозе!
                                    </Button>
                                </Popover>
                            )}
                        </>
                    )}
                </AlerContent>
            }
            type='warning'
        />
    )
}

export default memo(PaymentDetails)
