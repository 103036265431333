import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'
import EditOrderInfoFromAdmin from '../EditOrderInfoFromAdmin/EditOrderInfoFromAdmin'
import { EditOutlined } from '@ant-design/icons'

const IconWrap = styled.span`
    cursor: pointer;
    transition: color 0.2s linear;
    margin-right: 6px;

    &:hover {
        color: #1890ff;
    }
`

const ModalWrap = styled.div`
    width: 100%;
    max-width: 500px;
`

function ModalEditOrderFromAdmin({ order, getOrders }) {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const hadleShowModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <IconWrap onClick={hadleShowModal}>
                <EditOutlined />
            </IconWrap>

            <ModalWrap>
                <Modal
                    title='Редактирование заказа от имени админа:'
                    visible={isModalVisible}
                    footer={null}
                    onCancel={handleCancel}
                >
                    <EditOrderInfoFromAdmin order={order} handleCancel={handleCancel} getOrders={getOrders} />
                </Modal>
            </ModalWrap>
        </>
    )
}

export default memo(ModalEditOrderFromAdmin)
