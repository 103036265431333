import { SET_LOADED, SET_OPEN_PURCHASES, } from "../types";

const initialState = {
    openPurchases: [],
    isLoading: false,
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OPEN_PURCHASES:
            return {
                ...state,
                openPurchases: action.payload,
                isLoading: false,
            };

        case SET_LOADED:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state
    }
}

export default usersReducer