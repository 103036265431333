import React, { useState, useMemo, memo } from 'react'
import styled from 'styled-components'
import { Tag, Button, Alert } from 'antd'

import { ProductsContext } from '../../context/productsContext'
import PaymentDetails from '../PaymentDetails/PaymentDetails'
import { useHistory, Link } from 'react-router-dom'
import ProductCardUserMini from '../ProductCardUserMini/ProductCardUserMini'
import ProductCardInStockMini from '../ProductCardInStockMini/ProductCardInStockMini'

const AddedProductsWrap = styled.div``

const ProductsWrap = styled.div``

const ProductsHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 50px 0;
`

const TitleWrap = styled.div`
    display: flex;
    align-items: center;

    h2 {
        margin-right: 10px;

        @media (max-width: 380px) {
            font-size: 20px;
        }
    }
`

const NoProduct = styled.p`
    color: tomato;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 8px;
`

const ProductsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;
    margin-top: 16px;
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(33.333333% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;

    @media (max-width: 1180px) {
        max-width: calc(50% - 30px);
    }

    @media (max-width: 768px) {
        max-width: calc(100% - 30px);
    }

    @media (max-width: 380px) {
        max-width: calc(100% - 30px);
    }
`

const TotalSumWrap = styled.div`
    max-width: 500px;
    margin-top: 16px;
    margin-bottom: 50px;
`

function ProductList({ orders, formula, selectPurchase, purchaseId }) {
    const history = useHistory()

    const currentProducts = orders[0] ? orders[0].products : []

    const [products, setProducts] = useState(currentProducts)

    const countProducts = useMemo(
        () =>
            products.reduce(function (sum, { quantity }) {
                return sum + quantity
            }, 0),
        [products]
    )

    return (
        <AddedProductsWrap>
            {orders.length !== 0 && (
                <ProductsWrap>
                    <ProductsHeader>
                        {(products.length > 0 || orders[0]?.productsInStock?.length > 0) && (
                            <TitleWrap>
                                <h2>Ваши добавленные товары:</h2>

                                <Tag color='cyan'>
                                    {/*<p>{countProducts} шт</p>*/}
                                    <p>{countProducts + (orders[0]?.productsInStock?.length || 0)} шт</p>
                                </Tag>
                            </TitleWrap>
                        )}

                        {(products.length > 0 || orders[0]?.productsInStock?.length > 0) && (
                            <TotalSumWrap>
                                <PaymentDetails
                                    order={orders}
                                    products={products}
                                    formula={formula}
                                    selectPurchase={selectPurchase}
                                />
                            </TotalSumWrap>
                        )}

                        {selectPurchase && selectPurchase.status === true ? (
                            <Button
                                onClick={() => {
                                    history.push(
                                        `/purchase/${purchaseId}/add-product/${orders[0]._id}?title=${selectPurchase.title}`
                                    )
                                }}
                                type='primary'
                                size='large'
                            >
                                Добавить товар
                            </Button>
                        ) : null}
                    </ProductsHeader>

                    {products.length === 0 && orders[0]?.productsInStock?.length === 0 ? (
                        <ProductsHeader>
                            <NoProduct>Вы не добавили еще ни одного товара</NoProduct>
                        </ProductsHeader>
                    ) : (
                        <>
                            <Alert
                                message='ВАЖНО:'
                                description={
                                    <p>
                                        Перед тем как добавить товар — прочитайте пожалуйста
                                        <Link to='/client-faq' exact>
                                            {' '}
                                            условия{' '}
                                        </Link>
                                        и убедитесь в том, сможете ли вы его забрать в дни раздачи! <br />
                                        В другие дни раздача не осуществляется! <br />
                                        Если Вы случайно добавили товар или передумали его заказывать, то обязательно
                                        удалите его!
                                    </p>
                                }
                                type='info'
                                showIcon
                                style={{ marginTop: 16 }}
                            />

                            <ProductsContext.Provider value={{ setProducts, products }}>
                                <ProductsList>
                                    {products.map((item) => (
                                        <CardWrap key={item._id}>
                                            <ProductCardUserMini
                                                item={item}
                                                paramId={purchaseId}
                                                formula={formula}
                                                selectPurchase={selectPurchase}
                                            />
                                        </CardWrap>
                                    ))}

                                    {orders[0]?.productsInStock?.length > 0 &&
                                        orders[0]?.productsInStock.map((item) => (
                                            <CardWrap key={item._id}>
                                                <ProductCardInStockMini item={item} />
                                            </CardWrap>
                                        ))}
                                </ProductsList>
                            </ProductsContext.Provider>
                        </>
                    )}
                </ProductsWrap>
            )}
        </AddedProductsWrap>
    )
}

export default memo(ProductList)
