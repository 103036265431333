import React from "react"
import ReactFC from "react-fusioncharts"
import FusionCharts from "fusioncharts"
import Column2D from "fusioncharts/fusioncharts.charts"
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion"

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

const ChartStatusProducts = ({ totalProducts, totalProductsCompleted, totalProductsNoCompleted }) => {
    const newArr = [
        { label: 'Всего товаров', value: totalProducts, },
        { label: 'Выкупленно', value: totalProductsCompleted, color: '#85DB97' },
        { label: 'Не выкупленно', value: totalProductsNoCompleted, color: '#fb8769' },
    ]

    const chartConfigs = {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: {
            chart: {
                caption: "Выкупы / невыкупы",
                theme: "fusion",
                useplotgradientcolor: "0",
                showDivLineValues: false,
                showLimits: false,

            },
            data: newArr
        }
    };

    return <ReactFC {...chartConfigs} />
};

export default ChartStatusProducts;