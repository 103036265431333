export const totalPriceProductInStockBY = (items) => {
    if (!items) {
        return 0
    }

    const total = items?.reduce(function (sum, { price }) {
        return sum + price
    }, 0)

    return total
}
