import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, message, Alert } from 'antd'
import axios from 'axios'
import { dispatchLogin } from '../../redux/actions/authAction'
import { useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'

const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    form {
        width: 100%;

        button {
            width: 100%;
        }

        .ant-row {
            display: block;

            .ant-form-item-label {
                max-width: 250px;
                display: block;
                overflow: hidden;
                white-space: normal;
                text-align: left;
                vertical-align: auto;
            }

            .ant-form-item-label > label {
                display: block;
            }
        }
    }
`

const Text = styled.p`
    margin-bottom: 50px;
    text-align: center;
    font-size: 20px;
`

const TextTwo = styled.p`
    text-align: left;
    font-size: 12px;
    margin-bottom: 6px;
`

const TextSucces = styled.p`
    text-align: left;
    font-size: 16px;
    color: green;
    margin-bottom: 6px;
`

const TextError = styled.p`
    text-align: left;
    font-size: 12px;
    color: tomato;
    margin-bottom: 6px;
`

function LoginSMS() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCode, setIsLoadingCode] = useState(false)
    const [userPhone, setUserPhone] = useState(null)
    const [isSuccesSubmitPhone, setIsSuccesSubmitPhone] = useState(false)
    const [isTimeExceeded, setIsTimeExceeded] = useState(false)

    const [isBlackList, setIsBlackList] = useState(false)

    const [form] = Form.useForm()

    let timer = null

    const onFinish = async (values) => {
        setIsLoading(true)
        const { phone } = values
        setUserPhone(phone)

        try {
            const { data } = await axios.post('/user/code_sms', { phone })

            if (data?.status === 'error-isBlackList') {
                setIsLoadingCode(false)
                setIsBlackList(true)
                return
            }

            setIsSuccesSubmitPhone(true)
            setIsTimeExceeded(false)
            setIsLoading(false)

            timer = setTimeout(() => {
                setIsSuccesSubmitPhone(false)
                setIsTimeExceeded(true)
                form.resetFields(['code'])
            }, 80000)
        } catch (err) {
            setIsLoading(false)
            console.log('code_sms', err)
        }
    }

    const onActivationSMS = async (values) => {
        setIsLoadingCode(true)
        const { code } = values

        try {
            await axios.post('/user/activation_sms', { phone: userPhone, code })

            localStorage.setItem('firstLogin', true)

            setIsLoadingCode(false)
            dispatch(dispatchLogin())
            history.push('/')
        } catch (err) {
            setIsLoadingCode(false)
            message.error('Неверный код')
            console.log('activation_sms', err)
        }
    }

    const content = (
        <div>
            <b>Вы находитесь в черном списке. Обратитесь к администратору для выяснения обстоятельств!:</b>

            <p>* в инстаграм - sadovod_bel</p>

            <p>
                * или в телеграм -{' '}
                <a href={'https://t.me/Katerina_sad'} target={'_blank'}>
                    https://t.me/Katerina_sad
                </a>
            </p>
        </div>
    )

    useEffect(() => {
        clearTimeout(timer)
    }, [timer])

    if (isBlackList) {
        return <Alert message='Вход запрещен!' description={content} type='warning' />
    }

    return (
        <Wrapper>
            <Text>Для вашего удобства - мы перешли на авторизацию через СМС</Text>

            {!isSuccesSubmitPhone ? (
                <>
                    <TextTwo>На указанный номер придет СМС с кодом</TextTwo>
                    {isTimeExceeded && <TextError>Превышенно время ожидания</TextError>}

                    <Form initialValues={{}} name='basic' onFinish={onFinish} size='large' form={form}>
                        <Form.Item name='phone' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                            <InputMask mask='375-(99)-999-99-99'>
                                {(inputProps) => (
                                    <Input size='large' type='tel' placeholder='Ваш телефон' {...inputProps} />
                                )}
                            </InputMask>
                        </Form.Item>

                        {!isTimeExceeded ? (
                            <Form.Item>
                                <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                                    {isLoading ? 'Загрузка...' : 'Получить смс'}
                                </Button>
                            </Form.Item>
                        ) : (
                            <Form.Item>
                                <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                                    {isLoading ? 'Загрузка...' : 'Получить смс повторно'}
                                </Button>
                            </Form.Item>
                        )}
                    </Form>
                </>
            ) : (
                <>
                    <TextSucces>Ожидайте, на ваш номер отправлено СМС</TextSucces>
                    <TextTwo>Введите четыре цифры из СМС (придёт в течение 90 сек)</TextTwo>

                    <Form name='onActivationSMS' initialValues={{}} onFinish={onActivationSMS} size='large'>
                        <Form.Item name='code' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                            <Input size='large' placeholder='Код из смс' />
                        </Form.Item>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' size='large' disabled={isLoadingCode}>
                                {isLoadingCode ? 'Загрузка...' : 'Подтвердить'}
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}
        </Wrapper>
    )
}

export default LoginSMS
