import React, { useState, useEffect } from 'react'
import axios from 'axios'

export const usePurchase = (token, paramId) => {
    const [isLoadingPurchase, setIsLoadingPurchase] = useState(false)
    const [purchase, setPurchase] = useState(null)

    const getPurchase = async () => {
        setIsLoadingPurchase(true)

        try {
            const res = await axios.get(`/purchase/${paramId}`, {
                headers: { Authorization: token }
            })

            setPurchase(res.data[0])
            setIsLoadingPurchase(false)
        } catch (err) {
            console.log('err', err)
            setIsLoadingPurchase(false)
        }
    }

    useEffect(() => {
        if (token && paramId) {
            getPurchase()
        }
    }, [token, paramId])

    return { isLoadingPurchase, purchase, getPurchase }
}
