import { useState, useEffect } from 'react'
import axios from 'axios'

export const useAllProductsInStock = (userId) => {
    const [isLoadingProducts, setIsLoadingProducts] = useState(false)
    const [products, setProducts] = useState([])
    const [errorProducts, setErrorProducts] = useState(null)

    const getProducts = async () => {
        try {
            setIsLoadingProducts(true)

            const { data } = await axios.get(`/product-in-stock/all`)

            const arr = data.filter((item) => !item.status)

            setProducts(arr)
            setIsLoadingProducts(false)
        } catch (err) {
            setErrorProducts(err)
            setIsLoadingProducts(false)
        }
    }

    useEffect(() => {
        void getProducts()
    }, [])

    return {
        isLoadingProducts,
        products,
        setProducts,
        errorProducts
    }
}
