import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import HomePage from '../pages/HomePage/HomePage'
import LoginPage from '../pages/LoginPage/LoginPage'
import RegisterPage from '../pages/RegisterPage/RegisterPage'
import ActivationEmail from '../pages/ActivationEmail/ActivationEmail'
import PurchasePage from '../pages/PurchasePage/PurchasePage'
import PurchaseAddPage from '../pages/PurchaseAddPage/PurchaseAddPage'
import AddProductPage from '../pages/AddProductPage/AddProductPage'
import AdminCreatePurchase from '../pages/AdminCreatePurchase/AdminCreatePurchase'
import AdminOpenPurchase from '../pages/AdminOpenPurchase/AdminOpenPurchase'
import AdminAllPurchase from '../pages/AdminAllPurchase/AdminAllPurchase'
import AdminProductsRefOrder from '../pages/AdminProductsRefOrder/AdminProductsRefOrder'
import AdminTableOrdersPage from '../pages/AdminTableOrdersPage/AdminTableOrdersPage'
import AdminAddProductPage from '../pages/AdminAddProductPage/AdminAddProductPage'
import AdminTableBelpochta from '../pages/AdminTableBelpochta/AdminTableBelpochta'
import AdminStatisticsPage from '../pages/AdminStatisticsPage/AdminStatisticsPage'
import AdminTableSamovivoz from '../pages/AdminTableSamovivoz/AdminTableSamovivoz'
import AdminTableEvropochta from '../pages/AdminTableEvropochta/AdminTableEvropochta'
import AdminTableGeoMinsk from '../pages/AdminTableGeoMinsk/AdminTableGeoMinsk'
import AdminSettingsPage from '../pages/AdminSettingsPage/AdminSettingsPage'
import AdminTableClientsPage from '../pages/AdminTableClientsPage/AdminTableClientsPage'
import ProductsRefOrder from '../pages/ProductsRefOrder/ProductsRefOrder'
import PopularProductsPage from '../pages/PopularProductsPage/PopularProductsPage'
import NotFound from '../pages/NotFound/NotFound'
import AdminTableScreenshots from '../pages/AdminTableScreenshots/AdminTableScreenshots'
import AdminLinksPage from '../pages/AdminLinksPage/AdminLinksPage'
import СatalogPage from '../pages/СatalogPage/СatalogPage'
import FaqPage from '../pages/FaqPage/FaqPage'
import LoginAdminPage from '../pages/LoginAdminPage/LoginAdminPage'
import AdminSelectOrder from '../pages/AdminSelectOrder/AdminSelectOrder'
import AdminTableSMS from '../pages/AdminTableSMS/AdminTableSMS'
import LoginPagev2 from '../pages/LoginPagev2/LoginPagev2'
import LoginSMS from '../pages/LoginSMS/LoginSMS'
import OpenClientOrder from '../pages/OpenClientOrder/OpenClientOrder'
import AdminInStock from '../pages/AdminInStock/AdminInStock'
import ClientInStock from '../pages/ClientInStock/ClientInStock'
import ManagerOpenPurchase from '../pages/ManagerOpenPurchase/ManagerOpenPurchase'
import ManagerClosedPurchase from '../pages/ManagerClosedPurchase/ManagerClosedPurchase'
import ManagerTableOrdersPage from '../pages/ManagerTableOrdersPage/ManagerTableOrdersPage'
import AdminListManagers from '../pages/AdminListManagers/AdminListManagers'
import ManagerProductsByOrder from '../pages/ManagerProductsByOrder/ManagerProductsByOrder'
import AdminTableManagers from '../pages/AdminTableManagers/AdminTableManagers'
import { OrdersProvider } from '../pages/AdminTableManagers'
import ManagerRecalculating from '../pages/ManagerRecalculating/ManagerClosedPurchase'

export const useRoutes = (isAuthe, isAdmin, isManager) => {
    if (isAuthe) {
        if (isManager) {
            return (
                <Switch>
                    <Route path='/' exact>
                        <HomePage />
                    </Route>

                    <Route path='/manager/open-purchases' exact>
                        <ManagerOpenPurchase />
                    </Route>

                    <Route path='/manager/all-purchases' exact>
                        <ManagerClosedPurchase />
                    </Route>

                    <Route path='/manager/recalculating' exact>
                        <ManagerRecalculating />
                    </Route>

                    <Route path='/manager/table-orders/:id' exact>
                        <ManagerTableOrdersPage />
                    </Route>

                    <Route path='/purchase/:id/add-product/:orderId' exact>
                        <AddProductPage />
                    </Route>

                    <Route path='/manager/products-by-order/:purchaseId/:orderId' exact>
                        <ManagerProductsByOrder />
                    </Route>

                    <Route path='*' component={NotFound} exact />
                </Switch>
            )
        }

        return (
            <Switch>
                <Route path='/' exact>
                    <HomePage />
                </Route>

                <Route path='/demo-order/:orderId' exact>
                    <OpenClientOrder />
                </Route>

                <Route path='/client/open-products/' exact>
                    <ClientInStock />
                </Route>

                <Route path='/client-purchase/:id' exact>
                    <PurchaseAddPage />
                </Route>

                <Route path='/purchase/:id/add-product/:orderId' exact>
                    <AddProductPage />
                </Route>

                <Route path='/client-purchase' exact>
                    <PurchasePage />
                </Route>

                <Route path='/client-catalog' exact>
                    <СatalogPage />
                </Route>

                <Route path='/client-faq' exact>
                    <FaqPage />
                </Route>

                <Route path='/popular' exact>
                    <PopularProductsPage />
                </Route>

                <Route path='/history-products/:orderId' exact>
                    <ProductsRefOrder />
                </Route>

                <Route path='/admin-settings' component={isAdmin ? AdminSettingsPage : NotFound} exact />
                <Route path='/admin/create-purchase' component={isAdmin ? AdminCreatePurchase : NotFound} exact />
                <Route path='/admin/open-purchases' component={isAdmin ? AdminOpenPurchase : NotFound} exact />
                <Route path='/admin/order-products/:id' component={isAdmin ? AdminProductsRefOrder : NotFound} exact />
                <Route path='/admin/all-purchases' component={isAdmin ? AdminAllPurchase : NotFound} exact />
                <Route path='/admin/table-orders/:id' component={isAdmin ? AdminTableOrdersPage : NotFound} exact />
                <Route path='/admin/managers' component={isAdmin ? AdminListManagers : NotFound} exact />

                <Route
                    path='/admin/add-product/:id/:purchaseId'
                    component={isAdmin ? AdminAddProductPage : NotFound}
                    exact
                />
                <Route
                    path='/admin/table-belpochta/:purchaseId'
                    component={isAdmin ? AdminTableBelpochta : NotFound}
                    exact
                />
                <Route
                    path='/admin/table-samovivoz/:purchaseId'
                    component={isAdmin ? AdminTableSamovivoz : NotFound}
                    exact
                />
                <Route
                    path='/admin/table-evropochta/:purchaseId'
                    component={isAdmin ? AdminTableEvropochta : NotFound}
                    exact
                />
                <Route
                    path='/admin/table-geoMinsk/:purchaseId'
                    component={isAdmin ? AdminTableGeoMinsk : NotFound}
                    exact
                />
                <Route path='/admin/table-sms/:purchaseId' component={isAdmin ? AdminTableSMS : NotFound} exact />

                {isAdmin && (
                    <Route path='/admin/table-managers/:purchaseId/' exact>
                        <OrdersProvider>
                            <AdminTableManagers />
                        </OrdersProvider>
                    </Route>
                )}

                <Route path='/admin/links' component={isAdmin ? AdminLinksPage : NotFound} exact />
                <Route
                    path='/admin/statistics/:purchaseId'
                    component={isAdmin ? AdminStatisticsPage : NotFound}
                    exact
                />
                <Route path='/admin/clients' component={isAdmin ? AdminTableClientsPage : NotFound} exact />
                <Route path='/admin/order/:orderId' component={isAdmin ? AdminSelectOrder : NotFound} exact />
                <Route
                    path='/admin/table-screenshots/:purchaseId'
                    component={isAdmin ? AdminTableScreenshots : NotFound}
                    exact
                />
                <Route path='/admin/in-stock' component={AdminInStock} exact />

                {/* <Redirect to="/" /> */}

                <Route path='*' component={NotFound} exact />
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path='/' exact>
                <LoginSMS />
            </Route>

            <Route path='/demo-order/:orderId' exact>
                <OpenClientOrder />
            </Route>

            <Route path='/client/open-products/' exact>
                <ClientInStock />
            </Route>

            <Route path='/admin-login' exact>
                <LoginAdminPage />
            </Route>

            <Route path='/admin-login-google' exact>
                {/* <LoginSMS /> */}
                <LoginPagev2 />
            </Route>

            <Route path='/register' exact>
                <RegisterPage />
            </Route>

            <Route path='/user/activate/:activation_token' component={ActivationEmail} exact />

            {/* <Redirect to="/" /> */}
        </Switch>
    )
}
