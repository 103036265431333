import React, { useState, useEffect, useMemo } from 'react'
import { PageInner } from '../../components/shared/PageInner'
import axios from 'axios'
import styled from 'styled-components'
import ChartStatusProducts from '../../charts/ChartStatusProducts'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from '../../components/shared/Loader'
import { totalPriceBY } from '../../utils/totalPriceBY'

const ContentWrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
`

const StatisticsWrap = styled.div`
    display: flex;
    margin-bottom: 60px;
`

const OrdersBlock = styled.div`
    width: 30%;
    margin-right: 70px;
`

const ProductsBlock = styled.div`
    width: 30%;
`

const LeftBlock = styled.div`
    width: 59%;

    li {
        display: flex;

        &.yes {
            color: #43c443;
        }

        &.no {
            color: tomato;
        }

        &.pending {
            color: gray;
        }
    }

    span {
        color: #1890ff;
        margin-left: auto;
        margin-right: 10px;
    }
`

const RightBlock = styled.div`
    width: 40%;
`

const TotalSumProdicts = styled.div`
    width: 70%;
`

const ChartStatusProductWrap = styled.div`
    position: relative;
    margin-bottom: 50px;

    .spanTest {
        position: absolute;
        height: 30px;
        background-color: white;
        width: 200px;
        bottom: 00px;
        left: 0;
    }
`

function AdminStatisticsPage() {
    const params = useParams()
    const token = useSelector((state) => state.token)

    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [products, setProducts] = useState([])

    const [isLoadingFormula, setIsLoadingFormula] = useState(false)
    const [formula, setFormula] = useState()
    const [errorFormula, setErrorFormula] = useState(null)

    const [isLoadingPurchase, setIsLoadingPurchase] = useState(false)
    const [purchase, setPurchase] = useState(null)

    const filteredOrders = orders.filter((item) => item.products.length > 0)

    const ordersFromAdmin = orders.filter((item) => item.isAdmin).length
    const ordersFromClient = orders.filter((item) => !item.isAdmin).length

    const ordersDeliveryMinsk = orders.filter((item) => item.info.delivery === 'geoMinsk')

    const resultSumBy = useMemo(() => {
        let total = 0

        orders.forEach(({ products, author, personalPercentAdmdin }) => {
            const sumProducts = totalPriceBY(
                products,
                formula?.course,
                formula?.percent,
                author?.personalPercent,
                personalPercentAdmdin,
                purchase[0].purchaseCourse
            )
            return (total = total + +sumProducts)
        })

        return total
    }, [orders, formula?.course, formula?.percent, purchase])

    const sumDeliveryMinsk = useMemo(() => {
        let total = 0

        ordersDeliveryMinsk.forEach(({ products, author, personalPercentAdmdin }) => {
            const sumProducts = totalPriceBY(
                products,
                formula?.course,
                formula?.percent,
                author?.personalPercent,
                personalPercentAdmdin,
                purchase[0].purchaseCourse
            )
            return (total = total + +sumProducts)
        })

        return total
    }, [ordersDeliveryMinsk, formula?.course, formula?.percent, purchase])

    const totalProductsCompleted = useMemo(
        () =>
            products.reduce(function (count, { status, quantity, preQuantity }) {
                if (status === 'yes') {
                    if (!preQuantity) {
                        return count + quantity
                    }
                    return count + preQuantity
                }
                return count
            }, 0),
        [products]
    )

    const totalProductsNoCompleted = useMemo(
        () =>
            products.reduce(function (count, { status, quantity }) {
                if (status === 'no') {
                    return count + quantity
                }
                return count
            }, 0),
        [products]
    )

    const totalProductsPending = useMemo(
        () =>
            products.reduce(function (count, { status, quantity }) {
                if (status === 'pending') {
                    return count + quantity
                }
                return count
            }, 0),
        [products]
    )

    const totalProductsDefective = useMemo(
        () =>
            products.reduce(function (count, { status, checkDefects, quantity, preQuantity }) {
                if (checkDefects && status === 'yes') {
                    if (!preQuantity) {
                        return count + quantity
                    }

                    return count + preQuantity
                }
                return count
            }, 0),
        [products]
    )

    const sumProductsDefective = useMemo(
        () =>
            products.reduce(function (sum, { checkDefects, price, quantity, status, preQuantity }) {
                if (checkDefects && status === 'yes') {
                    if (!preQuantity) {
                        return sum + formula?.checkDefectsPrice * quantity
                    }

                    return sum + formula?.checkDefectsPrice * preQuantity
                }
                return sum
            }, 0),
        [products, formula?.checkDefectsPrice]
    )

    const sumProductsCompleted = useMemo(
        () =>
            products.reduce(function (sum, { price, quantity, status, preQuantity }) {
                if (status === 'yes') {
                    if (!preQuantity) {
                        return sum + price * quantity
                    }

                    return sum + price * preQuantity
                }
                return sum
            }, 0),
        [products]
    )

    const totalSumAddedProductRU = useMemo(
        () =>
            products.reduce(function (sum, { price, quantity }) {
                return sum + price * quantity
            }, 0),
        [products]
    )

    const totalAllProducts = useMemo(
        () =>
            products.reduce(function (count, { quantity }) {
                return count + quantity
            }, 0),
        [products]
    )

    useEffect(() => {
        const getFormula = async () => {
            try {
                const { data } = await axios.get(`/settings/formula`, {
                    headers: { Authorization: token }
                })

                setFormula(data)
                setIsLoadingFormula(false)
            } catch (err) {
                setErrorFormula(err.response?.data?.msg)
                setIsLoadingFormula(false)
            }
        }
        getFormula()
    }, [token])

    useEffect(() => {
        const getAllOrders = async () => {
            setIsLoading(true)
            try {
                const res = await axios.get(`/order/all/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })
                setOrders(res.data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }
        getAllOrders()
    }, [token, params.purchaseId])

    useEffect(() => {
        const getAllProducts = async () => {
            setIsLoading(true)
            try {
                const res = await axios.get(`/product/all/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })
                setProducts(res.data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }
        getAllProducts()
    }, [token, params.purchaseId])

    useEffect(() => {
        const getPurchase = async () => {
            setIsLoadingPurchase(true)
            try {
                const res = await axios.get(`/purchase/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })
                setPurchase(res.data)
                setIsLoadingPurchase(false)
            } catch (err) {
                console.log('err', err)
                setIsLoadingPurchase(false)
            }
        }
        getPurchase()
    }, [token, params.purchaseId])

    if (isLoading || isLoadingFormula || isLoadingPurchase) {
        return <Loader />
    }

    return (
        <PageInner>
            <div className='container'>
                <h2>Статистика по выбранной закупке:</h2>

                <ContentWrap>
                    <LeftBlock>
                        <StatisticsWrap>
                            <OrdersBlock>
                                <h4>Заказы:</h4>

                                <ul>
                                    <li>
                                        Заказов от клиента: <span>{ordersFromClient}</span>
                                    </li>
                                    <li>
                                        Заказов от админа: <span>{ordersFromAdmin}</span>
                                    </li>
                                    <li>
                                        {' '}
                                        <b>Всего заказов:</b> <span>{filteredOrders.length}</span>
                                    </li>
                                </ul>
                            </OrdersBlock>

                            <ProductsBlock>
                                <h4>Товары:</h4>

                                <ul>
                                    <li className='yes'>
                                        Товаров выкупленно: <span>{totalProductsCompleted}</span>{' '}
                                    </li>
                                    {/* <li className='no'>Товаров не выкупленно: <span>{totalProductsNoCompleted}</span> </li> */}
                                    <li className='pending'>
                                        Товаров "ожидается": <span>{totalProductsPending}</span>{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        <b>Всего товаров:</b> <span>{totalAllProducts}</span>{' '}
                                    </li>
                                </ul>
                            </ProductsBlock>
                        </StatisticsWrap>

                        <TotalSumProdicts>
                            <h4>Общее:</h4>

                            <ul>
                                <li>
                                    Общая сумма добавленых товаров в рос.руб:{' '}
                                    <span>{totalSumAddedProductRU}</span>{' '}
                                </li>
                                <li>
                                    Сумма выкупленных товаров в рос.руб:{' '}
                                    <span>{sumProductsCompleted}</span>{' '}
                                </li>
                                <li>
                                    Цена товаров в бел.руб: <span>{resultSumBy.toFixed(2)}</span>{' '}
                                </li>
                                <li>
                                    Сумма с доставки по Минску: <span>{sumDeliveryMinsk}</span>{' '}
                                </li>
                                <li>
                                    Кол-во проверок на брак: <span>{totalProductsDefective}</span>{' '}
                                </li>
                                <li>
                                    Стоимость проверок на брак в рос.руб:{' '}
                                    <span>{sumProductsDefective}</span>{' '}
                                </li>
                            </ul>
                        </TotalSumProdicts>
                    </LeftBlock>

                    {/* <RightBlock>
                        <ChartStatusProductWrap>
                            <ChartStatusProducts
                                totalProducts={totalAllProducts}
                                totalProductsCompleted={totalProductsCompleted}
                                totalProductsNoCompleted={totalProductsNoCompleted}
                            />
                            <span className='spanTest'></span>
                        </ChartStatusProductWrap>
                    </RightBlock> */}
                </ContentWrap>
            </div>
        </PageInner>
    )
}

export default AdminStatisticsPage
