import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'

export const useOrders = (id, token, managerId) => {
    const [isLoadingOrders, setIsLoadingOrders] = useState(false)
    const [orders, setOrders] = useState([])

    const getOrders = async () => {
        setIsLoadingOrders(true)

        try {
            const res = await axios.get(`/order/ref-purchase/${id}?manager=${managerId}`, {
                headers: { Authorization: token }
            })

            setOrders(res.data)
            setIsLoadingOrders(false)
        } catch (err) {
            console.log('err', err)
            setIsLoadingOrders(false)
        }
    }

    useEffect(() => {
        getOrders()
    }, [id, token])

    return { isLoadingOrders, orders, getOrders }
}
