import React from 'react'
import styled from 'styled-components'
import { PageInner } from '../../components/shared/PageInner'
import TitlePage from '../../components/shared/TitlePage'
import AddProduct from '../../components/AddProduct/AddProduct'

const Wrapper = styled.div`
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding-top: 30px;
`

function AdminAddProductPage() {
    return (
        <PageInner>
            <div className='container'>
                <TitlePage>Добавление товаров от имени админа:</TitlePage>

                <Wrapper>
                    <AddProduct />
                </Wrapper>
            </div>
        </PageInner>
    )
}

export default AdminAddProductPage
