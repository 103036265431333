export function filterOrderByStatus(data, status) {
    return data.filter(function (item) {
        let productsInStock = item?.productsInStock || []
        let products = [...item.products, ...productsInStock]

        products = item.products.filter(function (product) {
            return product.status === status || product.status === 'booking'
        })

        return products.length || productsInStock.length
    })
}
