import React from 'react'
import { Alert } from 'antd'

const PurchaseInfo = ({ distribution, deliveryDays, paymentDays, mailDays }) => {
    return (
        <Alert
            message={
                <div>
                    <h3>Общая информация:</h3>

                    <ul>
                        <li>
                            {' '}
                            <b>Раздача:</b> {distribution} с 17:00 до 21:00
                        </li>
                        <li>
                            {' '}
                            <b>Доставка по Минску:</b> {deliveryDays} с 12:00 до 22:00
                        </li>
                        <li>
                            {' '}
                            <b>Оплата:</b> {paymentDays}
                        </li>
                        <li>
                            {' '}
                            <b>Отправка почты:</b> {mailDays}
                        </li>
                    </ul>
                </div>
            }
            type='info'
        />
    )
}

export default PurchaseInfo
