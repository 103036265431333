import React, { useState, memo } from 'react'
import { Button, Alert, Tag, Popover, Modal } from 'antd'
import styled from 'styled-components'
import EditOrderInfo from '../EditOrderInfo/EditOrderInfo'
import AddScreenshotReceipt from './AddScreenshotReceipt';


const CardWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 450px;
    background: #fff;
    transform-style: preserve-3d;
    border-radius: 15px;
    box-shadow: 0 10px 35px rgb(0 0 0 / 5%);    
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 15px;   

    &:after {
        content: "";
        position: absolute;
        width: 96%;
        height: 30px;
        background-color: #fff;
        left: 2%;
        bottom: -7px;
        transform: translateZ(-1px);
        box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
        border-radius: 15px;
    }
`

const CardInner = styled.div`
    border: 1px solid #ededed;
    border-radius: 15px;
    height: 100%;
    padding: 4px 15px 20px 15px;    
`

const CardTitle = styled.span`
    display: block;
    font-family: 'Caveat', cursive;
    color: #585a62;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
`

const CardDateWrap = styled.div`
    margin-bottom: 15px;

    p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0;
        border-bottom: 1px dotted #b3b3b3;
    }
`

const TrackerWrap = styled.div`
    margin-bottom: 12px;
`

const StatusScreenshot = styled.p`
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;

    &.rejected {
        color: tomato;
    }

    &.approved {
        color: #74cf78;
    }

    &.pending {
        color: #c99463;
    }
`

const BtnsControl = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;


    @media(max-width: 470px) {
        flex-direction: column;

        button {
            &:first-child {
                margin-bottom: 16px;
            }
        }
    }
`


function OrderInfo({ order, showModalScreenShot, purchaseStatus, handleUpdateOrder }) {
    const { userName, phone, delivery, address, pochtaIndex, numberEvropochta } = order[0].info
    const { screenshotReceipt } = order[0]

    const [isModalVisible, setIsModalVisible] = useState(false)

    const deliveryJSX = {
        samovivoz: 'Самовывоз',
        belpochta: 'Белпочта',
        evropochta: 'Европочта',
        geoMinsk: 'Доставка по Минску',
    }

    const isShowAddScreenshot = delivery !== 'samovivoz' && delivery !== 'geoMinsk'

    const content = (
        <div style={{ position: 'relative' }}>
            {screenshotReceipt && screenshotReceipt.pathPhoto
                ? <img
                    src={`/public/screenshotReceipt/${screenshotReceipt.pathPhoto}`}
                    alt="Фото чека"
                    style={{ width: 300, height: 300, objectFit: 'cover' }}
                />

                : null
            }
        </div>
    )

    const showModal = () => {
        setIsModalVisible(true);
    }

    const handleCancel = () => (
        setIsModalVisible(false)
    )

    const screenshotReceiptContentJSX = {
        rejected: { className: 'rejected', text: 'Ваше фото чека отклонили! Пожалуйста, сделайте новое фото и проверьте, что на фото всё читабельно.' },
        approved: { className: 'approved', text: 'Оплата принята.' },
        pending: { className: 'pending', text: 'Оплата в ожидании проверки.' },
    }

    return (
        <>
            <CardWrap>
                <CardInner>
                    <CardTitle>Ваши контакты:</CardTitle>

                    <TrackerWrap>
                        <Tag color="cyan">Номер заказа: <b>{order[0].articleOrder}</b> </Tag>
                    </TrackerWrap>

                    {order[0].trackerPochta
                        ? <TrackerWrap>
                            <Alert
                                message={`Трек-номер: ${order[0].trackerPochta}`}
                                type="warning"
                                showIcon
                            />
                        </TrackerWrap>
                        : null
                    }

                    <CardDateWrap>
                        <p>ФИО - <span>{userName}</span> </p>
                        <p>Телефон - <span>{phone}</span> </p>
                        <p>Тип доставки - <span>{deliveryJSX[delivery]}</span> </p>
                        {address && <p>Адрес - <span>{address}</span> </p>}
                        {pochtaIndex && <p>Индекс - <span>{pochtaIndex}</span> </p>}
                        {numberEvropochta && <p>Номер отделения Европочты - <span>{numberEvropochta}</span> </p>}
                    </CardDateWrap>


                    {screenshotReceipt && purchaseStatus === false &&
                        <StatusScreenshot className={screenshotReceiptContentJSX[screenshotReceipt.status].className}>
                            {screenshotReceiptContentJSX[screenshotReceipt.status].text}
                        </StatusScreenshot>
                    }


                    <BtnsControl className='control-info' size='middle'>
                        {purchaseStatus !== false && <Button onClick={showModal} type='primary'>Редактировать контакты</Button>}

                        {purchaseStatus === true
                            ? null
                            : <AddScreenshotReceipt
                                screenshotReceipt={screenshotReceipt}
                                content={content}
                                isShowAddScreenshot={isShowAddScreenshot}
                                showModalScreenShot={showModalScreenShot}
                                products={order[0].products}
                            />
                        }

                    </BtnsControl>
                </CardInner>
            </CardWrap>


            <Modal
                title="Редактирование ваших данных по данной закупке:"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                {order.length !== 0 &&
                    <EditOrderInfo
                        order={order[0]}
                        handleCancel={handleCancel}
                        handleUpdateOrder={handleUpdateOrder}
                    />
                }
            </Modal>

        </>
    )
}

export default memo(OrderInfo)

