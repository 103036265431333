import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Empty } from 'antd'
import TitlePage from '../../components/shared/TitlePage'
import CardPurchase from '../../components/CardPurchase/CardPurchase'
import { PageInner } from '../../components/shared/PageInner'
import Loader from '../../components/shared/Loader'
import { getOpenPurchases } from '../../redux/actions/usersAction'

const OpenPurchasesWrap = styled.div`
    margin-bottom: 30px;
`

const ClosedPurchasesWrap = styled.div``

const PurchaseList = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;
`

function PurchasePage() {
    const dispatch = useDispatch()

    const token = useSelector((state) => state.token)
    const { user } = useSelector((state) => state.auth)

    const { openPurchases: purchases, isLoading } = useSelector((state) => state.users)

    const hiddenOpenPurchases = purchases.filter((item) => item.status === true && item.isTest === true)
    const openPurchases = purchases.filter((item) => item.status === true && !item.isTest)

    const hiddenClosedPurchases = purchases.filter(
        (item) => item.status === false && item.isTest && item.isTest === true
    )
    const closedPurchases = purchases.filter((item) => item.status === false && !item.isTest)

    const renderOpenPurchases = !user?.isTester ? openPurchases : [...openPurchases, ...hiddenOpenPurchases]
    const renderClosedPurchases = !user?.isTester ? closedPurchases : [...closedPurchases, ...hiddenClosedPurchases]

    useEffect(() => {
        dispatch(getOpenPurchases(token))
    }, [token, dispatch])

    if (isLoading) {
        return <Loader />
    }

    return (
        <section>
            <div className='container'>
                <PageInner>
                    <OpenPurchasesWrap>
                        <TitlePage>Открытые закупки:</TitlePage>

                        {openPurchases.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                        <PurchaseList>
                            {renderOpenPurchases.map((item) => (
                                <CardPurchase key={item._id} item={item} />
                            ))}
                        </PurchaseList>
                    </OpenPurchasesWrap>

                    <ClosedPurchasesWrap>
                        <TitlePage>Прошедшие закупки:</TitlePage>

                        {closedPurchases.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                        <PurchaseList>
                            {renderClosedPurchases.map((item) => (
                                <CardPurchase key={item._id} item={item} closed />
                            ))}
                        </PurchaseList>
                    </ClosedPurchasesWrap>
                </PageInner>
            </div>
        </section>
    )
}

export default PurchasePage
