import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Space, message, Popover, Popconfirm, Empty } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { PageInner } from '../../components/shared/PageInner'
import AddCategory from '../../components/AddCategory/AddCategory'
import { useAllCategories } from '../../hooks/useAllCategories'
import AddLink from '../../components/AddLink/AddLink'
import EditCategory from '../../components/EditCategory/EditCategory'
import EditLink from '../../components/EditLink/EditLink '

const ContentWrap = styled.div`
    width: 100%;
    display: flex;
    border: 1px solid black;
`

const PageTitle = styled.h2`
    text-align: center;
`

const ListCategories = styled.ul`
    width: 100%;
    max-width: 400px;
    border-right: 1px solid black;

    padding: 16px;

    li {
        cursor: pointer;

        &.active {
            color: red;
        }
    }
`

const LinksBlock = styled.div`
    width: 100%;
    padding: 16px;
`

const TitleSelectCategory = styled.h4`
    margin-bottom: 30px;

    b {
        color: tomato;
        margin-right: 16px;
    }
`

const LinkItem = styled.li`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #ecececc5;
    }

    &:last-child {
        padding-bottom: 0;
    }

    .nameLink {
        width: 100%;
        max-width: 30%;
        margin-right: 30px;
    }

    .descLink {
        width: 100%;
        max-width: 50%;
        margin-right: 30px;
        line-height: 18px;
    }
`

function AdminLinksPage() {
    const token = useSelector((state) => state.token)

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const auth = useSelector((state) => state.auth)

    const [visibleCategory, setVisibleCategory] = useState(null)

    const { isLoadingCategories, categories, setCategories, errorCategories } = useAllCategories()

    const [ordersAll, setOrdersAll] = useState([]) // удалить

    // const getAllOrders = async () => {
    //     try {
    //         const { data } = await axios.get('/order/allorders', {
    //             headers: { Authorization: token }
    //         })

    //         setOrdersAll(data)

    //         console.log('data', data)
    //     } catch (err) {
    //         console.log('getAllOrders', err)
    //     }
    // }

    // const handlerUpdateUserPhoneLogin = () => {
    //     const newArr = ordersAll.filter(({ articleOrder }) => articleOrder > 1282)

    //     newArr.forEach(({ info, author, articleOrder }, index) => {
    //         // if (articleOrder > 1282) {
    //         //     setTimeout(function () {
    //         //         axios.post('/order/orders-update-phone', { phone: info.phone, author, articleOrder })
    //         //     }, index * 1000)
    //         // }

    //         setTimeout(function () {
    //             axios.post('/order/orders-update-phone', { phone: info.phone, author, articleOrder })
    //         }, index * 1000)
    //     })
    // }

    const handleAddCategory = (newCategory) => {
        setCategories((prev) => [...prev, newCategory])
    }

    const handleAddLink = (newLink, categoryId) => {
        const newArr1 = categories.map((item) => {
            if (item._id === categoryId) {
                const newArrLinks = { ...item, links: [...item.links, newLink] }
                return newArrLinks
            }
            return item
        })

        const newVisibleCategory = newArr1.find((item) => item._id === categoryId)

        if (visibleCategory) {
            setCategories(newArr1)
            setVisibleCategory(newVisibleCategory)
        }
    }

    const onDeleteLink = async (linkId) => {
        setIsLoading(true)

        try {
            const { data } = await axios.delete(`/link/delete/${linkId}`, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            message.success(`${data.msg}`)

            const newArrCategories = categories.map((item) => {
                if (item._id === visibleCategory._id) {
                    const newArrLinks = { ...item, links: [...item.links.filter((item) => item._id !== linkId)] }
                    return newArrLinks
                }
                return item
            })

            const newVisibleCategory = newArrCategories.find((item) => item._id === visibleCategory._id)

            if (visibleCategory) {
                setCategories(newArrCategories)
                setVisibleCategory(newVisibleCategory)
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err)
            message.error(`Ошибка при удалении ссылки`)
        }
    }

    const onUpdateLink = (linkId, newLink) => {
        const newArrCategories = categories.map((item) => {
            if (item._id === visibleCategory._id) {
                const newArrLinks = item.links.map((link) => {
                    let newItem = { ...link }

                    if (link._id === linkId) {
                        newItem = { ...newLink }
                    }
                    return newItem
                })

                const newCategory = { ...item, links: [...newArrLinks] }
                return newCategory
            }

            return item
        })

        const newVisibleCategory = newArrCategories.find((item) => item._id === visibleCategory._id)

        if (visibleCategory) {
            setCategories(newArrCategories)
            setVisibleCategory(newVisibleCategory)
        }
    }

    const onUpdateCategory = (categoryId, newCategory) => {
        const newArrCategories = categories.map((item) => {
            if (item._id === categoryId) {
                return { ...item, name: newCategory.name }
            }

            return item
        })

        const newVisibleCategory = newArrCategories.find((item) => item._id === visibleCategory._id)

        setCategories(newArrCategories)
        setVisibleCategory(newVisibleCategory)
    }

    function confirm(linkId) {
        onDeleteLink(linkId)
    }

    if (!auth.isAdmin) {
        return <h2>У вас нет доступа к этой странице!</h2>
    }

    if (error) {
        return <div className='container'>{error}</div>
    }

    return (
        <section>
            <div className='container'>
                {/* <button type='button' onClick={getAllOrders}>
                    получить все заказы
                </button> */}

                {/* <button type='button' onClick={handlerUpdateUserPhoneLogin}>
                    обновить юзеров
                </button> */}

                <PageInner>
                    <PageTitle>Ссылки на катологи</PageTitle>

                    <AddCategory addCategories={handleAddCategory} />

                    <ContentWrap>
                        <ListCategories>
                            {categories.map((item) => (
                                <li
                                    key={item._id}
                                    onClick={() => setVisibleCategory(item)}
                                    className={visibleCategory && item.name === visibleCategory.name ? 'active' : ''}
                                >
                                    {item.name}
                                </li>
                            ))}
                        </ListCategories>

                        <LinksBlock>
                            {visibleCategory && (
                                <TitleSelectCategory>
                                    Выбранная категория - <b>{visibleCategory.name}</b>
                                    <Popover
                                        content={<EditCategory item={visibleCategory} updateCategory={onUpdateCategory} />}
                                        title='Редактировать категорию'
                                        trigger='click'
                                        placement='topLeft'
                                    >
                                        <Button>Редактировать</Button>
                                    </Popover>
                                </TitleSelectCategory>
                            )}

                            {visibleCategory && <AddLink categoryId={visibleCategory._id} addLink={handleAddLink} />}

                            {visibleCategory && (
                                <TitleSelectCategory>
                                    Ссылки для категории - <b>{visibleCategory.name}</b>
                                </TitleSelectCategory>
                            )}

                            {visibleCategory && visibleCategory.links.length !== 0 ? (
                                <ul>
                                    {visibleCategory.links.map((item) => (
                                        <LinkItem key={item._id}>
                                            <p className='nameLink'>
                                                <a href={`${item.pathLink}`} target='_blank' rel='noopener noreferrer'>
                                                    {item.title}
                                                </a>
                                            </p>

                                            <span className='descLink'>{item.description}</span>

                                            <Space>
                                                <Popconfirm
                                                    title='Точно удалить даннуб ссылку？'
                                                    okText='Да'
                                                    cancelText='Нет'
                                                    onConfirm={() => confirm(item._id)}
                                                >
                                                    <Button type='text'>Удалить</Button>
                                                </Popconfirm>

                                                <Popover
                                                    content={<EditLink item={item} updateLink={onUpdateLink} />}
                                                    title='Редактировать ссылку'
                                                    trigger='click'
                                                    placement='topLeft'
                                                >
                                                    <Button type='text'>Редактировать</Button>
                                                </Popover>
                                            </Space>
                                        </LinkItem>
                                    ))}
                                </ul>
                            ) : (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )}
                        </LinksBlock>
                    </ContentWrap>
                </PageInner>
            </div>
        </section>
    )
}

export default AdminLinksPage
