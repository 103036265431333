import React, { useState, useEffect, memo } from 'react'
import styled from 'styled-components'
import { Popover, Button, Input, Form, message, Space } from 'antd'
import axios from 'axios'
import { CommentOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setOrdersAdmin } from '../../redux/actions/adminActions'

const { TextArea } = Input

const Wrapper = styled.div`
    padding: 0 8px;
`

const IconWrap = styled.span`
    cursor: pointer;
    transition: color 0.2s linear;

    &.comment {
        position: relative;
        color: tomato;

        &:after {
            position: absolute;
            content: '';
            top: -6px;
            left: 4px;
            width: 4px;
            height: 4px;
            background-color: tomato;
            border-radius: 50%;
        }
    }

    &:hover {
        color: #1890ff;
    }
`

const FormWrap = styled.div`
    width: 100%;
    max-width: 400px;
`

function ModalAddCommenInOrder({ order, getOrders }) {
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const token = useSelector((state) => state.token)
    const orders = useSelector((state) => state.admin.orders)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async ({ comment }) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(
                `/order/add-comment/${order._id}`,
                { comment },
                {
                    headers: { Authorization: token }
                }
            )

            const newArr = orders.map((item) => {
                if (item._id === order._id) {
                    const newOrder = { ...item, comment: comment }
                    return newOrder
                }
                return item
            })

            setIsLoading(false)
            message.success(data.msg)
            setIsModalVisible(false)

            if (getOrders) {
                return getOrders()
            }

            dispatch(setOrdersAdmin(newArr))
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const handleDeleteComment = async () => {
        setIsLoading(true)

        try {
            await axios.patch(
                `/order/add-comment/${order._id}`,
                { comment: null },
                {
                    headers: { Authorization: token }
                }
            )

            const newArr = orders.map((item) => {
                if (item._id === order._id) {
                    const newOrder = { ...item, comment: null }
                    return newOrder
                }
                return item
            })

            setIsLoading(false)
            setIsModalVisible(false)
            message.success('Удаление успешно!')

            if (getOrders) {
                return getOrders()
            }

            dispatch(setOrdersAdmin(newArr))
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const content = (
        <FormWrap>
            <Form form={form} name={`addCommentInOrder_${order._id}`} onFinish={onFinish} size='large'>
                <Form.Item name='comment' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <TextArea rows={4} placeholder='Текст комментария' />
                </Form.Item>

                <Space>
                    <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>

                    <Button
                        type='primary'
                        onClick={handleDeleteComment}
                        size='large'
                        disabled={isLoading | !order.comment}
                    >
                        {isLoading ? 'Загрузка...' : 'Удалить'}
                    </Button>
                </Space>
            </Form>
        </FormWrap>
    )

    useEffect(() => {
        form.setFieldsValue({
            comment: order.comment
        })
    }, [form, order.comment])

    return (
        <Wrapper>
            <Popover
                content={content}
                title='Коментарий к заказу:'
                trigger='click'
                visible={isModalVisible}
                onVisibleChange={setIsModalVisible}
                overlayStyle={{ width: 400 }}
                forceRender
            >
                <IconWrap className={order.comment && 'comment'}>
                    <CommentOutlined />
                </IconWrap>
            </Popover>
        </Wrapper>
    )
}

export default memo(ModalAddCommenInOrder)
