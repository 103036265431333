import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Button, InputNumber, Space, message, Input, Table, Modal } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { PageInner } from '../../components/shared/PageInner'

const ContentWrapper = styled.div`
    width: 100%;
`

const PageTitle = styled.h2`
    text-align: center;
`

const FormulaWrap = styled.div`
    width: 100%;
    margin-bottom: 50px;

    form {
        width: 100%;
        max-width: 550px;
        margin: 0 auto;

        .ant-input-number {
            width: 100%;
        }

        .ant-space {
            width: 100%;
        }
    }
`

const ContentTitle = styled.h4`
    text-align: center;
    margin-bottom: 16px;
`

function AdminListManagers() {
    const token = useSelector((state) => state.token)
    const auth = useSelector((state) => state.auth)

    const [form] = Form.useForm()

    const [formEdit] = Form.useForm()

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentManager, setCurrentManager] = useState(null)

    const [managers, setManagers] = useState([])

    const columns = [
        {
            title: 'Имя / Ник',
            dataIndex: 'managerName',
            key: 'managerName',
            render: (text, record, index) => {
                return (
                    <Button type='primary' onClick={() => showModal(record)}>
                        {text}
                    </Button>
                )
            }
        },
        {
            title: 'Телефон',
            dataIndex: 'phoneLogin',
            key: 'phoneLogin'
        },
        {
            title: 'Курс',
            dataIndex: 'managerCourse',
            key: 'managerCourse'
        },
        {
            title: 'Процент',
            dataIndex: 'managerPercent',
            key: 'managerPercent'
        },
        {
            title: 'Пер-ый процент',
            dataIndex: 'managerPersonalPercent',
            key: 'managerPersonalPercent'
        },
        {
            title: 'Опционально',
            dataIndex: 'managerOptional',
            key: 'managerOptional'
        }
    ]

    const getAllManagers = async () => {
        try {
            const { data } = await axios.get(`/user/all-manager`, {
                headers: { Authorization: token }
            })

            setManagers(data)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
        }
    }

    const onFinish = async (values) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(
                `/user/add/manager`,
                { ...values },
                {
                    headers: { Authorization: token }
                }
            )

            setIsLoading(false)

            if (data?.status === 'error') {
                message.error(data?.message)
                return
            }

            if (data?.status === 'success') {
                message.success(data?.message)
                getAllManagers()
                form.resetFields()
                return
            }
        } catch (err) {
            setError(err.response?.data?.msg)
            setIsLoading(false)
        }
    }

    const onEditManager = async (values) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(
                `/user/edit/manager/${currentManager._id}`,
                { ...values },
                {
                    headers: { Authorization: token }
                }
            )

            setIsLoading(false)

            if (data?.status === 'success') {
                message.success(data?.message)
                getAllManagers()
                handleCancel()
                return
            }
        } catch (err) {
            setError(err.response?.data?.msg)
            setIsLoading(false)
        }
    }

    const showModal = (obj) => {
        formEdit.setFieldsValue({ ...obj })
        setCurrentManager(obj)
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        formEdit.resetFields()
        setCurrentManager(null)
        setIsModalVisible(false)
    }

    useEffect(() => {
        getAllManagers()
    }, [token])

    if (!auth.isAdmin) {
        return <h2>У вас нет доступа к этой странице!</h2>
    }

    if (error) {
        return <div className='container'>Что-то пошло не так, попробуйте обновить страницу.</div>
    }

    return (
        <section>
            <div className='container'>
                <PageInner>
                    <PageTitle>Менеджеры</PageTitle>

                    <ContentWrapper>
                        <FormulaWrap>
                            <ContentTitle>Новый manager:</ContentTitle>

                            <Form form={form} name='adminlistmanagers' onFinish={onFinish} initialValues={{}}>
                                <Space direction='vertical'>
                                    <Form.Item
                                        label='Телефон'
                                        name='phone'
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <Input placeholder='Телефон*' size='large' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Ник / обозначение'
                                        name='managerName'
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <Input placeholder='Ник / обозначение*' size='large' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Курс'
                                        name='managerCourse'
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Курс*' size='large' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Процент'
                                        name='managerPercent'
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Процент*' size='large' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Персональный процент'
                                        name='managerPersonalPercent'
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Персональный процент*' size='large' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Опционально'
                                        name='managerOptional'
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Optional*' size='large' />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                                            Добавить менеджера
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </FormulaWrap>
                    </ContentWrapper>

                    <Table columns={columns} dataSource={managers} rowKey='_id' pagination={false} />
                </PageInner>
            </div>

            <Modal
                title={`Карточка менеджера - ${currentManager?.managerName}`}
                visible={isModalVisible}
                onOk={false}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    form={formEdit}
                    name='adminlistmanagers2'
                    onFinish={onEditManager}
                    initialValues={{ ...currentManager }}
                >
                    <Space direction='vertical'>
                        <Form.Item
                            label='Ник / обозначение'
                            name='managerName'
                            rules={[{ required: true, message: 'Обязательное поле' }]}
                        >
                            <Input placeholder='Ник / обозначение*' size='large' />
                        </Form.Item>

                        <Form.Item
                            label='Курс'
                            name='managerCourse'
                            rules={[{ required: true, message: 'Обязательное поле' }]}
                        >
                            <InputNumber placeholder='Курс*' size='large' />
                        </Form.Item>

                        <Form.Item
                            label='Процент'
                            name='managerPercent'
                            rules={[{ required: true, message: 'Обязательное поле' }]}
                        >
                            <InputNumber placeholder='Процент*' size='large' />
                        </Form.Item>

                        <Form.Item
                            label='Персональный процент'
                            name='managerPersonalPercent'
                            rules={[{ required: true, message: 'Обязательное поле' }]}
                        >
                            <InputNumber placeholder='Персональный процент*' size='large' />
                        </Form.Item>

                        <Form.Item
                            label='Опционально'
                            name='managerOptional'
                            rules={[{ required: true, message: 'Обязательное поле' }]}
                        >
                            <InputNumber placeholder='Optional*' size='large' />
                        </Form.Item>

                        <Form.Item>
                            <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                                Редактировать менеджера
                            </Button>
                        </Form.Item>
                    </Space>
                </Form>
            </Modal>
        </section>
    )
}

export default AdminListManagers
