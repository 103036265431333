import React, { useEffect, useState } from 'react'
import { PageInner } from '../../components/shared/PageInner'
import TitlePage from '../../components/shared/TitlePage'
import { Row, Col, Button } from 'antd'
import { useSelector } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import ProductCardAdmin from '../../components/ProductCardAdmin/ProductCardAdmin'
import axios from 'axios'
import styled from 'styled-components';

const BtnBackWrap = styled.div`
    text-align: center;
    margin-top: 30px;    
`


function AdminSelectOrder() {
    const token = useSelector(state => state.token)

    const history = useHistory()

    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const paramValue = query.get('page')
    const valuePageSize = query.get('pageSize')


    const { orderId } = useParams()

    const [isLOading, setIsLoading] = useState(false)

    const [order, setOrder] = useState(null)

    const updateStatusProduct = (status, productId) => {
        const newArrProducts = order.products.map((item) => {
            if (item._id === productId) {
                return { ...item, status: status }
            }
            return item
        })

        const newOrder = { ...order, products: newArrProducts }
        setOrder(newOrder)
    }

    const addComment = (productId, comment) => {
        const newArrProducts = order.products.map((item) => {

            if (item._id === productId) {
                return { ...item, commentAdmin: comment }
            }
            return item
        })

        const newOrder = { ...order, products: newArrProducts }
        setOrder(newOrder)
    }

    const deleteProduct = (productId) => {
        const newArrProducts = order.products.filter((product) => product._id !== productId)

        const newOrder = { ...order, products: newArrProducts }
        setOrder(newOrder)
    }


    useEffect(() => {
        const getOrderById = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/order/orders_by_id/${orderId}`, {
                    headers: { Authorization: token }
                })

                setIsLoading(false)
                setOrder(data)

            } catch (err) {
                console.log(err)
                setIsLoading(false)
            }
        }
        getOrderById()

    }, [orderId, token])

    if (isLOading) {
        return <div className='container'>Загрузка</div>
    }

    return (
        <div className='container'>

            <PageInner>
                <TitlePage>Товары от заказа № {order?.articleOrder}:</TitlePage>

                {
                    order && order.products.length === 0 && <div>Товаров в этом заказе еще нет!</div>
                }

                {order &&
                    <Row gutter={[30, 30]}>
                        {
                            order.products.map((item) => (
                                <Col key={item._id} span={12}>
                                    <ProductCardAdmin
                                        item={item}
                                        orderId={orderId}
                                        updateStatusProduct={updateStatusProduct}
                                        addComment={addComment}
                                        deleteProduct={deleteProduct}
                                        purshaseId={order.purchase}
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                }

                <BtnBackWrap>
                    <Button
                        type='primary'
                        onClick={() => history.push(`/admin/table-orders/${order.purchase}?page=${paramValue}&pageSize=${valuePageSize}`)}
                        danger
                    >
                        Вернуться на страницу заказа
                    </Button>
                </BtnBackWrap>


            </PageInner>
        </div>
    )
}

export default AdminSelectOrder
