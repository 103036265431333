import React, { useState, useContext } from 'react'
import { Button } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ProductsContext } from '../../context/productsContext'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

function DeleteProduct({ productId, showModal, orderId }) {
    const token = useSelector(state => state.token)
    const params = useParams()
    const { setProducts, products } = useContext(ProductsContext)

    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = async () => {
        setIsLoading(true)

        try {
            await axios.delete(`/product/delete/${productId}/${params.id}/${orderId}`, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            const newArr = products.filter((item) => item._id !== productId)
            setProducts(newArr)
            showModal()

        } catch (err) {
            setIsLoading(false)
            console.log('handleDelete', err);
            const newArr = products.filter((item) => item._id !== productId)
            setProducts(newArr)
            showModal()
        }
    }

    return (
        <Wrapper>
            {!isLoading ? 'Вы уверены, что хотите удалить данный продукт?' : 'Удаляем товар...'}

            <div>
                <Button onClick={showModal}>Нет</Button>
                <Button onClick={handleDelete}>Да</Button>
            </div>
        </Wrapper>
    )
}

export default DeleteProduct
