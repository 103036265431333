import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import { useRoutes } from './routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchLogin, fetchUser, dispatchGetUser } from './redux/actions/authAction'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Loader from './components/shared/Loader'

const AppWrap = styled.div`
    min-height: calc(100vh - 124px);
`

function App() {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.token)
    const auth = useSelector((state) => state.auth)

    const routes = useRoutes(auth.isLogged, auth.isAdmin, auth.user.role === 3)

    const [isLoadingToken, setIsLoadingToken] = useState(false)
    const [isLoadingUser, setIsLoadingUser] = useState(false)

    useEffect(() => {
        const firstLogin = localStorage.getItem('firstLogin')

        if (firstLogin) {
            const getToken = async () => {
                setIsLoadingToken(true)

                try {
                    const res = await axios.post('/user/refresh_token', null)

                    setIsLoadingToken(false)

                    dispatch({ type: 'GET_TOKEN', payload: res.data.access_token })
                } catch (err) {
                    console.log('err-getToken', err)
                    setIsLoadingToken(false)
                }
            }

            getToken()
        }
    }, [auth.isLogged, dispatch])

    useEffect(() => {
        if (token) {
            const getUser = async () => {
                try {
                    setIsLoadingUser(true)
                    dispatch(dispatchLogin())

                    return fetchUser(token).then((res) => {
                        setIsLoadingUser(false)
                        dispatch(dispatchGetUser(res))
                    })
                } catch (err) {
                    console.log('err-getUser', err)
                    setIsLoadingUser(false)
                }
            }

            getUser()
        }
    }, [token, dispatch])

    if (isLoadingToken || isLoadingUser) {
        return <Loader />
    }

    return (
        <>
            <Router>
                {auth.isLogged && <Header />}

                <AppWrap>{routes}</AppWrap>

                {auth.isLogged && <Footer />}
            </Router>
        </>
    )
}

export default App
