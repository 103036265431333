import React from 'react'
import AddProduct from '../../components/AddProduct/AddProduct'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    padding-top: 30px;
`

function AddProductPage() {
    return (
        <Wrapper>
            <AddProduct />
        </Wrapper>
    )
}

export default AddProductPage
