import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
    text-align: center;
    font-size: 24px;
    margin-bottom: 16px;
`

function TitlePage({ children }) {
    return (
        <Title>{children}</Title>
    )
}

export default TitlePage
