import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { Modal } from 'antd'
import ProductCardAdmin from '../ProductCardAdmin/ProductCardAdmin'
import Button from '../shared/Button'
import { OrdersContext } from '../../pages/AdminTableManagers'

const UserName = styled.p`
    cursor: pointer;
    transition: color 0.2s linear;

    &:hover {
        color: #1890ff;
    }
`

const RowBtns = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    button,
    a {
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
`

const ProductsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(50% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
`

function ModalProductsByOrderAdmin({
    orderId,
    userName,
    isAdmin,
    products,
    onUpdateProduct,
    onUpdateProductStatus,
    onUpdatePreQuantity,
    getOrders
}) {
    const { orders } = useContext(OrdersContext)

    const productsJSX = orders.find((order) => order._id === orderId)?.products || []

    const params = useParams()

    const [isModalProductsVisible, setIsModalProductsVisible] = useState(false)

    const hadleShowModal = () => {
        setIsModalProductsVisible(true)
    }

    const handleCancel = () => {
        setIsModalProductsVisible(false)
    }

    return (
        <>
            <UserName onClick={hadleShowModal}>{userName}</UserName>

            <Modal
                title='Продукты по текущему заказу:'
                visible={isModalProductsVisible}
                footer={null}
                width={1200}
                closable={false}
            >
                <RowBtns>
                    {isAdmin && (
                        <Link to={`/demo-order/${orderId}`} target='_blank'>
                            Перейти к заказу
                        </Link>
                    )}

                    {isAdmin && (
                        <Button
                            variant='primary'
                            onClick={() => {
                                navigator.clipboard.writeText(`${window.location.origin}/demo-order/${orderId}`)
                                handleCancel()
                            }}
                        >
                            скопировать ссылку
                        </Button>
                    )}

                    <Button variant='secondary' onClick={handleCancel}>
                        Закрыть
                    </Button>
                </RowBtns>

                {productsJSX?.length !== 0 ? (
                    <ProductsList>
                        {productsJSX.map((item) => (
                            <CardWrap key={item._id}>
                                <ProductCardAdmin
                                    item={item}
                                    paramId={params.purchaseId}
                                    orderId={orderId}
                                    orders={orders}
                                    onUpdateProduct={onUpdateProduct}
                                    onUpdateProductStatus={onUpdateProductStatus}
                                    onUpdatePreQuantity={onUpdatePreQuantity}
                                    getOrders={getOrders}
                                />
                            </CardWrap>
                        ))}
                    </ProductsList>
                ) : (
                    <p>Здесь товаров нет.</p>
                )}

                {productsJSX?.length >= 3 && (
                    <RowBtns>
                        <Button variant='secondary' onClick={handleCancel}>
                            Закрыть
                        </Button>
                    </RowBtns>
                )}
            </Modal>
        </>
    )
}

export default ModalProductsByOrderAdmin
