import React, { useState } from 'react'
import { Form, Input, Button, Space } from 'antd'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'

const FormWrap = styled.div`
    width: 100%;
    margin-bottom: 16px;

    form {
        width: 100%;

        .ant-input-number {
            width: 100%;
        }
    }
`

function AddLink({ categoryId, addLink }) {
    const token = useSelector((state) => state.token)

    const [isLoading, setIsLoading] = useState(false)

    const [form] = Form.useForm()

    const onFinish = async (values) => {
        setIsLoading(true)

        try {
            const { data } = await axios.post(
                `/link/create/${categoryId}`,
                { ...values },
                {
                    headers: { Authorization: token }
                }
            )

            setIsLoading(false)
            addLink(data.newLink, categoryId)
            form.resetFields()
        } catch (err) {
            console.log('err-AddCategory', err)
            setIsLoading(false)
        }
    }

    return (
        <FormWrap>
            <Form form={form} name='addLink' onFinish={onFinish} initialValues={{}}>
                <Space direction='horizontal'>
                    <Form.Item name='title' rules={[{ required: true, message: 'Обязательное поле' }]}>
                        <Input placeholder='Название ссылки' />
                    </Form.Item>

                    <Form.Item name='pathLink' rules={[{ required: true, message: 'Обязательное поле' }]}>
                        <Input placeholder='Адрес ссылки' />
                    </Form.Item>

                    <Form.Item name='description'>
                        <Input placeholder='Описание ссылки' />
                    </Form.Item>

                    <Form.Item>
                        <Button type='primary' htmlType='submit' disabled={isLoading}>
                            Добавить ссылку
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </FormWrap>
    )
}

export default AddLink
