import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Input, Button } from 'antd'
import { NavLink } from 'react-router-dom'
import axios from 'axios'


const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    form {
        width: 100%;

        button {
            width: 100%;
        }
    }
`

function RegisterPage() {
    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async (values) => {
        setIsLoading(true)

        const { password, email } = values

        try {
            await axios.post('/user/register', {
                email, password
            })
            setIsLoading(false)

        } catch (err) {
            console.log('err-RegisterPage', err)
            setIsLoading(false)
        }
    }

    return (
        <Wrapper>
            <h2>Register</h2>

            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                size='large'
            >

                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <Input size='large' placeholder='email' />
                </Form.Item>

                {/* <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <Input size='large' placeholder='username' />
                </Form.Item> */}

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <Input.Password placeholder='password' />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Обязательное поле!',
                        },

                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Пароли не совподают!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder='confirm password' />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size='large' disabled={isLoading}>
                        Submit
                    </Button>
                </Form.Item>

                <NavLink to='/'>
                    У меня уже есть аккаунт
                </NavLink>
            </Form>
        </Wrapper>


    )
}

export default RegisterPage
