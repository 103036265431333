import React, { memo } from 'react'
import { getProfitSumProductsByManager } from '../../utils/getProfitSumProductsByManager'

function ProfitSumProductsByManager({ products, user }) {
    console.log('user', user)

    if (!user) {
        return 0
    }

    const total = getProfitSumProductsByManager(products, user)

    return <div>{total.toFixed(2)}</div>
}

export default memo(ProfitSumProductsByManager)
