import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'
import LogoPNG from '../../assets/logo.png'
import MenuAdmin from '../MenuAdmin/MenuAdmin'
import { Button } from 'antd'

const HeaderWrap = styled.header`
    border-bottom: 4px solid rgb(221, 255, 255);
    padding: 14px 0;
`

const HeaderInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 830px) {
        flex-direction: column;
    }
`

const Logo = styled.div`
    svg,
    img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
    }
`

const HeaderMenu = styled.nav`
    @media (max-width: 830px) {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 16px;
    }
`

const HeaderLink = styled(NavLink)`
    margin: 0 15px;
    white-space: nowrap;

    &.active {
        color: #615dfa;
        border-bottom: 1px solid #615dfa;
        padding-bottom: 3px;
    }

    @media (max-width: 767px) {
        width: auto;
        display: inline;
        margin: 0 6px;

        &.active {
            color: #615dfa;
            border-bottom: none;
            padding-bottom: 0;
        }
    }
`

const HeaderBtns = styled.div`
    display: flex;
    align-items: center;

    .button {
        &:first-child {
            margin-right: 10px;
        }
    }
`

const UserNameWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media (max-width: 415px) {
        flex-direction: column;

        button {
            min-width: 150px;
            margin-top: 16px;
        }
    }
`

const UserName = styled.div`
    margin-left: auto;
    margin-right: 30px;

    @media (max-width: 415px) {
        margin-right: 0;
        margin-left: 0;
    }
`

function Header() {
    const auth = useSelector((state) => state.auth)
    const history = useHistory()

    const handleLogout = async () => {
        try {
            await axios.post('/user/logout', { id: auth.user._id })
            localStorage.removeItem('firstLogin')
            window.location.href = '/'
        } catch (err) {
            window.location.href = '/'
        }
    }

    return (
        <HeaderWrap>
            <div className='container'>
                <HeaderInner>
                    <Logo>
                        <img src={LogoPNG} alt='Logo' />
                        Sadovod_bel
                    </Logo>

                    <HeaderMenu>
                        {auth.isAdmin && <MenuAdmin />}

                        {auth.user.role === 3 && (
                            <>
                                <HeaderLink to='/' exact>
                                    Главная
                                </HeaderLink>

                                <HeaderLink to='/manager/open-purchases' exact>
                                    Открытые
                                </HeaderLink>

                                <HeaderLink to='/manager/all-purchases' exact>
                                    Закрытые
                                </HeaderLink>

                                <HeaderLink to='/manager/recalculating' exact>
                                    Таблица пересчета
                                </HeaderLink>
                            </>
                        )}

                        {auth.user.role === 0 && (
                            <>
                                <HeaderLink to='/' exact>
                                    Главная
                                </HeaderLink>

                                <HeaderLink to='/client-purchase' exact>
                                    Закупки
                                </HeaderLink>

                                {/* <HeaderLink to='/client-catalog' exact>
                                    Католог
                                </HeaderLink> */}

                                <HeaderLink to='/client/open-products/' exact>
                                    В наличии
                                </HeaderLink>

                                <HeaderLink to='/client-faq' exact>
                                    Вопросы
                                </HeaderLink>
                            </>
                        )}
                    </HeaderMenu>

                    <UserNameWrap>
                        <UserName>{auth.user?.phoneLogin || auth.user.email}</UserName>

                        {!auth.isLogged ? (
                            <HeaderBtns>
                                <Button text='Регистрация' onClick={() => history.push('/register')} type='primary'>
                                    Регистрация
                                </Button>

                                <Button text='Войти' onClick={() => history.push('/')}>
                                    Войти
                                </Button>
                            </HeaderBtns>
                        ) : (
                            <Button onClick={handleLogout} type='primary'>
                                Выйти
                            </Button>
                        )}
                    </UserNameWrap>
                </HeaderInner>
            </div>
        </HeaderWrap>
    )
}

export default Header
