import React from 'react'
import styled from 'styled-components'
import { PageInner } from '../../components/shared/PageInner'
import { useSelector } from 'react-redux'

const StyledPageInner = styled(PageInner)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

function ManagerRecalculating() {
    const { user } = useSelector((state) => state.auth)
    const { managerCourse, managerPercent, managerOptional } = user

    const minPrice = 100
    const maxPrice = 3000

    const arr = []

    for (let i = minPrice; i <= maxPrice; i = i + 50) {
        arr.push(i)
    }

    const getMinPrice = (price) => {
        return price * managerCourse * managerPercent + managerOptional
    }

    return (
        <PageInner>
            <div className='container'>
                <StyledPageInner>
                    {arr.map((item) => (
                        <div key={item}>
                            <span>{item}</span>
                            &ensp; &mdash; &ensp;
                            <span>{getMinPrice(item).toFixed(1)}</span>
                        </div>
                    ))}
                </StyledPageInner>
            </div>
        </PageInner>
    )
}

export default ManagerRecalculating
