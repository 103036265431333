import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { AdminProductsOrder } from '../../context/adminProductsOrder'
import ProductCardUser from '../../components/ProductCardUser/ProductCardUser'

const ProductsList = styled.div` 
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;  
    margin-bottom: 30px;     
`

const CardWrap = styled.div`
    min-height: 355px;
    width: 100%;
    max-width: calc(20% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
`

function AdminProductsRefOrder() {
    const token = useSelector(state => state.token)
    const params = useParams()

    const [isLoading, setIsLoading] = useState(false)

    const [products, setProducts] = useState([])

    useEffect(() => {
        const getOrders = async () => {
            setIsLoading(true)

            try {
                const res = await axios.get(`/product/order-products/${params.id}`, {
                    headers: { Authorization: token }
                })
                setProducts(res.data)
                setIsLoading(false)

            } catch (err) {
                setIsLoading(false)
                console.log('err', err);
            }
        }
        getOrders()

    }, [token, params.id])

    return (
        <AdminProductsOrder.Provider value={{ products, setProducts }}>
            <ProductsList>
                {products.map((item) =>
                    <CardWrap key={item._id}>
                        <ProductCardUser item={item} paramId={params.id} />
                    </CardWrap>
                )}
            </ProductsList>
        </AdminProductsOrder.Provider>
    )
}

export default AdminProductsRefOrder
