
import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Space, message } from 'antd'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'


const FormWrap = styled.div`
    width: 100%;    
    margin: 30px 0;
   
   form {
       width: 100%;

        .ant-input-number {
            width: 100%;
        }
   }
`

function EditLink({ item, updateLink }) {
    const token = useSelector(state => state.token)

    const [isLoading, setIsLoading] = useState(false)

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/link/update/${item._id}`, { ...values }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            updateLink(item._id, data.result)
            message.success(`${data.status}`)

        } catch (err) {
            console.log('err-AddCategory', err);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            title: item.title,
            pathLink: item.pathLink,
            description: item.description,

        })
    }, [
        form,
        item.title,
        item.pathLink,
        item.description,
    ])


    return (
        <FormWrap>

            <Form
                form={form}
                name="addLink"
                onFinish={onFinish}
                initialValues={{}}
            >

                <Space direction='horizontal'>
                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input placeholder='Название ссылки' />
                    </Form.Item>

                    <Form.Item
                        name="pathLink"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input placeholder='Адрес ссылки' />
                    </Form.Item>

                    <Form.Item
                        name="description"
                    >
                        <Input placeholder='Описание ссылки' />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={isLoading}>
                            Изменить ссылку
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </FormWrap>
    )
}

export default EditLink
