import React, { useState } from 'react'
import axios from 'axios'
import { Select, Form, Input, Button, InputNumber, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { addOrderFromAdmin } from '../../redux/actions/adminActions'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router-dom'

const { Option } = Select

const FormWrap = styled.div`
    max-width: 500px;
    margin: 0 auto;

    .ant-input-number {
        width: 100%;
    }

    .ant-row {
        display: block;
    }

    .label {
        label {
            display: block;
            color: #1890ff;
            line-height: 16px;
            opacity: 0.6;
            white-space: normal;
            text-align: left;
            margin-bottom: 10px;
            height: auto;

            &:after {
                display: none;
            }
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: none;
        }
    }
`

function AddOrderInfoFromAdmin({
    paramId,
    showModal,
    productInStock,
    handleCancel,
    updateListProducts,
    getProducts,
    titlePurchase
}) {
    const history = useHistory()

    const dispatch = useDispatch()
    const token = useSelector((state) => state.token)

    const [typeDelivery, setTypeDelivery] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const isAdres = typeDelivery && typeDelivery !== 'samovivoz' && typeDelivery !== 'evropochta'

    function handleSelectChange(value) {
        setTypeDelivery(value)
    }

    const onFinish = async ({
        delivery,
        address,
        phone,
        userName,
        pochtaIndex,
        numberEvropochta,
        personalPercentAdmdin,
        userLogin
    }) => {
        const info = {
            delivery,
            address,
            phone,
            userName,
            pochtaIndex,
            numberEvropochta,
            instaLogin: userLogin
        }

        setIsLoading(true)

        try {
            const res = await axios.post(
                `/order/create/${paramId}`,
                { info, personalPercentAdmdin, isAdmin: true, productInStock },
                {
                    headers: { Authorization: token }
                }
            )

            if (res?.data?.status === 'phone-exists-admin' && res?.data?.message) {
                message.error(res.data.message)
                setIsLoading(false)
                showModal && showModal()

                if (res?.data?.data) {
                    history.push({
                        pathname: `/admin/table-orders/${paramId}?articleOrder=${res?.data?.data}&title=${titlePurchase}`,
                        search: ''
                    })
                }

                return
            }

            if (res?.data?.status === 'phone-exists' && res?.data?.message) {
                message.success(res.data.message)
                setIsLoading(false)
                handleCancel && handleCancel()
                updateListProducts && updateListProducts(res?.data?.data)
                return
            }

            if (res?.data?.status === 'error-isBlackList' && res?.data?.message) {
                message.success(res.data.message)
                setIsLoading(false)
                handleCancel && handleCancel()
                updateListProducts && updateListProducts(res?.data?.data)
                return
            }

            setIsLoading(false)
            showModal && showModal()
            message.success('Заказ успешно создан')
            dispatch(addOrderFromAdmin(res.data.newOrder))
            handleCancel && handleCancel()
            getProducts && getProducts()
        } catch (err) {
            console.log('AddOrderInfoFromAdmin', err)
            setIsLoading(false)
        }
    }

    return (
        <FormWrap>
            <Form name='basic' initialValues={{}} onFinish={onFinish} size='large'>
                <Form.Item name='userName' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <Input size='large' placeholder='ФИО' />
                </Form.Item>

                <Form.Item name='userLogin'>
                    <Input size='large' placeholder='Логин' />
                </Form.Item>

                <Form.Item name='phone' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <InputMask mask='375-(99)-999-99-99'>
                        {(inputProps) => (
                            <Input size='large' type='tel' placeholder='Ваш телефон для связи' {...inputProps} />
                        )}
                    </InputMask>
                </Form.Item>

                <Form.Item name='delivery' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <Select onChange={handleSelectChange} className='select' placeholder='Тип доставки'>
                        <Option value='samovivoz'>Самовывоз</Option>
                        <Option value='belpochta'>Белпочта</Option>
                        <Option value='evropochta'>Европочта</Option>
                        <Option value='geoMinsk'>Доставка по Минску</Option>
                    </Select>
                </Form.Item>

                {isAdres ? (
                    <Form.Item name='address' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                        <Input placeholder='Точный адрес, куда доставить ваш заказ' />
                    </Form.Item>
                ) : null}

                {typeDelivery === 'belpochta' ? (
                    <Form.Item name='pochtaIndex' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                        <InputNumber placeholder='Индекс*' />
                    </Form.Item>
                ) : null}

                {typeDelivery === 'evropochta' ? (
                    <>
                        <Form.Item name='address' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                            <Input placeholder='Адрес отделения Европочты' />
                        </Form.Item>

                        <Form.Item
                            className='label'
                            name='numberEvropochta'
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input placeholder='Номер отделения Европочты' />
                        </Form.Item>
                    </>
                ) : null}

                <Form.Item
                    name='personalPercentAdmdin'
                    label='Оставляем пустым, если нет скидки'
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (value > 2) {
                                    return Promise.reject('Некорректное число! Вероятно пропущена точка.')
                                }
                                return Promise.resolve()
                            }
                        })
                    ]}
                >
                    <InputNumber placeholder='Персональная скидка (процент)' />
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>
                </Form.Item>
            </Form>
        </FormWrap>
    )
}

export default AddOrderInfoFromAdmin
