import React from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'
import { CommentOutlined } from '@ant-design/icons'

const CommentWrap = styled.span`
    color: tomato;
`

const IconWrap = styled.span`
    position: relative;
    color: tomato;
    margin-left: 6px;

    &:after {
        position: absolute;
        content: '';
        top: -2px;
        left: 4px;
        width: 4px;
        height: 4px;
        background-color: tomato;
        border-radius: 50%;
    }
`

function PopoverComment({ comment, className }) {

    const content = (
        <div>
            {comment}
        </div>
    )

    return (
        <Popover content={content} title="Комментарий:">
            <CommentWrap className={className}>
                <IconWrap>
                    <CommentOutlined />
                </IconWrap>
            </CommentWrap>
        </Popover>
    )
}

export default PopoverComment
