import axios from 'axios'

export const authenticateError = () => {
    axios.interceptors.response.use(
        (response) => {
            return response
        },
        function (error) {
            if (error.response.status === 401) {
                localStorage.removeItem('firstLogin')
                window.location.href = '/'
            }

            return Promise.reject(error.response)
        }
    )
}
