import React from 'react'
import styled, { css } from 'styled-components'
import { format } from 'date-fns'
import { Alert } from 'antd'
import { useHistory } from 'react-router-dom'
import Button from '../shared/Button'
import PurchaseInfo from '../PurchaseInfo/PurchaseInfo'

const CardWrap = styled.div`
    position: relative;
    width: calc(33.333333% - 30px);
    background: #fff;
    transform-style: preserve-3d;
    border-radius: 15px;
    box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
    margin-bottom: 40px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;

    &:after {
        content: "";
        position: absolute;
        width: 96%;
        height: 30px;
        background-color: #fff;
        left: 2%;
        bottom: -7px;
        transform: translateZ(-1px);
        box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
        border-radius: 15px;
    }

    ${({ isTest }) => isTest && isTest === true && css`
        background: #ffc0b5;        
  `}

    @media (max-width: 1280px) {
        width: calc(50% - 30px);        
    }

    @media (max-width: 960px) {
        width: calc(100% - 30px);        
    }
    
`

const CardInner = styled.div`
    border: 1px solid #ededed;
    border-radius: 15px;
    height: 100%;
    padding: 4px 15px 20px 15px;

    ${({ closed }) => closed && css`
        border: 1px solid tomato;
  `}
`

const CardTitle = styled.span`
    display: block;
    font-family: 'Caveat', cursive;
    color: #585a62;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
`

const CardDateWrap = styled.div`
    font-size: 16px;
    margin-bottom: 16px;  
    
    p {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const AlertWrap = styled.div`
    margin-bottom: 16px;
`


function CardPurchase({ item, closed }) {
    const history = useHistory()

    return (
        <CardWrap isTest={item.isTest}>
            <CardInner closed={closed}>
                <CardTitle>{item.title}</CardTitle>

                <CardDateWrap>
                    <p> <span>Начало сбора: </span> {format(new Date(item.dateStart), 'dd.MM.yyyy')}</p>
                    <p> <span>Заказы принимаются до: </span> {format(new Date(item.dateEnd), 'dd.MM.yyyy')}</p>
                    <p> <span>Дата привоза в Минск: </span> {format(new Date(item.dateOfDelivery), 'dd.MM.yyyy')}</p>
                </CardDateWrap>

                {item.warningMessage &&
                    <AlertWrap>
                        <Alert message={item.warningMessage} type="warning" showIcon />
                    </AlertWrap>
                }

                <AlertWrap>
                    <PurchaseInfo
                        distribution={item.distribution}
                        deliveryDays={item.deliveryDays}
                        paymentDays={item.paymentDays}
                        mailDays={item.mailDays}
                    />
                </AlertWrap>

                <Button
                    onClick={() => history.push(`/client-purchase/${item._id}`)}
                    variant='secondary'
                >
                    {closed ? 'Подробности' : 'Учавствовать'}
                </Button>
            </CardInner>
        </CardWrap>
    )
}

export default CardPurchase
