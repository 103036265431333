import React from 'react'
import styled from 'styled-components'
import { PageInner } from '../../components/shared/PageInner'
import TitlePage from '../../components/shared/TitlePage'
import { Tabs, Card, Empty, Row, Col } from 'antd'
import { useAllCategories } from '../../hooks/useAllCategories'
import Loader from '../../components/shared/Loader'

const { TabPane } = Tabs

const LinkList = styled.div`

    .ant-row {
        flex-direction: column;
    }
`

function СatalogPage() {

    const { isLoadingCategories, categories, errorCategories } = useAllCategories()

    if (errorCategories) {
        return <div className='container'>Что-то пошло не так.... Попробуйте обновить страницу.</div>
    }

    if (isLoadingCategories) {
        return <Loader />
    }

    return (
        <div className='container'>

            <PageInner>
                <TitlePage>Каталоги:</TitlePage>

                <Tabs defaultActiveKey="1" tabPosition='top'>
                    {
                        categories.map((category) => (
                            <TabPane tab={`${category.name}`} key={category._id}>

                                <LinkList>
                                    <Row gutter={[16, 16]} align='center'>
                                        {category.links.length !== 0
                                            ? category.links.map((link, index) => (
                                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} key={index}>
                                                    <Card>
                                                        <a href={`${link.pathLink}`} target="_blank" rel="noopener noreferrer">{link.title}</a>
                                                        <p>{link.description}</p>
                                                    </Card>
                                                </Col>
                                            ))

                                            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        }
                                    </Row>
                                </LinkList>
                            </TabPane>
                        ))
                    }
                </Tabs>

            </PageInner>
        </div>
    )
}

export default СatalogPage
