import React from 'react'
import styled from 'styled-components'

const Loader = () => {
  return (
    <Wrapper>
      <Circle />
    </Wrapper>
  );
};

export default Loader;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Circle = styled.div`
  border: 6px solid #f1f1f1;
  border-top: 6px solid #23d2e2;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
