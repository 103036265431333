import axios from 'axios'
import { message, } from 'antd'

export const dowloadPurchaseImages = async (purchaseId, title, token) => {

    try {
        const res = await axios.get(`/product/download/${purchaseId}`, {
            responseType: 'blob',
            headers: { Authorization: token }
        })

        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');

        link.href = downloadUrl;

        link.setAttribute('download', `${title}.zip`);

        document.body.appendChild(link);

        link.click()

        link.remove()

    } catch (err) {
        console.log('err-dowloadPurchaseImages', err)

        if (err.status === 400) {
            message.error(`В данной закупке не найдено фото...`)
        }
    }
}