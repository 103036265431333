export const getProfitSumProductsByManager = (products, user) => {
    if (!user) {
        return 0
    }

    const { managerCourse, managerPersonalPercent, managerOptional, managerPercent } = user

    const getMinPrice = (price) => {
        return price * managerCourse * managerPercent + managerOptional
    }

    const total = products?.reduce(function (sum, { price, status, quantity, managerPrice, preQuantity }) {
        if (status !== 'yes' || !status) {
            return sum
        }

        const minPrice = getMinPrice(price)

        if (minPrice === managerPrice) {
            return sum + minPrice * managerPersonalPercent * (preQuantity || quantity)
        }

        if (minPrice < managerPrice) {
            return sum + (minPrice * managerPersonalPercent + (managerPrice - minPrice)) * (preQuantity || quantity)
        }

        if (managerPrice < minPrice) {
            return sum + (minPrice * managerPersonalPercent - (minPrice - managerPrice)) * (preQuantity || quantity)
        }

        return sum
    }, 0)

    return total
}
