import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Popover, Button, Form, message, Space, InputNumber, Tag } from 'antd'
import axios from 'axios'
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'


const Wrapper = styled.div`        
    padding: 0 8px;    
`

const IconWrap = styled.span`
    cursor: pointer;
    transition: color .2s linear;

    &.comment {
        position: relative;
        color: tomato;

        &:after {
            position: absolute;
            content: '';
            top: -6px;
            left: 4px;
            width: 4px;
            height: 4px;
            background-color: tomato;
            border-radius: 50%;
        }
    }

    &:hover {
        color: #1890ff;
    }
`

const FormWrap = styled.div`
    width: 100%;
    max-width: 400px;  
    
    .ant-input-number {
        width: 100%;
    }
`


function EditPersonalPercent({ currentPercent, userId, handleUpdatePersonalPercent, handleDeletePersonalPercent }) {

    const [form] = Form.useForm()

    const token = useSelector(state => state.token)


    const [isModalVisible, setIsModalVisible] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async ({ personalPercent }) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/user/update_personal_percent`, { personalPercent, userId }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            setIsModalVisible(false)
            message.success(data.status)
            handleUpdatePersonalPercent(personalPercent, userId)

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }

    const handleDeleteComment = async () => {
        setIsLoading(true)

        try {
            await axios.patch(`/user/update_personal_percent`, { personalPercent: null, userId }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            setIsModalVisible(false)
            message.success('Удаление успешно!')
            handleDeletePersonalPercent(userId)

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }

    const content = (
        <FormWrap>
            <Form
                form={form}
                name="eddirPersonalPercent"
                onFinish={onFinish}
            >

                <Form.Item
                    name="personalPercent"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <InputNumber placeholder='Процент' />
                </Form.Item>

                <Space>
                    <Button type="primary" htmlType="submit" disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>

                    <Button type="primary" onClick={handleDeleteComment} disabled={isLoading | !currentPercent}>
                        {isLoading ? 'Загрузка...' : 'Удалить'}
                    </Button>
                </Space>
            </Form>
        </FormWrap>
    )

    useEffect(() => {
        form.setFieldsValue({
            personalPercent: currentPercent
        })
    }, [form, currentPercent])

    return (
        <Wrapper>
            <Popover
                content={content}
                title="Редактирование процента:"
                trigger="click"
                visible={isModalVisible}
                onVisibleChange={setIsModalVisible}
                overlayStyle={{ width: 400 }}
                forceRender
            >

                <IconWrap>
                    {currentPercent
                        ? <>
                            <Tag color="purple">{currentPercent}</Tag>
                            <EditOutlined />
                        </>

                        : <PlusCircleOutlined />
                    }
                </IconWrap>
            </Popover>
        </Wrapper>
    )
}

export default EditPersonalPercent
