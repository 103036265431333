import React from 'react'
import { Input, Space, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import PrintOrder from '../components/PrintOrder/PrintOrder'

export function getColumnSearchProps(
    dataIndex,
    searchInput,
    handleSearch,
    handleReset,
    searchedColumn,
    searchText,
    formula,
    purchase,
    selectedManager,
    isManager
) {
    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Поиск по номеру заказа`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Поиск
                    </Button>

                    <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                        Сброс
                    </Button>
                </Space>
            </div>
        ),

        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select())
            }
        },

        render: (text, record, index) => {
            return (
                <>
                    <span>{text}</span>
                    <PrintOrder order={record} formula={formula} purchase={purchase} isManager={isManager} />
                    {selectedManager?.id === 'all' && <div>{record?.author?.managerName}</div>}
                </>
            )
        }
    }
}
