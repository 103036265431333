import React from 'react'

function PaymentInfo({ typeDelivery, selectPurchase }) {
    const getFinalText = (type) => {
        switch (type) {
            case 'belpochta':
            case 'evropochta':
                return (
                    <div>
                        <p>Оплаты принимаем: {selectPurchase && selectPurchase.paymentDays}</p>
                        <b>Оплата на счёт ИП:</b>
                        <p>
                            В интернет-банкинге выбираете произвольный платеж, платеж по реквизитам или свободный платеж
                            (не заходя в ЕРИП).{' '}
                        </p>
                        <b>Далее вводите данные</b>

                        <p>Банк-получатель: ОАО «Паритетбанк» (БИК банка: POISBY2X)</p>
                        <p>УНП получателя: 192731913</p>
                        <p>Номер счета получателя в формате IBAN: BY78POIS30130125516501933001</p>
                        <p>Получатель платежа: ИП Шварлак Александр Анатольевич</p>
                        <p> Назначение платежа: Ваша Фамилия</p>

                        <i>
                            Если не получается, то можно посмотреть информацию в Гугле по запросу: "Платеж по реквизитам
                            + название Вашего банка"
                        </i>

                        <p style={{ color: 'orange' }}>
                            <b>После оплаты необходимо загруззить фото чека, нажав кнопку "Загрузить фото чека"</b>
                        </p>
                    </div>
                )

            case 'samovivoz':
                return (
                    <div>
                        <b>Раздача:</b>
                        <p>{selectPurchase && selectPurchase.distribution} с 17:00 до 21:00</p>
                        <p>Есенина 25/2, кв.61, 7 этаж</p>
                        <p>Предварительно (за час) предупредите о том, что будете ехать. Звоните или пишите в Viber:</p>
                        <p>{selectPurchase && selectPurchase.phone}</p>
                        <div>
                            <b>Раздача осуществляется только в указанные выше дни!</b>
                        </div>
                    </div>
                )

            case 'geoMinsk':
                return (
                    <div>
                        <p>Доставка осуществляется в {selectPurchase && selectPurchase.deliveryDays}</p>
                    </div>
                )

            default:
                return null
        }
    }

    return <div style={{ maxWidth: 600 }}>{getFinalText(typeDelivery)}</div>
}

export default PaymentInfo
