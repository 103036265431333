import { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useManagers } from './useManagers'
import axios from 'axios'

export const OrdersContext = createContext()

function OrdersProvider({ children }) {
    const token = useSelector((state) => state.token)
    const params = useParams()

    const { isLoadingManagers, managers, selectedManager, onChangeSelectedManager } = useManagers(token)

    const [isLoadingOrders, setIsLoadingOrders] = useState(false)
    const [orders, setOrders] = useState([])
    const [isModalProductsVisible, setIsModalProductsVisible] = useState(false)

    const getOrders = async () => {
        setIsLoadingOrders(true)

        try {
            const res = await axios.get(`/order/ref-purchase/${params.purchaseId}?manager=${selectedManager?.id}`, {
                headers: { Authorization: token }
            })

            setOrders(res.data)
            setIsLoadingOrders(false)
        } catch (err) {
            console.log('err', err)
            setIsLoadingOrders(false)
        }
    }

    const getAllOrdersByManager = async () => {
        setIsLoadingOrders(true)

        try {
            const res = await axios.get(`/order/from-manager/${params.purchaseId}`, {
                headers: { Authorization: token }
            })

            setOrders(res.data)
            setIsLoadingOrders(false)
        } catch (err) {
            console.log('err', err)
            setIsLoadingOrders(false)
        }
    }

    const onUpdateProduct = (items, orderId, productId, objValue) => {
        const newOrders = items.map((order) => {
            if (order._id === orderId) {
                return {
                    ...order,
                    products: order.products.map((product) => {
                        if (product._id === productId) {
                            return { ...product, ...objValue }
                        }
                        return product
                    })
                }
            }
            return order
        })

        setOrders(newOrders)
    }

    const onUpdateProductStatus = (items, orderId, productId, value) => {
        const newOrders = items.map((order) => {
            if (order._id === orderId) {
                return {
                    ...order,
                    products: order.products.map((product) => {
                        if (product._id === productId) {
                            return { ...product, status: value }
                        }
                        return product
                    })
                }
            }
            return order
        })

        setOrders(newOrders)
    }

    const onUpdatePreQuantity = (items, orderId, productId, value) => {
        const newOrders = items.map((order) => {
            if (order._id === orderId) {
                return {
                    ...order,
                    products: order.products.map((product) => {
                        if (product._id === productId) {
                            return { ...product, preQuantity: value }
                        }
                        return product
                    })
                }
            }
            return order
        })

        setOrders(newOrders)
    }

    const onUpdateStatusOrder = (items, orderId, value) => {
        const newArr = items.map((item) => {
            if (item._id === orderId) {
                const newOrder = { ...item, status: value }
                return newOrder
            }
            return item
        })

        setOrders(newArr)
    }

    const hadleShowModal = () => {
        setIsModalProductsVisible(true)
    }

    const handleCancel = () => {
        setIsModalProductsVisible(false)
    }

    const value = {
        isLoadingManagers,
        managers,
        selectedManager,
        onChangeSelectedManager,
        isLoadingOrders,
        orders,
        getOrders,
        onUpdateProduct,
        onUpdateProductStatus,
        hadleShowModal,
        handleCancel,
        isModalProductsVisible,
        onUpdatePreQuantity,
        onUpdateStatusOrder
    }

    useEffect(() => {
        if (params.purchaseId && token && selectedManager?.id) {
            if (selectedManager?.id === 'all') {
                return getAllOrdersByManager()
            }

            getOrders()
        }
    }, [params.purchaseId, token, selectedManager?.id])

    return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
}

export { OrdersProvider }
