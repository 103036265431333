import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Tag, Modal, Alert, Form, Input, InputNumber, message, Space } from 'antd'
import { useSelector } from 'react-redux'
import axios from 'axios'
import NoPhoto from '../../assets/photo.png'
import DeleteProductFromManager from '../DeleteProductFromManager/DeleteProductFromManager'
import { addHours, format, subHours } from 'date-fns'

const CardWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 99;
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
`

const CardInner = styled.div`
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    padding: 15px;
`

const ContentWrap = styled.div`
    display: flex;

    @media (max-width: 420px) {
        flex-direction: column;
    }
`

const TagCommentWrap = styled.div`
    margin-bottom: 12px;

    .ant-tag {
        width: 100%;
        font-size: 14px;
        padding: 8px 4px;
    }
`

const CardImgWrap = styled.div`
    position: relative;
    max-width: 180px;
    overflow: hidden;
    margin: 0 auto;
    margin-right: 15px;

    @media (max-width: 420px) {
        margin: 0 auto;
        margin-bottom: 20px;
    }

    img {
        object-fit: cover;
        border-radius: 6px;

        &.noPhoto {
            object-fit: contain;
        }
    }

    .ant-tag {
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        text-align: center;
        margin-right: 0;
        padding: 8px 4px;
    }
`

const CardInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    .ant-row {
        &.btnPreQuantity {
            margin-bottom: 20px;
        }
    }

    form {
        &#edit_preQuantity {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ant-form-item {
                width: 48%;
                margin-right: 0;
            }
        }

        .ant-btn {
            display: block;
            width: 100%;
            margin-top: 16px;
        }

        .ant-form-item {
            width: 160px;
        }

        .ant-input-number {
            width: 100%;
        }

        .ant-input-number-input {
            height: 24px;
        }
    }

    .ant-radio-group {
        margin-bottom: 16px;
    }

    .cardRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        margin: 0;

        &:not(:last-child) {
            border-bottom: 1px solid #eeeeee;
        }

        .ant-row {
            margin: 0;
        }

        .ant-form-item-control-input {
            min-height: auto;
        }

        input {
            width: 160px;
            padding: 0;
        }

        span {
            font-weight: 400;
            color: #1890ff;
        }

        &.comment {
            color: tomato;
            opacity: 0.9;
            padding-top: 10px;
            border-bottom: none;
        }

        &.defects {
            color: orange;

            b {
                font-weight: 400;
                color: #389e0d;
            }
        }
    }
`

function ProductCardManager({ item, paramId, orderId, getProductsByOrder, purchase }) {
    const token = useSelector((state) => state.token)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [isEditing, setIsEditing] = useState(false)
    const [isPhoto, setIsPhoto] = useState(false)

    const handleUpdateProduct = async (data) => {
        try {
            await axios.patch(
                `/product/update-product/${item._id}`,
                { ...data, paramId },
                {
                    headers: { Authorization: token }
                }
            )

            setIsEditing(false)
            message.success('Товар успешно изменён!')
            getProductsByOrder()

            // dispatch(updateProductAction(orderId, item._id, data))
            // dispatch(setProductsByOrder(orderId))
        } catch (err) {
            console.log(err)
        }
    }

    const showModal = () => {
        setIsModalVisible((prev) => !prev)
    }

    const onOpenIsEditing = () => {
        setIsEditing(true)
    }

    const currentDate = addHours(new Date().setHours(0, 0, 0, 0), 4)
    const createDate = addHours(new Date(item.createdAt).setHours(0, 0, 0, 0), 4)
    const isClosed = currentDate > createDate

    return (
        <>
            <CardWrapper>
                <CardInner>
                    <TagCommentWrap>
                        {item.commentAdmin && <Alert message={item.commentAdmin} type='warning' showIcon />}
                    </TagCommentWrap>

                    <ContentWrap>
                        <CardImgWrap>
                            <img
                                id='imageProduct'
                                className='noPhoto'
                                src={`/images/${item.photo}`}
                                alt='Фото товара'
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = `${NoPhoto}`
                                }}
                            />

                            {item.status === 'yes' && <Tag color='green'>Выкуплено</Tag>}
                            {item.status === 'no' && <Tag color='red'>Не выкуплено</Tag>}
                            {item.status === 'pending' && <Tag color='#f50'>Уточняется</Tag>}
                            {item.status === 'yesMoscow' && <Tag color='purple'>Выкуп в Москве</Tag>}
                            {!item.status && <Tag color='default'>В обработке</Tag>}
                            {item.status === 'return' && <Tag color='black'>Возврат</Tag>}
                        </CardImgWrap>

                        <CardInfo>
                            <Form
                                onFinish={handleUpdateProduct}
                                initialValues={{
                                    nameProduct: item.nameProduct && item.nameProduct,
                                    quantity: item.quantity && item.quantity,
                                    color: item.color && item.color,
                                    colorAdditional: item.colorAdditional && item.colorAdditional,
                                    size: item.size && item.size,
                                    pointOnMarket: item.pointOnMarket && item.pointOnMarket,
                                    price: item.price && item.price,
                                    managerPrice: item?.managerPrice && item.managerPrice,
                                    comment: item.comment && item.comment,
                                    checkDefects: item.checkDefects && item.checkDefects
                                }}
                            >
                                <div className='cardRow'>
                                    <span>Номер товара: </span> {item.productNumber}
                                </div>

                                <div className='cardRow'>
                                    <span>Товар: </span>

                                    {!isEditing ? (
                                        item.nameProduct
                                    ) : (
                                        <Form.Item
                                            name='nameProduct'
                                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                                        >
                                            <Input size='small' />
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='cardRow'>
                                    <span>Кол-во: </span>

                                    {!isEditing ? (
                                        !item.preQuantity ? (
                                            `${item.quantity} шт.`
                                        ) : (
                                            `${item.preQuantity} из ${item.quantity}`
                                        )
                                    ) : (
                                        <Form.Item
                                            name='quantity'
                                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                                        >
                                            <InputNumber type='number' />
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='cardRow'>
                                    <span>Цвет: </span>

                                    {!isEditing ? (
                                        item.color
                                    ) : (
                                        <Form.Item
                                            name='color'
                                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                                        >
                                            <Input size='small' />
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='cardRow'>
                                    <span>Доп. цвет: </span>

                                    {!isEditing ? (
                                        item.colorAdditional ? (
                                            item.colorAdditional
                                        ) : (
                                            '---'
                                        )
                                    ) : (
                                        <Form.Item name='colorAdditional'>
                                            <Input size='small' />
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='cardRow'>
                                    <span>Размер: </span>

                                    {!isEditing ? (
                                        item.size
                                    ) : (
                                        <Form.Item
                                            name='size'
                                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                                        >
                                            <Input size='small' />
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='cardRow'>
                                    <span>Точка на рынке: </span>

                                    {!isEditing ? (
                                        item.pointOnMarket
                                    ) : (
                                        <Form.Item
                                            name='pointOnMarket'
                                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                                        >
                                            <Input size='small' />
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='cardRow'>
                                    <span>Цена: </span>

                                    {!isEditing ? (
                                        `${item.price} рос. руб.`
                                    ) : (
                                        <Form.Item
                                            name='price'
                                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                                        >
                                            <InputNumber type='number' />
                                        </Form.Item>
                                    )}
                                </div>

                                <div className='cardRow'>
                                    <span>Цена продажи: </span>

                                    {!isEditing ? (
                                        `${item?.managerPrice || 0} бел. руб.`
                                    ) : (
                                        <Form.Item
                                            name='managerPrice'
                                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                                        >
                                            <InputNumber type='number' />
                                        </Form.Item>
                                    )}
                                </div>

                                {isEditing && (
                                    <div className='cardRow'>
                                        <span>Комментарий:</span>

                                        {!isEditing ? null : (
                                            <Form.Item
                                                name='comment'
                                                rules={[
                                                    {
                                                        validator(rule, value) {
                                                            if (value.length > 138) {
                                                                return Promise.reject('Текст слишком длиный!')
                                                            }
                                                            return Promise.resolve()
                                                        }
                                                    }
                                                ]}
                                            >
                                                <Input.TextArea placeholder='Комментарий к товару' />
                                            </Form.Item>
                                        )}
                                    </div>
                                )}

                                {isEditing && (
                                    <Button className='btnSave' htmlType='submit' type='primary'>
                                        Сохранить
                                    </Button>
                                )}
                            </Form>

                            {item.comment ? <p className='comment'>{item.comment}</p> : null}

                            <Space direction='vertical'>
                                {purchase?.status && !isClosed && !item.status && (
                                    <Button onClick={showModal} style={{ marginTop: 12 }} danger>
                                        Удалить
                                    </Button>
                                )}

                                {!isEditing && purchase?.status && !isClosed && !item.status && (
                                    <Button onClick={onOpenIsEditing}>Редактировать</Button>
                                )}
                            </Space>
                        </CardInfo>
                    </ContentWrap>
                </CardInner>
            </CardWrapper>

            <Modal title='Удаление товара' visible={isModalVisible} footer={null} closable={false}>
                <DeleteProductFromManager
                    productId={item._id}
                    showModal={showModal}
                    purchaseId={paramId}
                    orderId={orderId}
                    getProductsByOrder={getProductsByOrder}
                />
            </Modal>
        </>
    )
}

export default ProductCardManager
