import axios from 'axios'

import {
    SET_ORDERS_ADMIN,
    SET_LOADED,
    EDIT_INFO_ORDER_FROM_ADMIN,
    SET_PRODUCTS_BY_ORDER,
    UPDATE_STATUS_PRODUCT,
    DELETE_PRODUCT,
    ADD_COMMENT_IN_PRODUCT,
    ADD_ORDER_FROM_ADMIN,
    UPDATE_STATUS_PRE_QUANTITY,
    UPDATE_PRODUCT
} from '../types'

export const setLoaded = (payload) => ({
    type: SET_LOADED,
    payload
})

export const setOrdersAdmin = (data) => ({
    type: SET_ORDERS_ADMIN,
    payload: data
})

export const getOrdersAdmin = (orderId, token, page, size) => async (dispatch) => {
    dispatch({
        type: SET_LOADED,
        payload: true
    })

    try {
        const res = await axios.get(`/order/ref-purchase/${orderId}?page=${page}&size=${size}`, {
            headers: { Authorization: token }
        })
        dispatch(setOrdersAdmin(res.data))
    } catch (err) {
        console.log('err', err)
    }
}

export const searchOrdersByAdmin =
    ({ purchaseId, token, articleOrder }) =>
    async (dispatch) => {
        dispatch({
            type: SET_LOADED,
            payload: true
        })

        try {
            const res = await axios.get(
                `/order/search/${purchaseId}?articleOrder=${articleOrder}`,
                {
                    headers: { Authorization: token }
                }
            )
            dispatch(setOrdersAdmin(res.data))
        } catch (err) {
            console.log('err', err)
        }
    }

export const editOrderInfoFromAdminAction = (orderId, info, personalPercentAdmdin) => ({
    type: EDIT_INFO_ORDER_FROM_ADMIN,
    payload: { orderId, info, personalPercentAdmdin }
})

export const setProductsByOrder = (orderId) => ({
    type: SET_PRODUCTS_BY_ORDER,
    payload: orderId
})

export const updateStatusProductAction = (orderId, productId, status) => ({
    type: UPDATE_STATUS_PRODUCT,
    payload: { orderId, productId, status }
})

export const updateProductAction = (orderId, productId, objValue) => ({
    type: UPDATE_PRODUCT,
    payload: { orderId, productId, objValue }
})

export const updatePreQuantityProductAction = (orderId, productId, preQuantity) => ({
    type: UPDATE_STATUS_PRE_QUANTITY,
    payload: { orderId, productId, preQuantity }
})

export const deleteProductAction = (orderId, productId) => ({
    type: DELETE_PRODUCT,
    payload: { orderId, productId }
})

export const addCommentInProductAction = (orderId, productId, commentAdmin) => ({
    type: ADD_COMMENT_IN_PRODUCT,
    payload: { orderId, productId, commentAdmin }
})

export const addOrderFromAdmin = (order) => {
    return {
        type: ADD_ORDER_FROM_ADMIN,
        payload: order
    }
}
