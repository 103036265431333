import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { ArrowUpOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { PageInner } from '../../components/shared/PageInner'
import TitlePage from '../../components/shared/TitlePage'

import { Link } from "react-scroll"
import { animateScroll as scroll } from 'react-scroll'

const CardWrap = styled.div`
    max-width: 1000px;

    position: relative;
    background: #fff;
    transform-style: preserve-3d;
    border-radius: 15px;
    box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 40px;    

    &:after {
        position: absolute;
        width: 96%;
        height: 30px;
        background-color: #fff;
        left: 2%;
        bottom: -7px;        
        transform: translateZ(-1px);
        box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
        border-radius: 15px;
    }
`

const Card = styled.div`

    border: 1px solid #ededed;
    border-radius: 15px;
    height: 100%;
    padding: 40px;

    h3 {
        color: #1890ff;
        line-height: 22px;
        margin-bottom: 10px;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    b {
        font-weight: 600;
        color: #5a5a5a;
    }  

    ul {
        margin-bottom: 24px;        
    }

    @media (max-width: 770px) {
        padding: 12px; 
    }
`

const WrapBtn = styled.div`
    position: fixed;
    right: 24px;
    bottom: 30px;
`

const ContentWrap = styled.div`
    position: relative;
`

const ListLink = styled.ul`
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;

    li {
        &:not(:last-child) {
            margin-bottom: 12px;
            line-height: 20px;
        }        
    }

    @media (max-width: 420px) {
        text-align: left;  
    }
`

function FaqPage() {

    const [visible, setVisible] = useState(false)

    function scrollToTop() {
        scroll.scrollToTop();
    }

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop

        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    }

    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', toggleVisible)
    }

    useEffect(() => {
        return () => window.removeEventListener('scroll', toggleVisible)
    }, [])

    return (
        <div className='container'>
            <PageInner>
                <TitlePage>Популярные вопросы:</TitlePage>

                <ContentWrap>
                    <ListLink>
                        <li><Link to="section1" spy={true} smooth={true} duration={1000}>Почему так дешево?</Link></li>
                        <li><Link to="section2" spy={true} smooth={true} duration={1000}>Как заказать?</Link></li>
                        <li><Link to="section3" spy={true} smooth={true} duration={1000}>Какие способы доставки у Вас есть?</Link></li>
                        <li><Link to="section4" spy={true} smooth={true} duration={1000}>Как и когда оплачивать?</Link></li>
                        <li><Link to="section5" spy={true} smooth={true} duration={1000}>Можно ли заказать товар, которого нет на странице Instagram?</Link></li>
                        <li><Link to="section6" spy={true} smooth={true} duration={1000}>Как подобрать размер и есть ли у Вас размерная таблица?</Link></li>
                        <li><Link to="section7" spy={true} smooth={true} duration={1000}>Можно померять? Что делать, если не подойдет или брак?</Link></li>
                    </ListLink>

                    <CardWrap id="section1">
                        <Card>
                            <h3>Почему так дешево?</h3>

                            <p>
                                Мы не интернет-магазин, а организаторы совместных покупок.
                                Мы не продаем вещи (накручивая 2-3 цены от закупочных),
                                а оказываем услуги по выкупу, доставке товаров в Минск и отправке их в другие города Беларуси.
                                Берем за это свой процент.
                            </p>
                        </Card>
                    </CardWrap>

                    <CardWrap id="section2">
                        <Card>
                            <h3>Как заказать?</h3>

                            <p>
                                Для того, чтобы сделать заказ — необходимо перейти во вкладку <b>“Закупки”</b> .
                            Выбрать подходящую для Вас закупку по датам и нажать кнопку <b>“Участвовать”</b>.
                            </p>

                            <p>Далее необходимо заполнить форму с Вашими данными.</p>

                            <p>
                                Для того, чтобы добавить товар, который хотите приобрести — необходимо
                            нажать на кнопку <b>“Добавить товар”</b>.
                            </p>

                            <p>
                                Затем загрузить фото и заполнить необходимые данные.
                            </p>

                            <p>
                                <b>Цена указывается в Российских рублях</b> <br />
                                <i> (если непонятно где ее взять – напишите в директ sadovod_bel).</i>
                            </p>

                            <p>
                                <b>Номер точки</b> указывается в формате 02-12 <br />
                                <i>(если номер точки содержит однозначное число, то перед ним ставится ноль).</i> <br />
                            Например: 03-22. Номер точки можно найти на самой фотографии,
                            в описании товара, в шапке профиля или информационном посте продавца.
                            </p>

                            <p>
                                Обратите внимание, что <b>есть возможность заказать проверку на брак</b> поставив флажок
                            в соответствующее поле (без заказа проверки на брак претензии по браку и не
                            соответствию параметрам не принимаются).
                            </p>

                            <p>
                                На странице со всеми добавленными товарами можно посмотреть <b>информацию о заказе. </b>
                            Параметр “Выкуплено товаров на сумму” будет меняться в процессе выкупа в зависимости от
                            количества и стоимости выкупленных товаров.
                            </p>

                            <p>
                                Если Вы случайно добавили товар или передумали его заказывать, то <b>обязательно удалите</b> его т.к.
                            в противном случае он отправится на выкуп. Удалить товар можно до 21.00 того дня,
                            в который Вы добавили этот товар (например, если добавили 01.06 в 17.00,
                            то удалить можете до 21.00 01.06). После 21.00 заказ отправляется посреднику на выкуп и удалить нельзя.
                            </p>

                            <p>
                                Для того, <b>чтобы удалить</b>, надо нажать на фото товара и во всплывающем окне нажать кнопку “Удалить”:
                            </p>

                            <p>
                                Каждому товару присваивается статус “В обработке”.
                                После того как пройдет выкуп — статус товара изменится на “Выкуплено” или “Не выкуплено”.
                            </p>

                            <p>
                                Когда пройдет выкуп и все статусы поменяются — блок <b> “Детали оплаты”</b> дополнится пунктом
                            <b> “Итоговая сумма”</b> и <b> “Информация об оплате и доставке!”</b>. Это и будет окончательная сумма к оплате,
                            которая уже не поменяется. Кроме того, в блоке “Информация об оплате и доставке!”
                            будут данные для оплаты и информация о доставке или самовывозе.
                            </p>

                            <p>
                                <b>ПОЖАЛУЙСТА продумывайте свои заказы сразу</b> и не добавляйте товары в которых сомневаетесь.
                            Если хотите что-то заменить, удалить или исправить — напишите нам в Instagram sadovod_bel
                            </p>

                            <p>
                                С момента закрытия приема заявок в закупку и до привоза, как правило, проходит несколько дней.
                            На это время закупка перемещается из раздела “Открытые закупки” в раздел <b>“Прошедшие закупки”</b>,
                            подсвечивается красным цветом и перемещается в низ. Если Вы принимали участие в этой закупке,
                            то там по-прежнему <b>можно отслеживать статусы Ваших товаров и итоговые суммы.</b>
                            </p>

                            <p>
                                В блоке информации указана дата привоза в Минск. В этот день Вы можете зайти
                                в личный кабинет нашего сайта, там будет информация о выкупленных, не выкупленных товарах и сумме к оплате.
                            </p>
                        </Card>
                    </CardWrap>

                    <CardWrap id="section3">
                        <Card>
                            <h3>Какие способы доставки у Вас есть?</h3>

                            <p>
                                <b>Те, кто проживает в Минске</b> могут получить товар двумя способами:
                            </p>

                            <ul>
                                <li>
                                    <b>Самовывоз из Малиновки</b> (дни и время раздачи строго определены и в другое время
                                    раздача не осуществляется). Оплата в этом случае при самовывозе.
                                </li>

                                <li>
                                    <b>Доставка по Минску</b> +5 бел.руб. к стоимости (также осуществляется в определенные дни и время). Оплата курьеру.
                                </li>
                            </ul>

                            <p>
                                <b>Для иногородних</b> доступны следующие варианты доставки:
                            </p>

                            <ul>
                                <li>
                                    <b>Белпочта</b> – пересылка оплачивается по тарифам в момент получения на почте.
                                    Оплата товара и наших услуг производится после того как товар приедет к нам,
                                    перед отправкой (оплаты принимаются в день привоза и на следующий день.
                                    В случае, если Вы по какой-то причине не успеваете оплатить за это время – отправка посылки
                                    переносится на следующую отправку товара).
                            </li>

                                <li>
                                    <b>Европочта</b> — пересылка добавляется к стоимость и составляет 2.99 бел.руб до 3кг.
                                    Оплата товара и наших услуг производится после того как товар приедет к нам, перед отправкой
                                    (оплаты принимаются в день привоза и на следующий день. В случае, если Вы по какой-то причине
                                    не успеваете оплатить за это время – отправка посылки переносится на следующую отправку товара).
                                </li>
                            </ul>

                            <h4>НАЛОЖЕННЫМ НЕ ОТПРАВЛЯЕМ!</h4>
                        </Card>
                    </CardWrap>

                    <CardWrap id="section4">
                        <Card>
                            <h3>Как и когда оплачивать?</h3>

                            <p>
                                Если у Вас <b>самовывоз или доставка курьером</b>, то оплата (наличными) при самовывозе или доставке соответственно.
                            </p>

                            <p>
                                Если <b>Белпочта или Европочта</b>, то оплата товара и наших услуг производится после
                            того как товар приедет к нам, перед отправкой. Оплаты принимаются в день привоза
                            и на следующий день. В случае, если Вы по какой-то причине не успеваете оплатить
                            за это время – отправка посылки переносится на следующую отправку товара.
                            </p>

                            <p>
                                <b>Пересылка Белпочтой</b> не включается в стоимость и оплачивается отдельно при получении.
                            </p>

                            <p>
                                <b>Пересылка Европочтой</b> добавляется к стоимости и оплачивается сразу.
                            </p>
                        </Card>
                    </CardWrap>

                    <CardWrap id="section5">
                        <Card>
                            <h3>Можно ли заказать товар, которого нет на странице Instagram?</h3>

                            <p>
                                Да. Можно заказывать любые товары, которые продаются на рынке “Садовод”
                                от одной единицы за исключением животных, хрупких и габаритных товаров.
                            </p>

                            <p>
                                Список продавцов можно посмотреть:
                                <NavLink to='/client-catalog' exact> у нас в каталоге </NavLink>
                                или
                                <a href="https://vk.com/sadovod_postavshiki" target="_blank" rel='noopener noreferrer'> здесь</a>
                            </p>

                            <p>
                                Если Вы хотите самостоятельно найти товар, то можно воспользоваться приложением
                            <b> “Садовод агрегатор”</b> в Play market или App Store или ввести в Google поиск соответствующий запрос.
                            Например: “Женская одежда Садовод вк”. Запрос может содержать любую группу товаров.
                            </p>

                            <p>
                                Если выбираете товар самостоятельно, то старайтесь искать товар с реальными фото от
                                продавца либо в комментариях.
                            </p>

                            <b>В реальности товар может отличаться от фото. За это ответственность мы не несем.</b>
                        </Card>
                    </CardWrap>

                    <CardWrap id="section6">
                        <Card>
                            <h3>Как подобрать размер и есть ли у Вас размерная таблица?</h3>

                            <p>
                                В подборе размера мы, к сожалению, не поможем, потому что вся информация, которая есть у нас,
                                указана в описании товара. На рынке бывают как большемеры, так и маломеры,
                                поэтому посоветовать или подобрать размер мы не можем. Онлайн-покупки такого рода всегда
                                риск и если есть сомнения, то лучше не заказывать.
                            </p>

                            <p>
                                В большинстве случаев размерной таблицы нет, т.к. продавец ее не предоставляет.
                            </p>
                        </Card>
                    </CardWrap>

                    <CardWrap id="section7">
                        <Card>
                            <h3>Можно померять? Что делать, если не подойдет или брак?</h3>

                            <p>
                                Т.к. каждый товар везется под конкретного заказчика — <b>примерок и возвратов нет!</b> <br />
                            Вы заказываете, а мы выкупаем и привозим.
                            </p>

                            <p>
                                Если Вы сомневаетесь в размерах или просто не уверены нужен ли Вам товар,
                            то лучше не рисковать и не заказывать. <b>Пожалуйста, уважайте свое и наше время. </b>
                            Имейте в виду, что вещи могут маломерить или большемерить.
                            </p>

                            <p>
                                Также есть возможность заказать проверку на брак поставив флажок в соответствующее
                                поле при добавлении товара (без заказа проверки на брак претензии по браку не принимаются).
                                Если же Вы заказывали проверку, но мы не заметили какого-либо дефекта, то мы берем на себя
                                обязательства по обмену некачественного товара на товар надлежащего качества того же размера и цвета.
                                Возврат денежных средств делается лишь в случае если у поставщика нет нужного размера и цвета.
                            </p>

                            <p>
                                Иногда на фото и в описании есть ремень, но по факту товар приходит без него.
                                Если не оформлена проверка на брак — претензии тоже не принимаются.
                            </p>
                        </Card>
                    </CardWrap>
                </ContentWrap>

                <WrapBtn>
                    <Button
                        onClick={scrollToTop}
                        style={{ display: visible ? 'inline' : 'none' }}
                        type='primary'
                    >
                        <ArrowUpOutlined />
                    </Button>
                </WrapBtn>
            </PageInner>
        </div>
    )
}

export default FaqPage
