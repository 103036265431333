import React, { useState, useEffect } from 'react'
import axios from 'axios'

export const useManagers = (token) => {
    const [isLoadingManagers, setIsLoadingManagers] = useState(false)
    const [managers, setManagers] = useState([])
    const [selectedManager, setSelectedManager] = useState(null)

    const onChangeSelectedManager = (value, obj) => {
        setSelectedManager({
            id: obj.key,
            managerName: obj.value,
            item: obj.item
        })
    }

    const getManagers = async () => {
        setIsLoadingManagers(true)

        try {
            const res = await axios.get(`/user/all-manager/`, {
                headers: { Authorization: token }
            })

            setManagers(res.data)

            if (res?.data?.length) {
                const manager = {
                    id: res.data[0]._id,
                    managerName: res.data[0].managerName
                }

                setSelectedManager(manager)
            }

            setIsLoadingManagers(false)
        } catch (err) {
            console.log('err', err)
            setIsLoadingManagers(false)
        }
    }

    useEffect(() => {
        if (token) {
            getManagers()
        }
    }, [token])

    return { isLoadingManagers, managers, selectedManager, onChangeSelectedManager }
}
