import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Button, InputNumber, Space, message } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux';
import { PageInner } from '../../components/shared/PageInner'


const ContentWrapper = styled.div`
    width: 100%;
`

const PageTitle = styled.h2`
    text-align: center;
`

const FormulaWrap = styled.div`
    width: 100%;   
    margin-bottom: 50px;
   
   form {
        width: 100%;
        max-width: 750px;
        margin: 0 auto;
      

        .ant-input-number {
            width: 100%;
        }

        .ant-space{
            width: 100%;
        }
   }
`

const ContentTitle = styled.h4`
    text-align: center;
    margin-bottom: 16px;
`

function AdminSettingsPage() {
    const token = useSelector(state => state.token)

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const auth = useSelector(state => state.auth)

    const [form] = Form.useForm();

    const [formula, setFormula] = useState()

    const onFinish = async (values) => {

        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/settings/update`, { ...values }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)

            setFormula(data.result)
            message.success(data.status)

        } catch (err) {
            setError(err.response?.data?.msg)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (formula) {
            form.setFieldsValue({
                course: formula.course,
                percent: formula.percent,
                checkDefectsPrice: formula.checkDefectsPrice,
                geoMinskPrice: formula.geoMinskPrice,
                evropochtaPrice: formula.evropochtaPrice,
            })
        }
    }, [formula, form])

    useEffect(() => {
        const getFormula = async () => {
            try {
                const { data } = await axios.get(`/settings/formula`, {
                    headers: { Authorization: token }
                })

                setFormula(data)
                setIsLoading(false)

            } catch (err) {
                setError(err.response.data.msg)
                setIsLoading(false)
            }
        }
        getFormula()
    }, [token])

    if (!auth.isAdmin) {
        return <h2>У вас нет доступа к этой странице!</h2>
    }

    if (error) {
        return <div className='container'>{error}</div>
    }


    return (
        <section>
            <div className='container'>
                <PageInner>
                    <PageTitle>Настройки</PageTitle>

                    <ContentWrapper>

                        <FormulaWrap>
                            <ContentTitle>Формула для итоговой суммы товара:</ContentTitle>

                            <Form
                                form={form}
                                name="adminSettings"
                                onFinish={onFinish}
                                initialValues={{}}
                            >

                                <Space direction='vertical'>
                                    <Form.Item
                                        label='Курс'
                                        name="course"
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Курс*' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Процент'
                                        name="percent"
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Процент*' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Проверка на брак - рос. руб'
                                        name="checkDefectsPrice"
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Проверка на брак*' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Доставка по Минску - бел. руб'
                                        name="geoMinskPrice"
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Доставка по Минску*' />
                                    </Form.Item>

                                    <Form.Item
                                        label='Европочта - бел. руб'
                                        name="evropochtaPrice"
                                        rules={[{ required: true, message: 'Обязательное поле' }]}
                                    >
                                        <InputNumber placeholder='Европочта*' />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" disabled={isLoading}>
                                            Сохранить
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </FormulaWrap>

                    </ContentWrapper>
                </PageInner>
            </div>
        </section>
    )
}

export default AdminSettingsPage
