import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Space, Modal, Form, Input, InputNumber, Select, message } from 'antd'
import AddOrderInfo from '../../components/AddOrderInfo/AddOrderInfo'
import AddOrderInfoFromAdmin from '../../components/AddOrderInfoFromAdmin/AddOrderInfoFromAdmin'

const { Option } = Select

const Wrap = styled.div`
    .ant-select {
        width: 100%;
        margin-bottom: 24px;
    }
`

function BookingInStock({ purchases, productInStock, handleCancel, updateListProducts, getProducts, closesPurchases }) {
    const options = [...purchases, closesPurchases[0]].map((item) => {
        return { label: item.title, value: item._id }
    })

    const [selectedPurchaseID, setSelectedPurchaseID] = useState(null)

    const handleChange = (value) => {
        setSelectedPurchaseID(value)
    }

    return (
        <Wrap>
            <Select options={options} onChange={handleChange} placeholder='Выберите закупку' size='large'></Select>

            {selectedPurchaseID && (
                <AddOrderInfoFromAdmin
                    paramId={selectedPurchaseID}
                    productInStock={productInStock}
                    handleCancel={handleCancel}
                    updateListProducts={updateListProducts}
                    getProducts={getProducts}
                />
            )}
        </Wrap>
    )
}

export default BookingInStock
