import { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

export const useAllProductsInStock = (filterType) => {
    const token = useSelector((state) => state.token)

    const [isLoadingProducts, setIsLoadingProducts] = useState(false)
    const [products, setProducts] = useState([])
    const [productsFiltered, setProductsFiltered] = useState(products)
    const [errorProducts, setErrorProducts] = useState(null)

    const getProducts = async () => {
        try {
            setIsLoadingProducts(true)

            const { data } = await axios.get(`/product-in-stock/all`, {
                headers: { Authorization: token }
            })

            setProducts(data)
            onFilteredProducts(data)
            setIsLoadingProducts(false)
        } catch (err) {
            setErrorProducts(err)
            setIsLoadingProducts(false)
        }
    }

    const onFilteredProducts = (items) => {
        if (filterType === 'all') {
            setProductsFiltered(items)
            return
        }

        if (filterType === 'default') {
            const arr = items.filter((item) => !item.status)
            setProductsFiltered(arr)
            return
        }

        const arr = items.filter((item) => item.status === filterType)
        setProductsFiltered(arr)
    }

    useEffect(() => {
        setIsLoadingProducts(true)
        void getProducts()
    }, [token])

    useEffect(() => {
        onFilteredProducts(products)
    }, [products.length, setProducts, filterType])

    return {
        isLoadingProducts,
        products,
        setProducts,
        errorProducts,
        getProducts,
        productsFiltered,
        setProductsFiltered
    }
}
