import React, { useState, memo, useMemo } from 'react'
import styled from 'styled-components'
import { Modal, Tag } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ProductCardUser from '../ProductCardUser/ProductCardUser'
import { useSelector } from 'react-redux'
import NoPhoto from '../../assets/photo.png'

const CardWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 99;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    border: 1px solid #fff;
`

const CardInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    transition: box-shadow 0.2s linear, border 0.2s linear;
    border: 1px solid #ffffff;
    padding: 15px;

    &:before {
        content: 'Товар из наличия';
        position: absolute;
        z-index: 20;
        left: 15px;
        top: -10px;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;
        padding: 2px 4px;
        border-radius: 2px;
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
        color: #389e0d;
    }

    .ant-tag {
        margin-bottom: 15px;
        text-align: center;
        margin-right: 0;
    }

    .anticon {
        position: absolute;
        right: 20px;
        bottom: 50%;
        transform: translateY(-50%);

        svg {
            width: 20px;
            height: 20px;
        }
    }

    @media (max-width: 380px) {
        padding: 8px;
    }
`

const CardImgWrap = styled.div`
    position: relative;
    width: 100%;
    max-width: 114px;
    overflow: hidden;
    margin: 0 auto;
    margin-right: 15px;

    img {
        width: 114px;
        height: 100px;
        border-radius: 6px;
        object-fit: cover;
        object-position: top;

        &.noPhoto {
            object-fit: contain;
        }
    }

    .ant-tag {
        position: absolute;
        top: 25px;
        left: 0;
        right: 0;
        text-align: center;
        margin-right: 0;
        padding: 6px 4px;
    }
`

const CardInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 0;

        .ant-tag {
            padding: 0px 8px;
            margin: 0;
        }

        b {
            line-height: 16px;
            margin-right: 10px;
        }

        &.comment {
            color: tomato;
            opacity: 0.9;
            padding-top: 10px;
        }

        &.preQuantity {
            color: tomato;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        color: #1890ff;
    }
`

function ProductCardInStockMini({ item }) {
    return (
        <>
            <CardWrapper>
                <CardInner>
                    <CardImgWrap>
                        <img
                            src={`${item.photo[0]}`}
                            alt='Фото товара'
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = `${NoPhoto}`
                            }}
                        />
                    </CardImgWrap>

                    <CardInfo>
                        <h4>{item.nameProduct}</h4>

                        <p>
                            <b>{item.price}</b> бел. руб
                        </p>
                    </CardInfo>
                </CardInner>
            </CardWrapper>
        </>
    )
}

export default memo(ProductCardInStockMini)
