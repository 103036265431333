
import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Space, message } from 'antd'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'


const FormWrap = styled.div`
    width: 100%;    
    margin: 30px 0;
   
   form {
       width: 100%;

        .ant-input-number {
            width: 100%;
        }
   }
`

function EditCategory({ item, updateCategory }) {
    const token = useSelector(state => state.token)

    const [isLoading, setIsLoading] = useState(false)

    const [form] = Form.useForm();

    const onFinish = async ({ name }) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/category/update/${item._id}`, { name }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            updateCategory(item._id, data.result)
            message.success(`${data.status}`)

        } catch (err) {
            console.log('err-AddCategory', err);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            name: item.name,
        })
    }, [
        form,
        item.name,
    ])

    return (
        <FormWrap>

            <Form
                form={form}
                name="editCategory"
                onFinish={onFinish}
                initialValues={{}}
            >

                <Space direction='horizontal'>
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Обязательное поле' }]}
                    >
                        <Input placeholder='Название категории' />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={isLoading}>
                            Изменить категорию
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </FormWrap>
    )
}

export default EditCategory
