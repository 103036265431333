import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavLink, useParams, useHistory, Link } from 'react-router-dom'
import { Button, Space, message, Popover, Popconfirm, Empty, Modal, Form, Input, InputNumber, Select } from 'antd'
import { useSelector } from 'react-redux'
import { PageInner } from '../../components/shared/PageInner'
import axios from 'axios'
import { useAllProductsInStock } from './useAllProductsInStock'
import Resizer from 'react-image-file-resizer'
import SimpleReactLightbox from 'simple-react-lightbox'
import CardProductInStock from '../../components/CardProductInStock/CardProductInStock'
import FormAddProductInStock from './FormAddProductInStock'
import { useGetOpenPurchase } from '../../hooks/useGetOpenPurchase'
import BookingInStock from './BookingInStock'

const { Option } = Select

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            600,
            600,
            'JPEG',
            90,
            0,
            (uri) => {
                resolve(uri)
            },
            'base64',
            400,
            400
        )
    })

const PageTitle = styled.h2`
    text-align: center;
`

const Loading = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const BtnRow = styled.div`
    display: flex;
    justify-content: center;

    margin-top: 30px;
    margin-bottom: 30px;
`

const RowInputs = styled.div``

const CardsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    @media (max-width: 360px) {
        margin-left: 0;
        margin-right: 0;
    }
`

const ListEmpty = styled.div`
    text-align: center;
`

const Filter = styled.div`
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    margin-bottom: 100px;

    .ant-select {
        width: 100%;
    }
`

function AdminInStock() {
    const history = useHistory()

    const token = useSelector((state) => state.token)
    const auth = useSelector((state) => state.auth)

    const search = window.location.search
    const paramsAll = new URLSearchParams(search)
    const productID = paramsAll.get('productID')
    const filter = paramsAll.get('filter')

    const [form] = Form.useForm()

    const {
        isLoadingProducts,
        products,
        setProducts,
        errorProducts,
        getProducts,
        productsFiltered,
        setProductsFiltered
    } = useAllProductsInStock(filter || 'all')

    const {
        isLoadingOpenPurchase,
        openPurchases,
        errorOpenPurchases,
        isLoadingClosesPurchase,
        closesPurchases,
        errorClosesPurchases
    } = useGetOpenPurchase()

    const [isLoading, setIsLoading] = useState(false)

    const [imagesBase64, setImagesBase64] = useState(null)
    const [selectedEditProduct, setSelectedEditProduct] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const [showModal, setShowModal] = useState(null) // 'add' | 'edit' | 'booking'

    const onChangeFilter = (value) => {
        history.push({
            pathname: '/admin/in-stock',
            search: '?' + new URLSearchParams({ filter: value }).toString()
        })
    }

    const onOpenModal = (name, obj) => {
        setShowModal(name)

        if (name === 'edit' && obj) {
            setSelectedEditProduct(obj)
            setImagesBase64(obj.photo)
            return
        }

        if (name === 'booking' && obj) {
            setSelectedEditProduct(obj)
            return
        }
    }

    const handleCancel = () => {
        setShowModal(null)
        setSelectedEditProduct(null)
    }

    const uploadImage = async (e) => {
        let images = []

        const arr = Array.from(e.target.files)

        for (const file of arr) {
            const string = await resizeFile(file)
            images.push(string)
        }

        setImagesBase64(images)
    }

    const onFinishFailed = () => {
        message.error('Заполните все обязательные поля!')
    }

    const updateListProducts = (data) => {
        void getProducts()
    }

    const onFinish = async (values) => {
        setIsLoading(true)

        const { nameProduct, comment, price, size } = values

        const formData = {
            photo: JSON.stringify(imagesBase64),
            nameProduct: nameProduct,
            comment: comment,
            price: price,
            size: size
        }

        try {
            const { data } = await axios.post(
                `/product-in-stock/create/`,
                { ...formData },
                {
                    headers: {
                        Authorization: token
                    }
                }
            )

            setIsLoading(false)
            message.success('Товар успешно добавлен!')
            setImagesBase64(null)
            form.resetFields()
            handleCancel()
            setProducts((prev) => [data.newProduct, ...prev])
        } catch (err) {
            console.log('onAddProductInStock', err)
            setIsLoading(false)
        }
    }

    const onEditProduct = async (values) => {
        setIsLoading(true)

        const { nameProduct, comment, price, size } = values

        const formData = {
            photo: JSON.stringify(imagesBase64),
            nameProduct: nameProduct,
            comment: comment,
            price: price,
            size: size
        }

        try {
            const { data } = await axios.patch(
                `/product-in-stock/update/${selectedEditProduct._id}`,
                { ...formData },
                {
                    headers: {
                        Authorization: token
                    }
                }
            )

            setIsLoading(false)
            message.success('Товар успешно обновлен!')
            setImagesBase64(null)
            setSelectedEditProduct(null)

            if (selectedProduct) {
                setSelectedProduct(data)
            }

            updateListProducts(data)
            handleCancel()
        } catch (err) {
            console.log('onEditProductInStock', err)
            setIsLoading(false)
        }
    }

    const onUpdateStatus = async (id, status) => {
        setIsLoading(true)

        const formData = {
            status: status
        }

        try {
            const { data } = await axios.patch(
                `/product-in-stock/update/status/${id}`,
                { ...formData },
                {
                    headers: {
                        Authorization: token
                    }
                }
            )

            setIsLoading(false)
            message.success('Товар успешно отмечен!')

            if (selectedProduct) {
                setSelectedProduct(data)
            }

            updateListProducts(data)
        } catch (err) {
            console.log('onEditProductInStock', err)
            setIsLoading(false)
        }
    }

    const onDeleteProduct = async (id) => {
        setIsLoading(true)

        try {
            await axios.delete(
                `/product-in-stock/delete/${id}`,

                {
                    headers: {
                        Authorization: token
                    }
                }
            )

            setIsLoading(false)
            message.success('Товар успешно удалён!')
            setProducts((prev) => prev.filter((item) => item._id !== id))
        } catch (err) {
            console.log('onDeleteProductInStock', err)
            setIsLoading(false)
        }
    }

    const onCancelBooking = async (id) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/product-in-stock/cancel-booking/${id}`, null, {
                headers: {
                    Authorization: token
                }
            })

            setIsLoading(false)
            message.success('Бронь успешно удалена!')

            if (selectedProduct) {
                setSelectedProduct(data)
            }

            updateListProducts(data)
        } catch (err) {
            console.log('onCancelBooking', err)
            setIsLoading(false)
        }
    }

    console.log('imagesBase64', imagesBase64)

    useEffect(() => {
        if (productID) {
            if (products.length) {
                const item = products.find((item) => item._id === productID)
                setSelectedProduct(item)
            }
        }
    }, [productID, products.length])

    if (!auth.isAdmin) {
        return <h2>У вас нет доступа к этой странице!</h2>
    }

    if (errorProducts || errorOpenPurchases || errorClosesPurchases) {
        return <h2>Что-то пошло не так, попробуйте перезагрузить страницу!</h2>
    }

    if (isLoading || isLoadingProducts || isLoadingOpenPurchase || isLoadingClosesPurchase) {
        return <Loading>Loading...</Loading>
    }

    return (
        <>
            <div className='container'>
                <PageInner>
                    <PageTitle>Пристрой:</PageTitle>

                    <BtnRow>
                        <Space>
                            {!selectedProduct && (
                                <Button type='primary' onClick={() => onOpenModal('add')}>
                                    Добавить товар
                                </Button>
                            )}

                            {selectedProduct && (
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        setSelectedProduct(null)

                                        history.push({
                                            pathname: `/admin/in-stock/`,
                                            search: ''
                                        })
                                    }}
                                >
                                    Сбросить
                                </Button>
                            )}
                        </Space>
                    </BtnRow>

                    {!selectedProduct && products.length > 0 && (
                        <Filter>
                            <Select
                                placeholder='Выберите тип'
                                onChange={onChangeFilter}
                                defaultValue={filter || 'all'}
                                size='large'
                            >
                                <Option value='all'>Все</Option>
                                <Option value='yes'>Проданные</Option>
                                <Option value='booking'>Забронированные</Option>
                                <Option value='default'>Без статуса</Option>
                            </Select>
                        </Filter>
                    )}

                    <SimpleReactLightbox>
                        {productsFiltered.length > 0 ? (
                            <CardsList>
                                {selectedProduct ? (
                                    <CardProductInStock
                                        item={selectedProduct}
                                        onOpenModal={onOpenModal}
                                        isAdmin={auth && auth?.isAdmin}
                                        onDelete={onDeleteProduct}
                                        onCancelBooking={onCancelBooking}
                                        onUpdateStatus={onUpdateStatus}
                                    />
                                ) : (
                                    productsFiltered.map((item, index) => (
                                        <CardProductInStock
                                            item={item}
                                            key={index}
                                            onOpenModal={onOpenModal}
                                            isAdmin={auth && auth?.isAdmin}
                                            onDelete={onDeleteProduct}
                                            onCancelBooking={onCancelBooking}
                                            onUpdateStatus={onUpdateStatus}
                                        />
                                    ))
                                )}
                            </CardsList>
                        ) : (
                            <ListEmpty>Список пуст</ListEmpty>
                        )}
                    </SimpleReactLightbox>
                </PageInner>
            </div>

            <Modal
                title='Новый товар для пристроя'
                visible={showModal === 'add'}
                width={500}
                footer={false}
                onCancel={handleCancel}
            >
                <FormAddProductInStock
                    form={form}
                    name={'addProductAdminInStock'}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    previewImages={imagesBase64}
                    uploadImage={uploadImage}
                    isLoading={isLoading}
                    textBtn='Добавить товар'
                    setImagesBase64={setImagesBase64}
                />
            </Modal>

            <Modal
                title='Редактирование товара для пристроя'
                visible={showModal === 'edit'}
                width={500}
                footer={false}
                onCancel={handleCancel}
            >
                <FormAddProductInStock
                    form={form}
                    name={'editProductAdminInStock'}
                    onFinish={onEditProduct}
                    onFinishFailed={onFinishFailed}
                    previewImages={imagesBase64}
                    uploadImage={uploadImage}
                    isLoading={isLoading}
                    selectedEditProduct={selectedEditProduct}
                    initialValues={{
                        comment: selectedEditProduct && selectedEditProduct.comment,
                        nameProduct: selectedEditProduct && selectedEditProduct.nameProduct,
                        price: selectedEditProduct && selectedEditProduct.price,
                        size: selectedEditProduct && selectedEditProduct.size,
                        photo: imagesBase64 && imagesBase64
                    }}
                    textBtn='Редактировать товар'
                    setImagesBase64={setImagesBase64}
                />
            </Modal>

            <Modal
                title='Бронирование товара'
                visible={showModal === 'booking'}
                width={500}
                footer={false}
                onCancel={handleCancel}
            >
                <BookingInStock
                    purchases={openPurchases}
                    closesPurchases={closesPurchases}
                    productInStock={selectedEditProduct}
                    handleCancel={handleCancel}
                    updateListProducts={updateListProducts}
                    getProducts={getProducts}
                />
            </Modal>
        </>
    )
}

export default AdminInStock
