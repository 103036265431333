import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { NavLink, useHistory } from 'react-router-dom'
import { Card, Tag, Space, Alert, Button } from 'antd'
import styled from 'styled-components'
import { PageInner } from '../../components/shared/PageInner'
import Loader from '../../components/shared/Loader'
import { dowloadPurchaseImages } from '../../utils/dowloadPurchaseImages'

const StyledPageInner = styled(PageInner)`
    width: 100%;
`

const Title = styled.h2`
    margin-bottom: 30px;
`

const CardList = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;

    .ant-card-head-title {
        margin-right: 20px;
    }

    .card {
        width: 100%;
        height: 100%;
    }

    .card__done {
        border: 1px solid tomato;
        border-radius: 6px;
    }

    .test {
        background: #ffc0b5;
    }

    .cardLink {
        display: block;
        margin-top: 15px;
    }

    .ant-tag {
        &.done {
            margin-bottom: 12px;
            font-size: 16px;
            padding: 8px;
        }
    }
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(33.333333% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;

    .fullWidth {
        width: 100%;
    }

    @media (max-width: 420px) {
        max-width: 100%;
    }
`

const DateWrap = styled.div`
    font-size: 16px;
    margin-bottom: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 420px) {
            flex-direction: column;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
`

const BtnsWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    button {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .linkStop {
        color: tomato;
    }

    .ant-popover-inner {
        max-width: 205px;
    }
`

function ManagerClosedPurchase() {
    const history = useHistory()

    const token = useSelector((state) => state.token)

    const [isLoading, setIsLoading] = useState(false)

    const [purchases, setPurchases] = useState([])

    useEffect(() => {
        const getPurchases = async () => {
            setIsLoading(true)
            try {
                const res = await axios.get('/purchase/closed', {
                    headers: { Authorization: token }
                })
                setPurchases(res.data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }
        getPurchases()
    }, [token])

    if (isLoading) {
        return <Loader />
    }

    return (
        <PageInner>
            <div className='container'>
                <StyledPageInner>
                    <Title>Открытые закупки:</Title>
                    {purchases.length !== 0 ? (
                        <CardList>
                            {purchases.map((item) => (
                                <CardWrap isTest={item.isTest} key={item._id}>
                                    <Card
                                        title={item.title}
                                        extra={
                                            <NavLink to={`/manager/table-orders/${item._id}?title=${item.title}`}>
                                                Перейти к таблице
                                            </NavLink>
                                        }
                                        className={`card ${item.isTest && 'test'}`}
                                    >
                                        <DateWrap>
                                            <p>
                                                {' '}
                                                <span>Начало сбора -</span>{' '}
                                                {format(new Date(item.dateStart), 'dd.MM.yyyy')}
                                            </p>

                                            <p>
                                                {' '}
                                                <span>Окончание сбора - </span>{' '}
                                                {format(new Date(item.dateEnd), 'dd.MM.yyyy')}
                                            </p>

                                            <p>
                                                {' '}
                                                <span>Доставка по Минску -</span> {item.deliveryDays}
                                            </p>

                                            <p>
                                                {' '}
                                                <span>Отправка почты -</span> {item.mailDays}
                                            </p>
                                        </DateWrap>

                                        {item.warningMessage && (
                                            <Alert message={item.warningMessage} type='warning' showIcon />
                                        )}
                                    </Card>
                                </CardWrap>
                            ))}
                        </CardList>
                    ) : (
                        <p>В настоящее время открытых закупок нет.</p>
                    )}
                </StyledPageInner>
            </div>
        </PageInner>
    )
}

export default ManagerClosedPurchase
