import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Popover, Button, Input, Form, message, Space } from 'antd'
import axios from 'axios'
import { CommentOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { addCommentInProductAction, setProductsByOrder } from '../../redux/actions/adminActions'

const { TextArea } = Input


const Wrapper = styled.div`
    margin: 16px 0;
`

const FormWrap = styled.div`
    width: 100%;
    max-width: 400px;   
`


function AddCommentInProduct({ productId, comment, orderId }) {

    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const token = useSelector(state => state.token)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async ({ commentAdmin }) => {
        setIsLoading(true)

        try {
            const { data } = await axios.patch(`/product/add-comment/${productId}`, { commentAdmin }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            message.success(data.msg)
            setIsModalVisible(false)


            dispatch(addCommentInProductAction(orderId, productId, commentAdmin))
            dispatch(setProductsByOrder(orderId))

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }

    const handleDeleteComment = async () => {
        setIsLoading(true)

        try {
            await axios.patch(`/product/add-comment/${productId}`, { commentAdmin: null }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            setIsModalVisible(false)
            message.success('Удаление успешно!')

            dispatch(addCommentInProductAction(orderId, productId, null))
            dispatch(setProductsByOrder(orderId))

        } catch (err) {
            console.log(err);
            setIsLoading(false)
        }
    }

    const content = (
        <FormWrap>
            <Form
                form={form}
                name={`addCommentInProduct_${productId}`}
                onFinish={onFinish}
                size='large'
            >

                <Form.Item
                    name="commentAdmin"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <TextArea rows={4} placeholder='Текст комментария' />
                </Form.Item>

                <Space>
                    <Button type="primary" htmlType="submit" size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>

                    <Button type="primary" onClick={handleDeleteComment} size='large' disabled={isLoading || !comment}>
                        {isLoading ? 'Загрузка...' : 'Удалить'}
                    </Button>
                </Space>
            </Form>
        </FormWrap>
    )

    useEffect(() => {
        form.setFieldsValue({
            commentAdmin: comment
        })
    }, [form, comment])

    return (
        <Wrapper>
            <Popover
                content={content}
                title="Коментарий к товару:"
                trigger="click"
                visible={isModalVisible}
                onVisibleChange={setIsModalVisible}
                overlayStyle={{ width: 400 }}
                forceRender
            >

                <Button type='primary' disabled={isLoading}>
                    <CommentOutlined /> Коммент
                </Button>
            </Popover>
        </Wrapper>
    )
}

export default AddCommentInProduct
