export const getTotalSumProductsByManager = (isManager, order, formula) => {
    if (!isManager) {
        return
    }

    const total = order?.products?.reduce(function (sum, { managerPrice, quantity, status, preQuantity }) {
        if (status !== 'yes') {
            return sum
        }

        if (!preQuantity) {
            return sum + managerPrice * quantity
        }

        return sum + managerPrice * preQuantity
    }, 0)

    if (order?.info?.delivery === 'evropochta') {
        return total + formula?.evropochtaPrice
    }

    return total
}
