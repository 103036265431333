import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
    border-top: 4px solid rgb(221, 255, 255);
`

const FooterInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 16px 0;
`

function Footer() {
    return (
        <Wrapper>
            <div className='container'>
                <FooterInner>
                    <p>(c) 2021 Sadovod_bel</p>

                    <p>По техническим или другим вопросам пишите в Инстаграм - Sadovod_bel </p>
                </FooterInner>
            </div>
        </Wrapper>
    )
}

export default Footer
