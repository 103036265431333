import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
    position: absolute;
    z-index: 12;
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: rgb(140 121 199 / 30%) 0px 5.81081px 15.1081px;
    border-radius: 50%;
`

function Pointer() {
    return (
        <Wrap>
            <svg width={21} height={22} viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M1.43528 1.91247H7.17459C7.4386 1.91247 7.65288 2.12675 7.65288 2.39076C7.65288 2.65477 7.4386 2.86905 7.17459 2.86905H1.43528C1.17127 2.86905 0.956988 2.65477 0.956988 2.39076C0.956988 2.12675 1.17127 1.91247 1.43528 1.91247Z'
                    fill='#8B75C8'
                />
                <path
                    d='M1.09691 2.05302L3.01004 0.139903C3.19657 -0.0466247 3.49981 -0.0466247 3.68634 0.139903C3.87287 0.326431 3.87287 0.629673 3.68634 0.8162L2.11183 2.3907L3.68728 3.96614C3.87381 4.15267 3.87381 4.45591 3.68728 4.64244C3.59353 4.73521 3.47112 4.78211 3.34866 4.78211C3.22621 4.78211 3.1038 4.73525 3.01004 4.64244L1.09691 2.72932C0.910377 2.54275 0.910377 2.23955 1.09691 2.05302Z'
                    fill='#8B75C8'
                />
                <path
                    d='M13.8702 1.91247H19.6095C19.8736 1.91247 20.0878 2.12675 20.0878 2.39076C20.0878 2.65477 19.8736 2.86905 19.6095 2.86905H13.8702C13.6062 2.86905 13.3919 2.65477 13.3919 2.39076C13.3919 2.12675 13.6062 1.91247 13.8702 1.91247Z'
                    fill='#8B75C8'
                />
                <path
                    d='M18.9333 2.39069L17.3588 0.816193C17.1722 0.629665 17.1722 0.326423 17.3588 0.139896C17.5453 -0.0466319 17.8485 -0.0466319 18.0351 0.139896L19.9481 2.05306C20.1347 2.23959 20.1347 2.54283 19.9481 2.72936L18.035 4.64248C17.9412 4.73525 17.8188 4.78215 17.6964 4.78215C17.5739 4.78215 17.4515 4.73529 17.3587 4.64154C17.1722 4.45501 17.1722 4.15177 17.3587 3.96524L18.9333 2.39069Z'
                    fill='#8B75C8'
                />
                <path
                    d='M1.91312 9.56478C2.30626 9.56478 2.67264 9.68437 2.97682 9.88811C3.2389 9.14393 3.94963 8.60825 4.78278 8.60825C5.17592 8.60825 5.54229 8.72783 5.84648 8.93158C6.10856 8.18739 6.81929 7.65171 7.65243 7.65171C8.0006 7.65171 8.32774 7.74547 8.60897 7.90904V4.78206C8.60897 3.72696 9.467 2.86894 10.5221 2.86894C11.5772 2.86894 12.4352 3.72696 12.4352 4.78206V12.9127L14.0977 11.6664C15.0829 10.9269 16.4852 11.0255 17.3586 11.8979C17.9181 12.4574 17.9181 13.3671 17.3586 13.9267L10.5469 20.7393C9.73388 21.5523 8.65202 22 7.50223 22H5.26102C2.36074 22 0 19.6402 0 16.739V11.4779C0 10.4228 0.858027 9.56478 1.91312 9.56478ZM0.956538 16.7389C0.956538 19.1121 2.88782 21.0434 5.26102 21.0434H7.50223C8.39756 21.0434 9.23837 20.6952 9.87063 20.0629L16.6823 13.2504C16.8688 13.0639 16.8688 12.7606 16.6823 12.5741C16.3857 12.2785 15.9897 12.1264 15.5918 12.1264C15.2685 12.1264 14.9432 12.2269 14.6706 12.4316L12.2438 14.2519C12.0984 14.3609 11.9033 14.3772 11.7426 14.2968C11.5809 14.2156 11.4786 14.0501 11.4786 13.8693V4.78206C11.4786 4.25498 11.0501 3.82552 10.522 3.82552C9.99403 3.82552 9.56551 4.25503 9.56551 4.78206V11.9562C9.56551 12.2202 9.35123 12.4345 9.08722 12.4345C8.82321 12.4345 8.60892 12.2202 8.60892 11.9562V9.56483C8.60892 9.03775 8.18041 8.60829 7.65239 8.60829C7.12437 8.60829 6.69585 9.0378 6.69585 9.56483V11.9562C6.69585 12.2202 6.48157 12.4345 6.21756 12.4345C5.95355 12.4345 5.73927 12.2202 5.73927 11.9562V10.5214C5.73927 9.99429 5.31075 9.56483 4.78273 9.56483C4.25471 9.56483 3.82619 9.99433 3.82619 10.5214V11.9562C3.82619 12.2202 3.61191 12.4345 3.3479 12.4345C3.0839 12.4345 2.86961 12.2202 2.86961 11.9562V11.4779C2.86961 10.9508 2.44109 10.5214 1.91308 10.5214C1.38506 10.5214 0.956538 10.9509 0.956538 11.4779V16.7389Z'
                    fill='#8B75C8'
                />
            </svg>
        </Wrap>
    )
}

export default Pointer
