import React from 'react'
import { Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const Wrap = styled.div`
    display: inline;
    cursor: pointer;

    p {
        display: inline;
    }

    span {
        padding: 4px;
    }
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    /* text-align: center; */

    span {
        text-align: left;
    }
`

function SocialLogin({ record }) {
    const LoginVK = ({ admin }) => {
        if (!admin) {
            return (
                <a href={`${record?.author?.userLogin}`} target='_blank' rel='noopener noreferrer'>
                    {record.author?.userLogin?.substr(15)}
                </a>
            )
        }

        return (
            <a href={`${record.userLogin}`} target='_blank' rel='noopener noreferrer'>
                {record?.userLogin?.substr(15)}
            </a>
        )
    }

    const LoginInstagram = ({ admin }) => {
        // if (!admin) {
        //     return (
        //         <a
        //             href={`https://www.instagram.com/${record.info?.instaLogin}`}
        //             target='_blank'
        //             rel='noopener noreferrer'
        //         >
        //             {record.info?.instaLogin}
        //         </a>
        //     )
        // }

        return (
            // <a
            //     href={`https://www.instagram.com/${record.info?.instaLogin}`}
            //     target='_blank'
            //     rel='noopener noreferrer'
            // >
            //     {record.info?.instaLogin}
            // </a>

            <Tooltip title={record.info?.instaLogin}>
                <Inner>
                    <p>{record.info?.instaLogin.slice(0, 6)}</p>

                    <CopyOutlined />
                </Inner>
            </Tooltip>
        )
    }

    return (
        <>
            {/*{!record.isAdmin ? (*/}
            {/*    record.author?.userLogin?.substr(0, 5) === 'https' ? (*/}
            {/*        <LoginVK />*/}
            {/*    ) : (*/}
            {/*        <LoginInstagram />*/}
            {/*    )*/}
            {/*) : record?.userLogin?.substr(0, 5) === 'https' ? (*/}
            {/*    <LoginVK admin />*/}
            {/*) : (*/}
            {/*    <LoginInstagram admin />*/}
            {/*)}*/}

            {record?.info?.instaLogin && (
                <Wrap
                    onClick={() => {
                        navigator.clipboard.writeText(record?.info?.instaLogin)
                    }}
                >
                    <LoginInstagram />
                </Wrap>
            )}
        </>
    )
}

export default SocialLogin
