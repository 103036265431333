import React, { memo } from 'react'
import { Popover } from 'antd'
import { useTotalSumOrderBy } from '../../hooks/useTotalSumOrderBy'

function TotalSumProductsBy({
    order,
    products,
    formula,
    personalPercent,
    personalPercentAdmdin,
    purchaseCourse,
    productsInStock
}) {
    const {
        sumProducts,
        productsStatusCheckDefects,
        sumProductsStatusCheckDefects,
        sumDelivery,
        totalSumOrder,
        sumProductsInStock
    } = useTotalSumOrderBy(
        order,
        products,
        formula,
        personalPercent,
        personalPercentAdmdin,
        purchaseCourse,
        productsInStock
    )

    const isShowTotalSum = products.find((item) => item.status === 'yes')

    const content = (
        <div>
            <p>Сумма товаров: {sumProducts} </p>
            <p>Сумма из наличия: {sumProductsInStock} </p>
            <p>Кол-во товаров с проверкой брака: {productsStatusCheckDefects}</p>
            <p>Сумма за проверку брака: {sumProductsStatusCheckDefects}</p>
            <p>Оплата за доставку: {sumDelivery}</p>
        </div>
    )

    if (Number.isNaN(+totalSumOrder)) {
        return <div>0</div>
    }

    return (
        <Popover content={content} title='Детали оплаты'>
            {(isShowTotalSum || (productsInStock && productsInStock.length)) && totalSumOrder
                ? (+totalSumOrder + sumProductsInStock).toFixed(2)
                : 0}
        </Popover>
    )
}

export default memo(TotalSumProductsBy)
