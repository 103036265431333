import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import axios from 'axios'
import { dispatchLogin } from '../../redux/actions/authAction'
import { useDispatch } from 'react-redux'
import TitlePage from '../../components/shared/TitlePage'

const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    form {
        width: 100%;
        button {
            width: 100%;
        }
    }
`

function LoginPage() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)

    const responseVk = async ({ session }) => {
        try {
            await axios.post('/user/vkontakte_login', { user: session.user })

            localStorage.setItem('firstLogin', true)

            setIsLoading(false)
            dispatch(dispatchLogin())

            history.push('/')
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    return (
        <Wrapper>
            <TitlePage>Вход в личный кабинет</TitlePage>
        </Wrapper>
    )
}

export default LoginPage
