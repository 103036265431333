import React, { useState, useEffect, useMemo } from 'react'
import { CarOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import axios from 'axios'
import { Excel } from 'antd-table-saveas-excel'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Table, Checkbox, Space } from 'antd'
import { PageInner } from '../../components/shared/PageInner'
import Button from '../../components/shared/Button'
import { filterOrderByStatus } from '../../utils/filterOrderByStatus'
import { useFormula } from '../../hooks/useFormula'
import TotalSumProductsBy from '../../components/TotalSumProductsBy/TotalSumProductsBy'
import ModalAddTracker from '../../components/ModalAddTracker/ModalAddTracker'
import { getTotalSumProductsByManager } from '../../utils/getTotalSumProductsByManager'

const TitleWrap = styled.div`
    text-align: center;
    margin-bottom: 15px;

    h2 {
        margin-bottom: 15px;
    }
`

const TableWrap = styled.div`
    overflow-x: scroll;

    width: 100%;
    margin-top: 30px;

    table {
        .ant-table-cell {
            padding: 8px;
        }

        .ant-table-row {
            &.packed {
                background-color: #fff9a861;
            }

            &.paidFor {
                background-color: #ffd7d0a7;
            }

            &.completed {
                background-color: #affaea4b;
            }
        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background: #f5f1f1;
        }

        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #cacaca;
        }
    }
`

const columnsDownload = [
    {
        title: 'Номер',
        dataIndex: 'articleOrder'
    },

    {
        title: 'Логин',
        dataIndex: 'instaLogin'
        // render: (text, record, index) => record.info.instaLogin
    },

    {
        title: 'ФИО',
        dataIndex: 'userName'
        // render: (text, record, index) => record.info.userName
    },

    {
        title: 'Отделение',
        dataIndex: 'numberEvropochta'
        // render: (text, record, index) => record.info.numberEvropochta
    },

    {
        title: 'Адрес',
        dataIndex: 'address'
        // render: (text, record, index) => record.info.address
    },

    {
        title: 'Телефон',
        dataIndex: 'phone'
        // render: (text, record, index) => record.info.phone
    },

    {
        title: 'Тип доставки',
        dataIndex: 'delivery'
        // render: (text, record, index) => record.info.delivery
    },

    {
        title: 'total',
        dataIndex: 'total'
        // render: (text, record, index) => record.info.delivery
    }
]

function AdminTableEvropochta() {
    const token = useSelector((state) => state.token)
    const params = useParams()

    const { isLoadingFormula, formula, errorFormula } = useFormula()

    const [isLoading, setIsLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [purchase, setPurchase] = useState([])
    const [isOrdersByManager, setIsOrdersByManager] = useState(false)

    const filteredOrders = orders.filter((item) => item.products.length > 0 || item?.productsInStock?.length > 0)

    const filteredByStatus = filterOrderByStatus(filteredOrders, 'yes')
    const filteredByStatusNoManager = filteredByStatus.filter((item) => !item?.isManager)

    const ordersByManager = filteredByStatus.filter((item) => item?.isManager)
    const resultFiltered = isOrdersByManager ? ordersByManager : filteredByStatusNoManager

    const onChange = (e) => {
        setIsOrdersByManager(e.target.checked)
    }

    const onChangeHandle = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    const resultFilteredForDownload = resultFiltered.map((item) => {
        return {
            articleOrder: item.articleOrder,
            instaLogin: item?.info?.instaLogin,
            id: item._id,
            userName: item?.info?.userName,
            pochtaIndex: item?.info?.pochtaIndex,
            phone: item?.info?.phone,
            address: item?.info?.address,
            delivery: item?.info?.delivery,
            total: getTotalSumProductsByManager(true, item, formula)?.toFixed(2)
        }
    })

    const columns = useMemo(
        () => [
            {
                title: 'Номер',
                dataIndex: 'articleOrder'
            },

            {
                title: 'Логин',
                dataIndex: 'instaLogin',
                render: (text, record, index) => record.info.instaLogin
            },

            {
                title: 'ФИО',
                dataIndex: 'userName',
                render: (text, record, index) => record.info.userName
            },

            {
                title: 'Отделение',
                dataIndex: 'numberEvropochta',
                render: (text, record, index) => {
                    return (
                        <>
                            {record.info.numberEvropochta}

                            <ModalAddTracker order={record} />
                        </>
                    )
                }
            },

            {
                title: 'Адрес',
                dataIndex: 'address',
                render: (text, record, index) => record.info.address
            },

            {
                title: 'Телефон',
                dataIndex: 'phone',
                render: (text, record, index) => record.info.phone
            },

            {
                title: 'Тип доставки',
                dataIndex: 'delivery',
                render: (text, record, index) => record.info.delivery
            },

            {
                title: 'Общая бел.руб',
                dataIndex: 'products',
                width: 75,
                render: (text, record, index) => {
                    if (!record?.isManager) {
                        if (text.length !== 0 || (record?.productsInStock && record?.productsInStock?.length > 0)) {
                            return (
                                <TotalSumProductsBy
                                    order={record}
                                    products={text}
                                    productsInStock={record?.productsInStock}
                                    formula={formula}
                                    personalPercent={record?.author?.personalPercent}
                                    personalPercentAdmdin={record.personalPercentAdmdin}
                                    purchaseCourse={purchase && purchase[0]?.purchaseCourse}
                                />
                            )
                        }
                        return 0
                    }

                    return <div>{getTotalSumProductsByManager(true, record, formula)?.toFixed(2)}</div>
                }
            }
        ],
        [formula, purchase]
    )

    useEffect(() => {
        const getPurchaseById = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/purchase/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })

                setPurchase(data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }

        getPurchaseById()
    }, [token, params.purchaseId])

    useEffect(() => {
        const getOrdersByBelpochta = async () => {
            setIsLoading(true)

            try {
                const { data } = await axios.get(`/order/evropochta/${params.purchaseId}`, {
                    headers: { Authorization: token }
                })

                setOrders(data)
                setIsLoading(false)
            } catch (err) {
                console.log('err', err)
                setIsLoading(false)
            }
        }

        getOrdersByBelpochta()
    }, [token, params.purchaseId])

    if (isLoadingFormula || isLoading) {
        return <div className='container'>Loading...</div>
    }

    if (errorFormula) {
        return <div className='container'>Что-то пошло не так... Попробуйте перезагрузить страницу.</div>
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <TitleWrap>
                        <h2>Заказы с типом доставки "Европочта":</h2>

                        <Space>
                            <Button
                                variant='secondary'
                                onClick={() => {
                                    const excel = new Excel()
                                    excel
                                        .addSheet(`evropochta-${purchase[0].title}`)
                                        .addColumns(columnsDownload)
                                        .addDataSource(resultFilteredForDownload)
                                        .saveAs(`evropochta-${purchase[0].title}.xlsx`)
                                }}
                            >
                                Скачать ексель
                            </Button>

                            <Checkbox onChange={onChange}>Заказы менеджера</Checkbox>
                        </Space>
                    </TitleWrap>

                    {!isLoading ? (
                        <TableWrap>
                            <Table
                                columns={columns}
                                dataSource={resultFiltered}
                                onChange={onChangeHandle}
                                rowKey='_id'
                                rowClassName={(record) => record?.status && `${record?.status}`}
                            />
                        </TableWrap>
                    ) : (
                        <p>Loading....</p>
                    )}
                </div>
            </PageInner>
        </section>
    )
}

export default AdminTableEvropochta
