import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PageInner } from '../../components/shared/PageInner'
import { useAllProductsInStock } from './useAllProductsInStock'
import SimpleReactLightbox from 'simple-react-lightbox'
import CardProductInStock from '../../components/CardProductInStock/CardProductInStock'
import { useSelector } from 'react-redux'

const PageTitle = styled.h2`
    text-align: center;
    margin-bottom: 50px;
`

const Loading = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CardsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
`

const ListEmpty = styled.div`
    text-align: center;
`

function ClientInStock() {
    const history = useHistory()

    const token = useSelector((state) => state.token)
    const auth = useSelector((state) => state.auth)

    const { isLoadingProducts, products, setProducts, errorProducts } = useAllProductsInStock(auth?.user?._id)

    const [isLoading, setIsLoading] = useState(false)

    if (errorProducts) {
        return <h2>Что-то пошло не так, попробуйте перезагрузить страницу!</h2>
    }

    if (isLoadingProducts) {
        return <Loading>Loading...</Loading>
    }

    return (
        <div className='container'>
            <PageInner>
                <PageTitle>В наличии:</PageTitle>

                <SimpleReactLightbox>
                    {products.length > 0 ? (
                        <CardsList>
                            {products.map((item, index) => (
                                <CardProductInStock item={item} key={index} auth={auth} />
                            ))}
                        </CardsList>
                    ) : (
                        <ListEmpty>Список пуст</ListEmpty>
                    )}
                </SimpleReactLightbox>
            </PageInner>
        </div>
    )
}

export default ClientInStock
