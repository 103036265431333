import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import DataProvider from './redux/store'
import { authenticateError } from './utils/AuthenticateError'
import 'swiper/swiper-bundle.css'
import 'antd/dist/antd.css'
import './index.css'

authenticateError()

ReactDOM.render(
    <DataProvider>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </DataProvider>,
    document.getElementById('root')
)
