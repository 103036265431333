import React, { useState } from 'react'
import { Button } from 'antd'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { deleteProductAction, setProductsByOrder } from '../../redux/actions/adminActions'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const TextError = styled.p`
    font-size: 12px;
    color: tomato;
`

function DeleteProductFromAdmin({ productId, showModal, orderId, getOrders }) {
    const dispatch = useDispatch()

    const token = useSelector((state) => state.token)
    const params = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleDelete = async () => {
        setIsLoading(true)

        try {
            await axios.delete(`/product/delete/${productId}/${params.purchaseId || params.id}/${orderId}`, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            showModal()

            if (getOrders) {
                return getOrders()
            }

            dispatch(deleteProductAction(orderId, productId))
            dispatch(setProductsByOrder(orderId))
        } catch (err) {
            console.log('handleDelete', err)
            setIsLoading(false)
            showModal()
            dispatch(deleteProductAction(orderId, productId))
            dispatch(setProductsByOrder(orderId))
        }
    }

    return (
        <Wrapper>
            {!isLoading ? 'Вы уверены, что хотите удалить данный продукт?' : 'Удаляем товар...'}

            <div>
                <Button onClick={showModal}>Закрыть</Button>
                <Button onClick={handleDelete} disabled={isLoading}>
                    Да
                </Button>
            </div>
        </Wrapper>
    )
}

export default DeleteProductFromAdmin
