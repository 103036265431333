import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Input, Select, Button, InputNumber } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import InputMask from 'react-input-mask'

const { Option } = Select

const Wrapper = styled.div`
    .ant-input-number {
        width: 100%;
    }

    .label {
        label {
            color: #1890ff;
            line-height: 16px;
            opacity: 0.6;
            white-space: normal;
            text-align: left;

            &:after {
                display: none;
            }
        }
    }
`

function EditOrderInfo({ order, handleUpdateOrder, handleCancel }) {
    const token = useSelector((state) => state.token)

    const [typeDelivery, setTypeDelivery] = useState(order.info.delivery)
    const [isLoading, setIsLoading] = useState(false)

    const isAdres = typeDelivery && typeDelivery !== 'samovivoz' && typeDelivery !== 'evropochta'

    const [form] = Form.useForm()

    function handleSelectChange(value) {
        setTypeDelivery(value)
    }

    const onSave = async (info) => {
        setIsLoading(true)

        try {
            const res = await axios.patch(
                `/order/update-info/${order._id}`,
                { info },
                {
                    headers: { Authorization: token }
                }
            )

            setIsLoading(false)
            handleUpdateOrder([res.data.newOrder])
            handleCancel()
        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        form.setFieldsValue({
            userName: order.info.userName,
            instaLogin: order.info.instaLogin,
            phone: order.info.phone,
            delivery: order.info.delivery,
            address: order.info.address,
            pochtaIndex: order.info.pochtaIndex,
            numberEvropochta: order.info.numberEvropochta
        })
    }, [
        form,
        order,
        order.info.address,
        order.info.delivery,
        order.info.numberEvropochta,
        order.info.phone,
        order.info.pochtaIndex,
        order.info.userName,
        order.info.instaLogin
    ])

    return (
        <Wrapper>
            <Form form={form} name='basic' onFinish={onSave} size='large'>
                <Form.Item name='userName' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <Input size='large' placeholder='ФИО' />
                </Form.Item>

                <Form.Item name='instaLogin'>
                    <Input size='large' placeholder='Логин Instagram' />
                </Form.Item>

                <Form.Item name='phone' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <InputMask mask='375-(99)-999-99-99'>
                        {(inputProps) => (
                            <Input size='large' type='tel' placeholder='Ваш телефон для связи' {...inputProps} />
                        )}
                    </InputMask>
                </Form.Item>

                <Form.Item name='delivery' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <Select onChange={handleSelectChange} className='select' placeholder='Тип доставки'>
                        <Option value='samovivoz'>Самовывоз</Option>
                        {/* <Option value="belpochta">Белпочта</Option> */}
                        {/* <Option value="evropochta">Европочта</Option> */}
                        <Option value='geoMinsk'>Доставка по Минску</Option>
                    </Select>
                </Form.Item>

                {isAdres ? (
                    <Form.Item name='address' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                        <Input placeholder='Точный адрес, куда доставить ваш заказ' />
                    </Form.Item>
                ) : null}

                {typeDelivery === 'belpochta' ? (
                    <Form.Item name='pochtaIndex' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                        <InputNumber placeholder='Индекс*' />
                    </Form.Item>
                ) : null}

                {typeDelivery === 'evropochta' ? (
                    <>
                        <Form.Item name='address' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                            <Input placeholder='Адрес отделения Европочты' />
                        </Form.Item>

                        <Form.Item
                            className='label'
                            name='numberEvropochta'
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input placeholder='Номер отделения Европочты' />
                        </Form.Item>
                    </>
                ) : null}

                <Form.Item>
                    <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>
                </Form.Item>
            </Form>
        </Wrapper>
    )
}

export default EditOrderInfo
