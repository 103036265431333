import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button, Popover } from 'antd'
import styled from 'styled-components'

import { PrinterOutlined } from '@ant-design/icons'
import TotalSumProductsBy from '../TotalSumProductsBy/TotalSumProductsBy'
import { getTotalSumProductsByManager } from '../../utils/getTotalSumProductsByManager'

const PrintWrap = styled.div`
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;

    .printOrder {
        display: none;
    }
`
const ContentWrap = styled.div`
    .printOrder {
        display: none;
    }
`

const deliveryType = {
    samovivoz: 'С',
    belpochta: 'БП',
    evropochta: 'ЕП',
    geoMinsk: 'Д'
}

function PrintOrder({ order, formula, purchase, isManager }) {
    const componentRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const content = (
        <ContentWrap>
            <div className='printOrder' ref={componentRef} style={{ marginBottom: 12 }}>
                <div className='printOrder-row'>
                    <p>{order?.articleOrder}</p>
                    <p>{deliveryType[order?.info?.delivery]}</p>
                </div>
                <p>{order?.info?.userName}</p>
                <p>{order?.info?.phone}</p>

                {!isManager ? (
                    <>
                        <TotalSumProductsBy
                            order={order}
                            products={order.products}
                            productsInStock={order?.productsInStock}
                            formula={formula}
                            personalPercent={order?.author?.personalPercent}
                            personalPercentAdmdin={order.personalPercentAdmdin}
                            purchaseCourse={purchase && purchase[0]?.purchaseCourse}
                        />{' '}
                        бел.руб
                    </>
                ) : (
                    <div>{getTotalSumProductsByManager(isManager, order, formula)?.toFixed(2)}</div>
                )}
            </div>

            <Button type='primary' onClick={handlePrint}>
                Печатать
            </Button>
        </ContentWrap>
    )

    return (
        <PrintWrap>
            <Popover content={content} title='Печать стикера' trigger='click'>
                <PrinterOutlined />
            </Popover>
        </PrintWrap>
    )
}

export default PrintOrder
