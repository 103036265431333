import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Input, Button, InputNumber, Checkbox, message, Upload } from 'antd'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Loader from '../shared/Loader'
import ImgCrop from 'antd-img-crop'
import { animateScroll as scroll } from 'react-scroll'

const REQUIRED_FIELD = 'Обязательное поле!'

const Wrapper = styled.div`
    .label {
        label {
            width: 100%;
            display: block;
            color: #1890ff;
            line-height: 16px;
            opacity: 0.6;
            white-space: normal;
            text-align: left;
            margin-bottom: 10px;
            height: auto;

            &:after {
                display: none;
            }
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: none;
        }
    }

    form {
        .ant-input-number {
            width: 100%;
        }

        .ant-form-item {
            &.checkDefects {
                .ant-form-item-label {
                    display: block;
                    width: 100%;
                    text-align: left;

                    label {
                        color: #1890ff;
                        height: auto;
                    }
                }
            }

            &.uploadImage {
                display: block;

                label {
                    display: block;
                    color: red;
                }
            }
        }

        input,
        textarea {
            &::placeholder {
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
`

const RowInputs = styled.div`
    display: flex;

    .ant-row {
        &:first-child {
            margin-right: 15px;
        }
    }
`

const BtnWrap = styled.div`
    margin-bottom: 30px;
`

function AddProduct() {
    const params = useParams()
    const history = useHistory()
    const token = useSelector((state) => state.token)
    const auth = useSelector((state) => state.auth)

    const titlePurchase = history.location.search

    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [filePhoto, setFilePhoto] = useState(null)
    const [filePhotoError, setFilePhotoError] = useState(false)

    const [form] = Form.useForm()

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const onFinish = async (values) => {
        if (!filePhoto) {
            setFilePhotoError(true)
            scroll.scrollToTop()
            return
        }

        setIsLoading(true)

        const {
            color,
            colorAdditional,
            comment,
            price,
            managerPrice,
            size,
            pointOnMarket,
            quantity,
            nameProduct,
            productLink,
            checkDefects
        } = values

        let formData = new FormData()
        formData.append('photo', filePhoto)
        formData.append('color', color)
        formData.append('colorAdditional', colorAdditional)
        formData.append('productLink', productLink)
        formData.append('comment', comment)
        formData.append('price', price)
        formData.append('size', size)
        formData.append('pointOnMarket', pointOnMarket)
        formData.append('quantity', quantity)
        formData.append('nameProduct', nameProduct)
        formData.append('checkDefects', checkDefects || false)
        formData.append('order', params.orderId)
        formData.append('purchase', params.id)

        if (auth.isAdmin) {
            formData.append('isAdmin', true)
        }

        if (auth.user.role === 3) {
            formData.append('isManager', true)
            formData.append('nameManager', auth.user?.managerName)
            formData.append('managerPrice', managerPrice)
        }

        try {
            await axios.post(`/product/create/${titlePurchase}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: token
                }
            })

            setIsLoading(false)
            message.success('Товар успешно добавлен!')
            setFilePhoto(null)
            form.resetFields()

            if (auth.user.role === 0) {
                history.push(`/client-purchase/${params.id}`)
            }
        } catch (err) {
            setError(err?.response?.data?.msg)
            setIsLoading(false)
        }
    }

    const onFinishFailed = () => {
        message.error('Заполните все обязательные поля!')
    }

    const compress = (photo) => {
        const fileName = photo.name
        const reader = new FileReader()

        reader.readAsDataURL(photo)
        reader.onload = (event) => {
            const img = new Image()

            img.src = event.target.result
            img.onload = () => {
                const width = 600
                const scaleFactor = width / img.width
                const elem = document.createElement('canvas')
                elem.width = width
                elem.height = img.height * scaleFactor
                const ctx = elem.getContext('2d')
                ctx.drawImage(img, 0, 0, width, img.height * scaleFactor)
                ctx.canvas.toBlob(
                    (blob) => {
                        const file = new File([blob], fileName, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        })

                        setFilePhoto(file)
                    },
                    'image/jpeg',
                    1
                )
            }
            reader.onerror = (error) => console.log(error)
        }
    }

    useEffect(() => {
        scroll.scrollToTop()
    }, [])

    if (error) {
        return <div className='container'>{error}</div>
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <Wrapper>
            <Form
                form={form}
                name='addProductClient'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                size='large'
                initialValues={{
                    comment: '',
                    colorAdditional: '',
                    checkDefects: false,
                    productLink: ''
                }}
            >
                <Form.Item
                    name='photo'
                    getValueFromEvent={normFile}
                    label={filePhotoError && 'Фото - обязательно!'}
                    className='uploadImage'
                >
                    <Upload
                        disabled={filePhoto}
                        maxCount={1}
                        name='photo'
                        customRequest={({ file, onError, onSuccess, onProgress }) => {
                            onSuccess()
                            compress(file)
                            setFilePhotoError(false)
                        }}
                        listType='picture-card'
                    >
                        добавить фото
                    </Upload>
                </Form.Item>

                <Form.Item
                    className='label'
                    label='Наименование товара: шорты, шапка, куртка, платье...'
                    name='nameProduct'
                    rules={[
                        {
                            required: true,
                            message: 'Название товара - ' + REQUIRED_FIELD
                        },

                        {
                            validator(rule, value) {
                                if (value && value.length > 50) {
                                    return Promise.reject('Текст слишком длиный!')
                                }
                                return Promise.resolve()
                            }
                        }
                    ]}
                >
                    <Input size='large' placeholder='Наименование товара*' />
                </Form.Item>

                <RowInputs>
                    <Form.Item
                        name='quantity'
                        rules={[
                            {
                                required: true,
                                message: 'Количество - ' + REQUIRED_FIELD
                            },

                            {
                                validator(rule, value) {
                                    if (value < 0) {
                                        return Promise.reject('Кол-во не может быть меньше нуля!')
                                    }
                                    return Promise.resolve()
                                }
                            }
                        ]}
                    >
                        <InputNumber placeholder='Количество*' type='number' />
                    </Form.Item>

                    <Form.Item
                        name='price'
                        rules={[
                            {
                                required: true,
                                message: 'Цена - ' + REQUIRED_FIELD
                            },

                            {
                                validator(rule, value) {
                                    if (value < 0) {
                                        return Promise.reject('Цена не может быть меньше нуля!')
                                    }
                                    return Promise.resolve()
                                }
                            }
                        ]}
                    >
                        <InputNumber placeholder='Цена (в рос. руб.)*' type='number' />
                    </Form.Item>
                </RowInputs>

                {auth.user.role === 3 && (
                    <Form.Item
                        name='managerPrice'
                        rules={[
                            {
                                required: true,
                                message: 'Цена продажи - ' + REQUIRED_FIELD
                            },

                            {
                                validator(rule, value) {
                                    if (value < 0) {
                                        return Promise.reject('Цена не может быть меньше нуля!')
                                    }
                                    return Promise.resolve()
                                }
                            }
                        ]}
                    >
                        <InputNumber placeholder='Цена продажи (в бел. руб.)*' type='number' />
                    </Form.Item>
                )}

                <RowInputs>
                    <Form.Item
                        name='size'
                        rules={[
                            {
                                required: true,
                                message: 'Размер - ' + REQUIRED_FIELD
                            }
                        ]}
                    >
                        <Input placeholder='Размер*' />
                    </Form.Item>

                    <Form.Item
                        name='color'
                        rules={[
                            {
                                required: true,
                                message: 'Цвет - ' + REQUIRED_FIELD
                            }
                        ]}
                    >
                        <Input size='large' placeholder='Цвет*' />
                    </Form.Item>
                </RowInputs>

                <Form.Item
                    className='label'
                    name='colorAdditional'
                    label='Если не будет цвета, который вы заказываете и согласны на другой, то вы можете указать дополнительный цвет'
                >
                    <Input size='large' placeholder='Дополнительный  цвет' />
                </Form.Item>

                <Form.Item
                    name='pointOnMarket'
                    rules={[
                        {
                            required: true,
                            message: 'Точка - ' + REQUIRED_FIELD
                        }
                    ]}
                >
                    <Input placeholder='Точка на рынке в формате 01-01*' />
                </Form.Item>

                <Form.Item
                    className='label'
                    name='productLink'
                    label='Ссылка на пост с товаром у поставщика ( если вы самостоятельно искали товар на Садоводе'
                >
                    <Input placeholder='Ссылка на товар' />
                </Form.Item>

                <Form.Item
                    name='comment'
                    rules={[
                        {
                            validator(rule, value) {
                                if (value.length > 138) {
                                    return Promise.reject('Текст слишком длиный!')
                                }
                                return Promise.resolve()
                            }
                        }
                    ]}
                >
                    <Input.TextArea placeholder='Комментарий к товару' />
                </Form.Item>

                {auth.user.role !== 3 && (
                    <Form.Item
                        className='checkDefects'
                        name='checkDefects'
                        valuePropName='checked'
                        label='Проверка на брак = +50 рос.руб к каждой позици'
                    >
                        <Checkbox>Проверка товара на брак</Checkbox>
                    </Form.Item>
                )}

                <Form.Item>
                    <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                        Добавить товар
                    </Button>
                </Form.Item>
            </Form>
        </Wrapper>
    )
}

export default AddProduct
