import React, { useEffect, useState } from 'react'
import { Select, Form, Input, Button, InputNumber, message } from 'antd'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Loader from '../shared/Loader'

const { Option } = Select

const FormWrap = styled.div`
    max-width: 500px;
    margin: 0 auto;

    .ant-input-number {
        width: 100%;
    }

    .ant-row {
        display: block;
    }

    .label {
        label {
            display: block;
            color: #1890ff;
            line-height: 16px;
            opacity: 0.6;
            white-space: normal;
            text-align: left;
            margin-bottom: 10px;
            height: auto;

            &:after {
                display: none;
            }
        }

        .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: none;
        }
    }
`

const FormTitle = styled.p`
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
`

function AddOrderInfo({ onFinish, isLoading }) {
    const { user } = useSelector((state) => state.auth)
    const token = useSelector((state) => state.token)

    const [form] = Form.useForm()

    const [isLoadingOrder, setIsLoadingOrder] = useState(false)
    const [typeDelivery, setTypeDelivery] = useState(null)

    const isAdres = typeDelivery && typeDelivery !== 'samovivoz' && typeDelivery !== 'evropochta'

    function handleSelectChange(value) {
        setTypeDelivery(value)
    }

    // useEffect(() => {
    //     if (user) {
    //         const getOrderByPhone = async (phone, userId, msg, orderId) => {
    //             setIsLoadingOrder(true)
    //             try {
    //                 const { data } = await axios.post(
    //                     `/order/orders_by_phone/`,
    //                     { phone: user.phoneLogin },
    //                     {
    //                         headers: { Authorization: token }
    //                     }
    //                 )

    //                 if (data.status === 'success') {
    //                     form.setFieldsValue({ ...data.data })
    //                     setIsLoadingOrder(false)
    //                 }
    //             } catch (err) {
    //                 setIsLoadingOrder(false)
    //                 console.log('addPenalty', err)
    //             }
    //         }

    //         void getOrderByPhone()
    //     }
    // }, [])

    // if (isLoadingOrder) {
    //     return <Loader />
    // }

    return (
        <FormWrap>
            <FormTitle>Для продолжения, заполните пожалуйста форму:</FormTitle>

            <Form name='basic' initialValues={{ phone: user.phoneLogin }} onFinish={onFinish} size='large' form={form}>
                <Form.Item name='userName' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <Input size='large' placeholder='ФИО' />
                </Form.Item>

                <Form.Item label='Если делаете заказ из Instagram, то обязательно укажите свой ник.' name='instaLogin'>
                    <Input size='large' placeholder='Логин Instagram' />
                </Form.Item>

                <Form.Item name='phone' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <InputMask mask='375-(99)-999-99-99' disabled={true}>
                        {(inputProps) => (
                            <Input
                                size='large'
                                type='tel'
                                placeholder='Ваш телефон для связи'
                                {...inputProps}
                                disabled={true}
                            />
                        )}
                    </InputMask>
                </Form.Item>

                <Form.Item name='delivery' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <Select onChange={handleSelectChange} className='select' placeholder='Тип доставки'>
                        <Option value='samovivoz'>Самовывоз</Option>
                        {/* <Option value='belpochta'>Белпочта</Option> */}
                        {/* <Option value='evropochta'>Европочта</Option> */}
                        <Option value='geoMinsk'>Доставка по Минску</Option>
                    </Select>
                </Form.Item>

                {isAdres ? (
                    <Form.Item name='address' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                        <Input placeholder='Точный адрес, куда доставить ваш заказ' />
                    </Form.Item>
                ) : null}

                {typeDelivery === 'belpochta' ? (
                    <Form.Item name='pochtaIndex' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                        <InputNumber placeholder='Индекс*' />
                    </Form.Item>
                ) : null}

                {typeDelivery === 'evropochta' ? (
                    <>
                        <Form.Item name='address' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                            <Input placeholder='Адрес отделения Европочты' />
                        </Form.Item>

                        <Form.Item
                            className='label'
                            name='numberEvropochta'
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input placeholder='Номер отделения Европочты' />
                        </Form.Item>
                    </>
                ) : null}

                <Form.Item>
                    <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Сохранить'}
                    </Button>
                </Form.Item>
            </Form>
        </FormWrap>
    )
}

export default AddOrderInfo
