import { useMemo } from 'react'

export const useSumProductsStatusYesBy = (
    items,
    course,
    percent,
    personalPercent,
    personalPercentAdmdin,
    purchaseCourse
) => {
    const total = useMemo(
        () =>
            items?.reduce(function (sum, { price, status, quantity, preQuantity }) {
                if (status !== 'yes') {
                    return sum
                }

                if (!preQuantity) {
                    return sum + price * quantity
                }

                return sum + price * preQuantity
            }, 0),
        [items]
    )

    if (personalPercentAdmdin) {
        return (total * (purchaseCourse || course) * personalPercentAdmdin).toFixed(2)
    }

    if (personalPercent) {
        return (total * (purchaseCourse || course) * personalPercent).toFixed(2)
    }

    return (total * (purchaseCourse || course) * percent).toFixed(2)
}
