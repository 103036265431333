import React from 'react'
import { Button, Popover } from 'antd'
import { filterProductsByStatus } from '../../utils/filterProductsByStatus'

function AddScreenshotReceipt({ screenshotReceipt, content, isShowAddScreenshot, showModalScreenShot, products }) {
    const productsByStatusYes = filterProductsByStatus(products, 'yes')

    if (!productsByStatusYes?.length) {
        return null
    }

    return (
        <>
            {isShowAddScreenshot && !screenshotReceipt ? (
                <Button onClick={showModalScreenShot} type='secondry'>
                    Загрузить фото чека
                </Button>
            ) : screenshotReceipt && screenshotReceipt.status !== 'rejected' ? (
                <Popover content={content} title='Фото чека' trigger='click'>
                    <Button>Фото чека</Button>
                </Popover>
            ) : isShowAddScreenshot ? (
                <Button onClick={showModalScreenShot} type='primary' danger>
                    Изменить фото чека
                </Button>
            ) : null}
        </>
    )
}

export default AddScreenshotReceipt
