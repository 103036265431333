import React, { useState, useEffect, useMemo, useCallback } from 'react'
import axios from 'axios'

export const useProductsByOrder = (token, orderId) => {
    const [isLoadingProducts, setIsLoadingOrders] = useState(false)
    const [products, setProducts] = useState([])

    const getProductsByOrder = useCallback(async () => {
        setIsLoadingOrders(true)

        try {
            const res = await axios.get(`/order/orders_by_id/${orderId}`, {
                headers: { Authorization: token }
            })

            setProducts(res.data.products)
            setIsLoadingOrders(false)
        } catch (err) {
            console.log('err', err)
            setIsLoadingOrders(false)
        }
    }, [])

    useEffect(() => {
        getProductsByOrder()
    }, [orderId, token])

    return { isLoadingProducts, products, getProductsByOrder }
}
