import React, { useMemo, memo } from 'react'
import { getTotalPriceRU } from '../../utils/totalPriceRU'

function TotalPriceRU({ items }) {

    const total = useMemo(() => getTotalPriceRU(items), [items])

    return (
        <p>{total}</p>
    )
}

export default memo(TotalPriceRU)
