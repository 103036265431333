import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

export const useOrdersByPurchase = (purchaseId) => {
    const token = useSelector(state => state.token)

    const [isLoadingOrder, setIsLoadingOrder] = useState(false)
    const [orders, setOrder] = useState([])

    useEffect(() => {
        if (purchaseId) {
            const getOrders = async () => {
                setIsLoadingOrder(true)

                try {
                    const { data } = await axios.get(`/order/check/${purchaseId}`, {
                        headers: { Authorization: token }
                    })

                    setOrder(data)
                    setIsLoadingOrder(false)

                } catch (err) {
                    setIsLoadingOrder(false)
                    console.log('err', err)
                }
            }
            getOrders()
        }

    }, [purchaseId, token])

    return [isLoadingOrder, orders, setOrder]
}