import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Row, Col } from 'antd'
import { PageInner } from '../../components/shared/PageInner'
import TitlePage from '../../components/shared/TitlePage'
import { useParams } from 'react-router-dom'
import Loader from '../../components/shared/Loader'
import ProductCardUserMini from '../../components/ProductCardUserMini/ProductCardUserMini'

const CardWrap = styled.div``

function ProductsRefOrder() {
    const token = useSelector((state) => state.token)
    const params = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState([])

    useEffect(() => {
        setIsLoading(true)

        const getProducts = async () => {
            try {
                const res = await axios.get(`/product/products/${params.orderId}`, {
                    headers: { Authorization: token }
                })
                setProducts(res.data)
                setIsLoading(false)
            } catch (err) {
                setIsLoading(false)
                console.log('err', err)
            }
        }
        getProducts()
    }, [token, params.orderId])

    if (isLoading) {
        return <Loader />
    }

    return (
        <div className='container'>
            <PageInner>
                <TitlePage>Товары заказа № </TitlePage>

                {/* <Row gutter={[30, 30]}>
                    {
                        products.map((item, index) => (
                            <Col span={8} key={index}>
                                <CardWrap>
                                    <ProductCardUserMini item={item} />
                                </CardWrap>
                            </Col>
                        ))
                    }
                </Row> */}
            </PageInner>
        </div>
    )
}

export default ProductsRefOrder
