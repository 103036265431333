import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import { Modal } from 'antd'
import PopularProducts from '../../components/PopularProducts/PopularProducts'
import { useOrdersByPurchase } from '../../hooks/useOrdersByPurchase'
import Loader from '../../components/shared/Loader'
import { PageInner } from '../../components/shared/PageInner'
import TitlePage from '../../components/shared/TitlePage'
import AddOrderInfo from '../../components/AddOrderInfo/AddOrderInfo'
import AddProduct from '../../components/AddProduct/AddProduct'

const AddProductWrap = styled.div`
    max-width: 320px;
    margin: 0 auto;
`


function PopularProductsPage() {
    const token = useSelector(state => state.token)

    const [isLoading, setIsLoading] = useState(false)

    const [selectPhotoProduct, setSelectPhotoProduct] = useState()

    const [isModalOrderVisible, setIsModalOrderVisible] = useState(false)
    const [isModalProductVisible, setIsModalProductVisible] = useState(false)

    const [isLoadingProducts, setIsLoadingProducts] = useState(false)
    const [products, setProducts] = useState([])

    const [isLoadingPurchases, setIsLoadingPurchases] = useState(false)
    const [purchases, setPurchases] = useState([])

    const purchaseId = purchases[0] && purchases[1]._id

    const [isLoadingOrder, orders] = useOrdersByPurchase(purchaseId, token)

    const isOrder = orders.length !== 0

    const params = {
        slidesPerView: 'auto',
        spaceBetween: 30,
        grabCursor: true,
    }

    const showModalOrder = () => {
        setIsModalOrderVisible(true)
    };

    const closeModalOrder = () => (
        setIsModalOrderVisible(false)
    )

    const showModalProduct = () => {
        setIsModalProductVisible(true)
    };

    const closeModalProduct = () => (
        setIsModalProductVisible(false)
    )

    const handleAddProduct = async (photo) => {

        if (!isOrder) {
            showModalOrder()
            return
        }
        showModalProduct()

        return new Promise((resolve, reject) => {
            const image = new Image()
            image.onload = resolve;
            image.onerror = reject;
            image.src = `images/${photo}`;

            console.log(`${image.width}-${image.height}`)

            // const file = new File([img], `img`)
            // setSelectPhotoProduct(file)
        })

    }

    const onFinish = async (info) => {
        setIsLoading(true)

        try {
            const res = await axios.post(`/order/create/${purchaseId}`, { info }, {
                headers: { Authorization: token }
            })

            setIsLoading(false)
            closeModalOrder()

        } catch (err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const getPurchases = async () => {
            setIsLoadingPurchases(true)

            try {
                const res = await axios.get(`/purchase/open`, {
                    headers: { Authorization: token }
                })
                setPurchases(res.data)
                setIsLoadingPurchases(false)

            } catch (err) {
                console.log('err', err)
                setIsLoadingPurchases(false)
            }
        }
        getPurchases()

    }, [token])


    useEffect(() => {
        const getProducts = async () => {
            setIsLoadingProducts(true)

            try {
                const res = await axios.get(`/product/products/all`, {
                    headers: { Authorization: token }
                })
                setProducts(res.data)
                setIsLoadingProducts(false)

            } catch (err) {
                console.log('err', err)
                setIsLoadingProducts(false)
            }
        }
        getProducts()

    }, [token])

    if (isLoadingProducts || isLoadingOrder || isLoadingPurchases) {
        return <Loader />
    }

    return (
        <>
            <div className='container'>
                <PageInner>
                    <TitlePage>Товары, добавленные другими пользователями:</TitlePage>

                    <PopularProducts data={products} isLoading={isLoadingProducts} params={params} onClick={handleAddProduct} />
                </PageInner>
            </div>

            <Modal
                title="Создание заказа:"
                visible={isModalOrderVisible}
                onCancel={closeModalOrder}
                footer={null}
            >
                <AddOrderInfo onFinish={onFinish} isLoading={isLoading} />
            </Modal>

            <Modal
                title="Создание товара:"
                visible={isModalProductVisible}
                onCancel={closeModalProduct}
                footer={null}
            >
                <AddProductWrap>
                    <AddProduct selectPhotoProduct={selectPhotoProduct} />
                </AddProductWrap>

            </Modal>
        </>

    )
}

export default PopularProductsPage
