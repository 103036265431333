import { totalPriceBY } from '../utils/totalPriceBY'
import { useMemo } from 'react'
import { totalPriceProductInStockBY } from '../utils/totalPriceProductInStockBY'

export const useTotalSumOrderBy = (
    order,
    products,
    formula,
    personalPercent,
    personalPercentAdmdin,
    purchaseCourse,
    productsInStock
) => {
    const sumProducts = useMemo(
        () =>
            totalPriceBY(
                products,
                formula?.course,
                formula?.percent,
                personalPercent,
                personalPercentAdmdin,
                purchaseCourse
            ),
        [formula?.course, formula?.percent, personalPercent, personalPercentAdmdin, products, purchaseCourse]
    )

    const sumProductsInStock = useMemo(() => totalPriceProductInStockBY(productsInStock), [productsInStock])

    const productsStatusCheckDefects = useMemo(
        () =>
            products?.reduce(function (sum, { checkDefects, quantity, status, preQuantity }) {
                if (checkDefects === false || status === 'no' || status === 'pending' || status === 'yesMoscow') {
                    return sum
                }

                if (!preQuantity) {
                    return sum + quantity
                }

                return sum + preQuantity

                // return sum + quantity
            }, 0),
        [products]
    )

    const sumProductsStatusCheckDefects = useMemo(() => {
        return (
            formula?.checkDefectsPrice *
            (purchaseCourse || formula?.course) *
            formula?.percent *
            productsStatusCheckDefects
        ).toFixed(2)
    }, [formula?.checkDefectsPrice, formula?.course, formula?.percent, productsStatusCheckDefects, purchaseCourse])

    const getSumDelivery = () => {
        if (order && order.info) {
            switch (order.info.delivery) {
                case 'evropochta':
                    return formula?.evropochtaPrice

                case 'geoMinsk':
                    return formula?.geoMinskPrice

                default:
                    return 0.001
            }
        }
    }

    const sumDelivery = getSumDelivery()

    const totalSumOrder = useMemo(
        () => (+sumProducts + +sumProductsStatusCheckDefects + +sumDelivery).toFixed(2),
        [sumDelivery, sumProducts, sumProductsStatusCheckDefects]
    )

    return {
        sumProducts,
        productsStatusCheckDefects,
        sumProductsStatusCheckDefects,
        sumDelivery,
        totalSumOrder,
        sumProductsInStock
    }
}
