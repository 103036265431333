import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Tag, Modal } from 'antd'
import NoPhoto from '../../../assets/photo.png'

const CardWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 99;
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
`

const CardInner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    padding: 15px;

    .ant-tag {
        margin-bottom: 15px;
        text-align: center;
        margin-right: 0;
    }
`

const CardImgWrap = styled.div`
    position: relative;
    max-width: 500px;
    max-height: 500px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 15px;

    img {
        max-width: 100%;
        max-height: 300px;
        border-radius: 6px;

        &.noPhoto {
            object-fit: contain;
        }
    }

    .ant-tag {
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        text-align: center;
        margin-right: 0;
        padding: 8px 4px;
    }
`

const CardInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        margin: 0;
        margin-bottom: 7px;

        span {
            font-weight: 400;
        }

        &.comment {
            color: tomato;
            opacity: 0.9;
            padding-top: 10px;
        }
    }

    h4 {
        font-size: 16px;
        text-align: center;
        font-weight: 300;
        line-height: 16px;
        color: #1890ff;
        margin-bottom: 6px;
    }
`

const CardBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    button {
        width: 100%;
    }
`

function ProductCardUser({ item, priceBY, className }) {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible((prev) => !prev)
    }

    return (
        <>
            <CardWrapper className={className}>
                <CardInner>
                    <CardImgWrap>
                        <img
                            className='noPhoto'
                            src={`/images/${item.photo}`}
                            alt='Фото товара'
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = `${NoPhoto}`
                            }}
                        />

                        {item.status === 'yesMoscow' && (
                            <Tag color='purple'>
                                Выкуплено <br /> (в Москве)
                            </Tag>
                        )}

                        {item.status === 'yes' && (
                            <Tag color='green'>
                                Выкуплено <br /> (в Минске)
                            </Tag>
                        )}

                        {item.status === 'no' && <Tag color='red'>Не выкуплено</Tag>}
                        {item.status === 'return' && <Tag color='red'>Не выкуплено</Tag>}
                    </CardImgWrap>

                    <CardInfo>
                        <h4>{item.nameProduct}</h4>

                        <p>
                            <span>Кол-во: </span>
                            {!item.preQuantity ? `${item.quantity}` : `${item.preQuantity} из ${item.quantity}`}
                        </p>

                        <p>
                            <span>Цвет: </span>
                            {item.color}
                        </p>

                        <p>
                            <span>Доп. цвет: </span>
                            {item.colorAdditional ? item.colorAdditional : '---'}
                        </p>

                        <p>
                            <span>Размер: </span>
                            {item.size}
                        </p>

                        <p>
                            <span>Точка на рынке: </span>
                            {item.pointOnMarket}
                        </p>

                        <p>
                            <span>Цена: </span>
                            {`${item.price} рос. руб.`}
                        </p>

                        <p>
                            <span>Цена: </span>
                            {`${priceBY} бел. руб.`}
                        </p>

                        {item.comment ? <p className='comment'>{item.comment}</p> : null}

                        <p>
                            <span>Проверка на брак </span>
                            {item.checkDefects ? 'Да' : 'Нет'}
                        </p>
                    </CardInfo>
                </CardInner>
            </CardWrapper>
        </>
    )
}

export default ProductCardUser
