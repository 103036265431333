export const totalPriceBY = (items, course, percent, personalPercent, personalPercentAdmdin, purchaseCourse) => {
    if (!items || !items.length) {
        return 0
    }

    const total = items?.reduce(function (sum, { price, status, quantity, preQuantity }) {
        // if (status === 'no' || !status || status === 'pending' || status === 'yesMoscow') {
        //     return sum
        // }

        if (status !== 'yes' || !status) {
            return sum
        }

        if (!preQuantity) {
            return sum + price * quantity
        }

        return sum + price * preQuantity
    }, 0)

    if (personalPercent) {
        return (total * (purchaseCourse || course) * personalPercent).toFixed(2)
    }

    if (personalPercentAdmdin) {
        return (total * (purchaseCourse || course) * personalPercentAdmdin).toFixed(2)
    }

    if (course && percent) {
        return (total * (purchaseCourse || course) * percent).toFixed(2)
    }

    return 0
}
