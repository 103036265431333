import React, { useState } from 'react'
import { Button, Form, Input, DatePicker, Checkbox } from 'antd'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { PageInner } from '../../components/shared/PageInner'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const FormWrap = styled.div`
    padding-top: 40px;

    form {
        max-width: 500px;
        margin: 0 auto;

        .ant-picker {
            width: 100%;
        }
    }
`

function AdminCreatePurchase() {
    const auth = useSelector(state => state.auth)
    const token = useSelector(state => state.token)
    const history = useHistory()

    const [isLoading, setIsloading] = useState(false)

    const onFinish = async (values) => {
        setIsloading(true)
        const { title, date, dateOfDelivery, distribution, deliveryDays, paymentDays, mailDays, phone, isTest } = values

        const newObj = {
            title,
            dateStart: date[0]._d,
            dateEnd: date[1]._d,
            dateOfDelivery,
            distribution,
            deliveryDays,
            paymentDays,
            mailDays,
            phone,
            isTest
        }

        try {
            await axios.post(`/purchase/create/`, { ...newObj }, {
                headers: { Authorization: token }
            })

            history.push('/admin/open-purchases')
            setIsloading(false)

        } catch (err) {
            console.log(err);
            setIsloading(false)
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    if (!auth.isAdmin) {
        return <h2>У вас нет доступа к этой странице!</h2>
    }

    if (isLoading) {
        return <div className='container'>Loading...</div>
    }

    return (
        <PageInner>
            <div className='container'>
                <FormWrap>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="title"
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input placeholder='Заголовок закупки' size="large" />
                        </Form.Item>

                        <Form.Item
                            name="date"
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >

                            <DatePicker.RangePicker size="large" />
                        </Form.Item>

                        <Form.Item
                            name="dateOfDelivery"
                            label='Дата привоза товара в Минск'
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <DatePicker size="large" />
                        </Form.Item>

                        <Form.Item
                            name="distribution"
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input size='large' placeholder='Раздача' />
                        </Form.Item>

                        <Form.Item
                            name="deliveryDays"
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input size='large' placeholder='Доставка' />
                        </Form.Item>

                        <Form.Item
                            name="paymentDays"
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input size='large' placeholder='Оплаты' />
                        </Form.Item>

                        <Form.Item
                            name="mailDays"
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input size='large' placeholder='Отправка почты' />
                        </Form.Item>

                        <Form.Item
                            name="phone"
                            rules={[{ required: true, message: 'Обязательное поле!' }]}
                        >
                            <Input size='large' placeholder='Телефон' />
                        </Form.Item>

                        <Form.Item
                            className='isTest'
                            name="isTest"
                            valuePropName="checked"
                            label='Использовать, как тестовую (скрытую) закупку'
                        >
                            <Checkbox />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" size="large">
                                Открыть сбор
                            </Button>
                        </Form.Item>
                    </Form>
                </FormWrap>
            </div>
        </PageInner>
    )
}

export default AdminCreatePurchase

