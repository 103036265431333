import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import TitlePage from '../../components/shared/TitlePage'

const StyledPageInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
`

const ContentWrap = styled.div`
    position: relative;
    background: #fff;
    transform-style: preserve-3d;
    border-radius: 15px;
    box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
    margin-bottom: 40px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;

    &:after {
        content: '';
        position: absolute;
        width: 96%;
        height: 30px;
        background-color: #fff;
        left: 2%;
        bottom: -7px;
        transform: translateZ(-1px);
        box-shadow: 0 10px 35px rgb(0 0 0 / 5%);
        border-radius: 15px;
    }
`

const Content = styled.div`
    max-width: 1000px;

    border: 1px solid #ededed;
    border-radius: 15px;
    height: 100%;
    padding: 40px;

    h4 {
        text-align: center;
        margin-bottom: 12px;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    @media (max-width: 770px) {
        padding: 12px;
    }
`

const LinkWrap = styled.div`
    display: flex;
    justify-content: center;
`

function HomePage() {
    const auth = useSelector((state) => state.auth)

    return (
        <section>
            <div className='container'>
                <StyledPageInner>
                    {auth.user.role === 0 && (
                        <>
                            <TitlePage>Добро пожаловать</TitlePage>

                            <ContentWrap>
                                <Content>
                                    <h4>Добро пожаловать в совместные покупки с оптового рынка “Садовод”</h4>

                                    <p>
                                        Просим обратить внимание на то, что мы не интернет-магазин, а организаторы
                                        совместных покупок. Мы не продаем вещи, а оказываем услуги по выкупу, доставке
                                        товаров в Минск и отправке их в другие города Беларуси.
                                    </p>

                                    <p>
                                        Мы, как и вы, в большинстве случаев не видим товар в живую. Только по фото.
                                        Поэтому не несем ответственность за смену фурнитуры, не тот оттенок, замену
                                        цвета, пересорт и брак! Также необходимо понимать, что есть вещи которые
                                        маломерят, большемерят или просто не подошли по размеру. За это мы тоже не несем
                                        ответственность.
                                    </p>

                                    <LinkWrap>
                                        <NavLink to='/client-purchase'>Принять участие</NavLink>
                                    </LinkWrap>
                                </Content>
                            </ContentWrap>
                        </>
                    )}

                    {auth.user.role === 3 && (
                        <>
                            <TitlePage>Вы вошли как Менеджер</TitlePage>
                            <NavLink to='/manager/open-purchases'>Смотреть открытые закупки</NavLink>
                        </>
                    )}

                    {auth.isAdmin && (
                        <>
                            <TitlePage>Вы вошли как Администратор</TitlePage>
                            <NavLink to='/admin/open-purchases'>Смотреть открытые закупки</NavLink>
                        </>
                    )}
                </StyledPageInner>
            </div>
        </section>
    )
}

export default HomePage
