import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useProductsByOrder } from './useProductsByOrder'
import ProductCardManager from '../../components/ProductCardManager/ProductCardManager'
import { usePurchase } from '../AdminTableManagers/usePurchase'

const Wrapper = styled.div``

const TitleStyled = styled.div`
    text-align: center;
    padding-top: 30px;
`

const ProductsList = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;

    @media (max-width: 420px) {
        margin-right: 0;
        margin-left: 0;
    }
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(50% - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;

    @media (max-width: 420px) {
        max-width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
`

function ManagerProductsByOrder() {
    const token = useSelector((state) => state.token)
    const auth = useSelector((state) => state.auth)
    const params = useParams()

    const search = window.location.search
    const paramsAll = new URLSearchParams(search)
    const articleOrder = paramsAll.get('articleOrder')

    const { purchase, isLoadingPurchase } = usePurchase(token, params.purchaseId)

    const { isLoadingProducts, products, getProductsByOrder } = useProductsByOrder(token, params.orderId)

    if (!products?.length) {
        return <TitleStyled>В этом закезе товаров не найдено.</TitleStyled>
    }

    if (isLoadingProducts || isLoadingPurchase) {
        return <div className='container'>Loading...</div>
    }

    return (
        <Wrapper>
            <TitleStyled>Продукты заказа - {articleOrder}</TitleStyled>

            <div className='container'>
                {products?.length > 0 && (
                    <ProductsList>
                        {products?.map((item) => (
                            <CardWrap key={item._id}>
                                <ProductCardManager
                                    item={item}
                                    paramId={params.purchaseId}
                                    orderId={params.orderId}
                                    getProductsByOrder={getProductsByOrder}
                                    purchase={purchase}
                                />
                            </CardWrap>
                        ))}
                    </ProductsList>
                )}
            </div>
        </Wrapper>
    )
}

export default ManagerProductsByOrder
