export const getTotalPriceRU = (items) => {

    const total = items.reduce(function (sum, { price, status, quantity, preQuantity }) {

        if (status === "no" || !status || status === "pending") {
            return sum;
        }

        if (!preQuantity) {
            return sum + price * quantity

        }

        return sum + price * preQuantity


        // return sum + price * quantity;
    }, 0)


    return total
}


