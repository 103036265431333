import React, { useEffect, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { NavLink, useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { Card, Popconfirm, message, Button, Space, Alert, Tag } from 'antd'
import { PageInner } from '../../components/shared/PageInner'
import Loader from '../../components/shared/Loader'
import { dowloadPurchaseImages } from '../../utils/dowloadPurchaseImages'
import AddWarningMessage from '../../components/AddWarningMessage/AddWarningMessage'

const StyledPageInner = styled(PageInner)`
    width: 100%;
    padding-top: 20px;
`

const Title = styled.h2`
    margin-bottom: 30px;
`

const CardList = styled.div`
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;

    .ant-card-head-title {
        margin-right: 20px;
    }

    .card {
        width: 100%;
        max-width: 320px;

        &.test {
            background: #ffc0b5;
        }
    }

    .cardLink {
        display: block;
        margin-top: 15px;
    }
`

const CardWrap = styled.div`
    width: 100%;
    max-width: calc(360px - 30px);
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;

    .fullWidth {
        width: 100%;
    }

    .rowBtns {
        display: flex;
        justify-content: space-between;

        .linkStop {
            color: tomato;
            text-align: center;
            display: block;
        }
    }
`

const DateWrap = styled.div`
    font-size: 16px;
    margin-bottom: 16px;

    p {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const BtnsWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .linkList {
        margin-bottom: 10px;

        li {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
`

function AdminOpenPurchase({ isManager }) {
    const token = useSelector((state) => state.token)
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [purchase, setPurchase] = useState([])

    const handleUpdateMessagePurchase = useCallback(
        (id, message) => {
            const newWarningMessage = !message ? null : message

            const newArr = purchase.map((item) => {
                if (item._id === id) {
                    const newPurchase = { ...item, warningMessage: newWarningMessage }
                    return newPurchase
                }
                return item
            })

            setPurchase(newArr)
        },
        [purchase]
    )

    const getPurchase = async () => {
        setIsLoading(true)

        try {
            const res = await axios.get(`/purchase/open`, {
                headers: { Authorization: token }
            })

            if (res?.data?.length) {
                const purchaseNoTest = res.data.filter((item) => !item.isTest)
                const items = isManager ? purchaseNoTest : res.data
                setPurchase(items)
            }

            // setPurchase(res.data)

            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log('err', err)
        }
    }

    async function confirm(id) {
        try {
            const { data } = await axios.patch(
                `/purchase/update/${id}`,
                { status: false },
                {
                    headers: { Authorization: token }
                }
            )

            message.success(data.msg)
            getPurchase()
        } catch (err) {
            console.log(err)
            message.success(err.response.data.msg)
        }
    }

    useEffect(() => {
        getPurchase()
    }, [])

    if (isLoading) {
        return <Loader />
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <StyledPageInner>
                        <Title>Открытые закупки:</Title>
                        {purchase.length !== 0 ? (
                            <CardList>
                                {purchase.map((item) => (
                                    <CardWrap isTest={item.isTest} key={item._id}>
                                        <Card
                                            title={item.title}
                                            extra={
                                                <NavLink to={`/admin/table-orders/${item._id}?title=${item.title}`}>
                                                    Перейти к таблице
                                                </NavLink>
                                            }
                                            className={`card ${item.isTest && 'test'}`}
                                        >
                                            <DateWrap>
                                                <p>
                                                    {' '}
                                                    <span>Начало сбора -</span>{' '}
                                                    {format(new Date(item.dateStart), 'dd.MM.yyyy')}
                                                </p>
                                                <p>
                                                    {' '}
                                                    <span>Окончание сбора - </span>{' '}
                                                    {format(new Date(item.dateEnd), 'dd.MM.yyyy')}
                                                </p>
                                            </DateWrap>

                                            {item.warningMessage && (
                                                <Alert message={item.warningMessage} type='warning' showIcon />
                                            )}

                                            <BtnsWrap>
                                                <ul className='linkList'>
                                                    <li>
                                                        <NavLink to={`/admin/table-belpochta/${item._id}`}>
                                                            Таблица "Белпочта"
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to={`/admin/table-samovivoz/${item._id}`}>
                                                            Таблица "Самовывоз"
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to={`/admin/table-evropochta/${item._id}`}>
                                                            Таблица "Европочта"
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to={`/admin/table-geoMinsk/${item._id}`}>
                                                            Таблица "Доставка по Минску"
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to={`/admin/table-screenshots/${item._id}`}>
                                                            Таблица "Фото чеков"
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to={`/admin/table-sms/${item._id}`}>
                                                            Таблица для SMS
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink to={`/admin/table-managers/${item._id}`}>
                                                            Менеджеры
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </BtnsWrap>

                                            <Space direction='vertical' size='middle' className='fullWidth'>
                                                <Space direction='vertical' className='rowBtns'>
                                                    <Popconfirm
                                                        title='Вы точно уверенны, что хотите остановить данный сбор?'
                                                        onConfirm={() => confirm(item._id)}
                                                        okText='Да'
                                                        cancelText='Нет'
                                                        overlayStyle={{ maxWidth: '205px' }}
                                                    >
                                                        <a href='/' className='linkStop'>
                                                            СТОП
                                                        </a>
                                                    </Popconfirm>

                                                    <Button
                                                        type='primary'
                                                        onClick={() =>
                                                            dowloadPurchaseImages(item._id, item.title, token)
                                                        }
                                                        className='fullWidth'
                                                    >
                                                        Скачать картинки
                                                    </Button>
                                                </Space>

                                                <Button
                                                    className='fullWidth'
                                                    onClick={() => history.push(`/admin/statistics/${item._id}`)}
                                                >
                                                    Статистика
                                                </Button>

                                                <AddWarningMessage
                                                    getPurchase={getPurchase}
                                                    purchaseId={item._id}
                                                    currentWarning={item.warningMessage}
                                                    handleUpdateMessagePurchase={handleUpdateMessagePurchase}
                                                />
                                            </Space>
                                        </Card>
                                    </CardWrap>
                                ))}
                            </CardList>
                        ) : (
                            <p>В настоящее время открытых закупок нет.</p>
                        )}
                    </StyledPageInner>
                </div>
            </PageInner>
        </section>
    )
}

export default AdminOpenPurchase
