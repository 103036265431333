import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { dispatchLogin } from '../../redux/actions/authAction'
import { useDispatch } from 'react-redux'


const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    form {
        width: 100%;

        button {
            width: 100%;
        }
    }
`

function LoginAdminPage() {
    const dispatch = useDispatch()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)

    const onFinish = async (values) => {
        setIsLoading(true)
        const { password, email } = values

        try {
            await axios.post('/user/login', { email, password })

            localStorage.setItem('firstLogin', true)

            setIsLoading(false)
            dispatch(dispatchLogin())

            history.push("/")

        } catch (err) {
            setIsLoading(false)
            message.error(err?.data?.msg)
        }
    }

    return (
        <Wrapper>
            <h2>Вход</h2>

            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                size='large'
            >

                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <Input size='large' placeholder='email' />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Обязательное поле!' }]}
                >
                    <Input.Password placeholder='password' />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" size='large' disabled={isLoading}>
                        {isLoading ? 'Загрузка...' : 'Войти'}
                    </Button>
                </Form.Item>

                <NavLink to='/admin-register'>
                    Зарегистрироваться
                </NavLink>
            </Form>

        </Wrapper>
    )
}

export default LoginAdminPage
