import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useParams, useHistory, Link } from 'react-router-dom'
import { Table, Modal, Tag, Input, Space, Button, Tooltip, message } from 'antd'
import { CarOutlined, PlusCircleOutlined, SearchOutlined, LinkOutlined } from '@ant-design/icons'
import { PageInner } from '../../components/shared/PageInner'
import OrderStatusInTable from '../../components/OrderStatusInTable/OrderStatusInTable'
import { getOrdersAdmin } from '../../redux/actions/adminActions'
import AddOrderInfoFromAdmin from '../../components/AddOrderInfoFromAdmin/AddOrderInfoFromAdmin'
import ModalEditOrderFromAdmin from '../../components/ModalEditOrderFromAdmin/ModalEditOrderFromAdmin'
import ModalAddCommenInOrder from '../../components/ModalAddCommenInOrder/ModalAddCommenInOrder'
import PopoverComment from '../../components/PopoverComment/PopoverComment'
import { getColumnSearchProps } from '../../utils/getColumnSearchProps'
import ModalAddTracker from '../../components/ModalAddTracker/ModalAddTracker'
import TotalSumProductsBy from '../../components/TotalSumProductsBy/TotalSumProductsBy'
import TotalPriceRU from '../../components/TotalPriceRU/TotalPriceRU'
import SocialLogin from '../../components/SocialLogin/SocialLogin'
import ModalOrdersAdmin from '../../components/ModalOrdersAdmin/ModalOrdersAdmin'
import AddPenalty from '../AdminTableClientsPage/AddPenalty'

const TitleWrap = styled.div`
    text-align: center;
    margin-bottom: 15px;

    h2 {
        margin-bottom: 15px;
    }
`

const TableWrap = styled.div`
    overflow-x: scroll;

    width: 100%;
    margin-top: 30px;

    table {
        .ant-table-cell {
            padding: 8px;
        }

        .ant-table-row {
            &.packed {
                background-color: #fff9a861;
            }

            &.paidFor {
                background-color: #ffd7d0a7;
            }

            &.completed {
                background-color: #affaea4b;
            }
        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background: #f5f1f1;
        }

        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #cacaca;
        }

        .ant-table-filter-column {
            svg {
                color: inherit;
                width: 16px;
                height: 16px;
            }
        }

        .ant-tooltip {
            z-index: -1 !important;
        }
    }
`

const ProductItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;

    &[data-preQuantity='Y'] {
        background-color: pink;
    }

    .checkDefects {
        color: tomato;
        margin-left: 6px;
    }

    &.no {
        color: tomato;
    }

    &.yes {
        color: #33c733;
    }

    &.return {
        background-color: #000;
        color: #fff;
        border-radius: 2px;
        padding: 2px 4px;
    }

    &.yesMoscow {
        color: purple;
    }

    &.pending {
        background-color: #ffa99a;
        color: white;
        padding: 0 4px;
        border-radius: 2px;

        .checkDefects {
            color: white;
            margin-left: 6px;
        }

        .popoverPosition {
            svg {
                fill: white;
            }
        }
    }

    .linkIcon {
        margin-left: 6px;
    }
`

const ProductItemInStock = styled.div`
    position: relative;
    border-radius: 4px;
    background-color: ${(props) => (props.status === 'yes' ? '#66CDAA' : '#fff')};
    box-shadow: 1px 4px 9px -3px rgba(77, 77, 77, 0.2);
    border: 1px solid orange;

    p {
        position: absolute;
        z-index: 20;
        left: 6px;
        top: -8px;
        font-size: 8px;
        line-height: 8px;
        white-space: nowrap;
        padding: 2px 4px;
        border-radius: 2px;
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
        color: #389e0d;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 4px 6px;
        color: ${(props) => (props.status === 'yes' ? '#fff' : '#000')};
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    span {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        font-size: 8px;
        line-height: 8px;
        border-radius: 50%;
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const AddProductLink = styled.div`
    display: flex;
    align-items: center;

    a {
        color: inherit;
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }

        &:hover {
            color: #1890ff;
        }
    }
`

const AddTrackerWrap = styled.div`
    display: flex;
    align-items: center;

    span {
        &.delivery {
            margin-left: 6px;
        }
    }
`

const UserNameControl = styled.div`
    display: inline-flex;
    align-items: center;
`

const deliveryJSX = {
    samovivoz: 'Самовывоз',
    belpochta: 'Белпочта',
    evropochta: 'Европочта',
    geoMinsk: 'Доставка'
}

function AdminTableOrdersPage() {
    const dispatch = useDispatch()
    const token = useSelector((state) => state.token)

    const { orders } = useSelector((state) => state.admin)
    const isLoading = useSelector((state) => state.admin.isLoading)
    const params = useParams()
    const history = useHistory()

    const search = window.location.search
    const paramsAll = new URLSearchParams(search)
    const titlePurshuase = paramsAll.get('title')
    const articleOrder = paramsAll.get('articleOrder')

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)

    const [isLoadingFormula, setIsLoadingFormula] = useState(false)
    const [formula, setFormula] = useState()
    const [errorFormula, setErrorFormula] = useState(null)

    const [page, setPage] = useState(1)
    const [backPage, setBackPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const [isLoadingPurchase, setIsLoadingPurchase] = useState(false)
    const [purchase, setPurchase] = useState(null)

    const filteredOrders = orders?.filter((item) => (item.products.length > 0 || item.isAdmin) && !item.isManager)
    const searchItem = filteredOrders?.filter((item) => item.articleOrder === +articleOrder)

    const arr = searchItem ? searchItem : []
    const dataTable = articleOrder ? arr : filteredOrders

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    function handleReset(clearFilters) {
        clearFilters()
        setSearchText('')
    }

    const onShowSizeChange = (current, size) => {
        setPageSize(size)
    }

    const addPenalty = async (phone, userId, msg, orderId) => {
        try {
            const { data } = await axios.post(
                `/penalty/add-penalty/`,
                { phone, message: msg, userId, orderId },
                {
                    headers: { Authorization: token }
                }
            )

            if (data.status === 'success') {
                message.success(data.message)
                dispatch(getOrdersAdmin(params.id, token))
            }
        } catch (err) {
            console.log('addPenalty', err)
        }
    }

    const deletePenalty = async (phone, userId, orderId) => {
        try {
            const { data } = await axios.post(
                `/penalty/delete-penalty/`,
                { phone, userId, orderId },
                {
                    headers: { Authorization: token }
                }
            )

            if (data.status === 'error') {
                message.error(data.message)
            }

            if (data.status === 'success') {
                message.success(data.message)
                dispatch(getOrdersAdmin(params.id, token))
            }
        } catch (err) {
            console.log('addPenalty', err)
        }
    }

    const columns = useMemo(
        () => [
            {
                title: '@',
                dataIndex: 'status',
                filters: [
                    {
                        text: 'Возврат',
                        value: 'return'
                    },
                    {
                        text: 'Уточняется',
                        value: 'pending'
                    },
                    {
                        text: 'Без статуса',
                        value: null
                    }
                ],

                onFilter: (value, record) => {
                    let products = [...record.products]
                    products = record.products.filter(function (product) {
                        return product.status === value
                    })

                    return products.length
                },

                render: (text, record, index) => {
                    return <OrderStatusInTable orderId={record._id} statusCurrent={text} />
                }
            },

            {
                title: 'Номер',
                dataIndex: 'articleOrder',
                width: 50,
                ...getColumnSearchProps(
                    'articleOrder',
                    searchInput,
                    handleSearch,
                    handleReset,
                    searchedColumn,
                    searchText,
                    formula,
                    purchase
                )
            },

            {
                title: 'Логин',
                dataIndex: 'userLogin',

                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Поиск по логину`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, 'userLogin')}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />

                        <Space>
                            <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm, 'userLogin')}
                                icon={<SearchOutlined />}
                                size='small'
                                style={{ width: 90 }}
                            >
                                Поиск
                            </Button>

                            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                                Сброс
                            </Button>
                        </Space>
                    </div>
                ),

                filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

                onFilter: (value, { author, info, userLogin }) =>
                    (info?.instaLogin && info?.instaLogin.toString().toLowerCase().includes(value.toLowerCase())) ||
                    (userLogin && userLogin.toString().toLowerCase().includes(value.toLowerCase())),

                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current.select())
                    }
                },

                render: (text, record, index) => {
                    return (
                        <>
                            {record.personalPercentAdmdin && (
                                <div>
                                    <Tag color='purple'>Скидка: {record.personalPercentAdmdin}</Tag>
                                </div>
                            )}

                            <SocialLogin record={record} />
                        </>
                    )
                }
            },

            {
                title: 'ФИО',
                dataIndex: 'userName',
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Поиск по тел и ФИО`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, 'userName')}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />

                        <Space>
                            <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm, 'userName')}
                                icon={<SearchOutlined />}
                                size='small'
                                style={{ width: 90 }}
                            >
                                Поиск
                            </Button>

                            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                                Сброс
                            </Button>
                        </Space>
                    </div>
                ),

                filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

                onFilter: (value, record) =>
                    record.info.phone.toString().toLowerCase().includes(value.toLowerCase()) ||
                    record.info.userName.toString().toLowerCase().includes(value.toLowerCase()),

                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current.select())
                    }
                },

                render: (text, record, index) => {
                    return (
                        <>
                            {record.isAdmin && (
                                <AddProductLink>
                                    <Tag color='green'>ByAdmin</Tag>

                                    <NavLink to={`/purchase/${params.id}/add-product/${record._id}`}>
                                        <PlusCircleOutlined />
                                    </NavLink>

                                    <ModalAddCommenInOrder order={record} />
                                    <ModalEditOrderFromAdmin order={record} />
                                </AddProductLink>
                            )}

                            <AddProductLink>
                                {record.info.userName.length >= 15 ? (
                                    <Tooltip title={`${record.info.userName}`}>
                                        <span>
                                            {record.info.userName.length >= 15 ? (
                                                <ModalOrdersAdmin
                                                    orderId={record._id}
                                                    isAdmin={record.isAdmin}
                                                    userName={`${record.info.userName.substr(0, 15)}...`}
                                                />
                                            ) : (
                                                <ModalOrdersAdmin
                                                    orderId={record._id}
                                                    isAdmin={record.isAdmin}
                                                    userName={record.info.userName}
                                                />
                                            )}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <ModalOrdersAdmin
                                        orderId={record._id}
                                        isAdmin={record.isAdmin}
                                        userName={record.info.userName}
                                    />
                                )}
                            </AddProductLink>

                            {/*<span>{record.info.phone}</span>*/}

                            <AddPenalty
                                phone={record.info.phone}
                                isBan={record?.info?.isBlackList}
                                addPenalty={addPenalty}
                                deletePenalty={deletePenalty}
                                orderId={record._id}
                            />

                            <br />

                            <UserNameControl>
                                {!record.isAdmin && <ModalEditOrderFromAdmin order={record} />}
                                {!record.isAdmin && <ModalAddCommenInOrder order={record} />}
                            </UserNameControl>
                        </>
                    )
                }
            },

            {
                title: 'Адрес',
                dataIndex: 'address',
                render: (text, record, index) => {
                    return (
                        <>
                            {record?.info.address && record.info.address.length >= 15 ? (
                                <Tooltip title={`${record.info.address}`}>
                                    <span>
                                        {record.info.address.length >= 15
                                            ? `${record.info.address.substr(0, 15)}...`
                                            : record.info.address}
                                    </span>
                                </Tooltip>
                            ) : (
                                record.info.address
                            )}

                            <AddTrackerWrap>
                                <CarOutlined /> <span className='delivery'>{deliveryJSX[record.info.delivery]}</span>
                                {record.info.delivery === 'belpochta' || record.info.delivery === 'evropochta' ? (
                                    <ModalAddTracker order={record} />
                                ) : null}
                            </AddTrackerWrap>
                        </>
                    )
                }
            },

            {
                title: 'Пристрой',
                dataIndex: 'products',
                render: (text, record, index) => {
                    if (record?.productsInStock && record?.productsInStock?.length) {
                        return record.productsInStock.map((item, index) => (
                            <ProductItemInStock status={item?.status} key={index}>
                                <Link to={`/admin/in-stock/?productID=${item._id}`}>
                                    {item.nameProduct}
                                    <span>ПР</span>
                                </Link>

                                <p>{item.price} бел.руб</p>
                            </ProductItemInStock>
                        ))
                    }

                    return <div>---</div>
                }
            },

            {
                title: '№',
                dataIndex: 'productNumber',

                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Поиск по логину`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, 'userLogin')}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />

                        <Space>
                            <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm, 'userLogin')}
                                icon={<SearchOutlined />}
                                size='small'
                                style={{ width: 90 }}
                            >
                                Поиск
                            </Button>

                            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                                Сброс
                            </Button>
                        </Space>
                    </div>
                ),

                filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

                onFilter: (value, { author, info, products }) => {
                    const product = products && products.find((item) => item.productNumber === +value)
                    return (
                        product &&
                        product.productNumber &&
                        product.productNumber.toString().toLowerCase().includes(value.toLowerCase())
                    )
                },

                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current.select())
                    }
                },

                render: (text, record, index) => {
                    return record.products.map((item, productIndex) => (
                        <p key={`productNumber_${productIndex}`}>{item.productNumber}</p>
                    ))
                }
            },

            {
                title: 'Продукты',
                dataIndex: 'products',
                render: (text, record, index) => {
                    const arrProducts = text.map((item) => {
                        return { ...item, type: 'default' }
                    })

                    let arrProductsInStock = []

                    if (record?.productsInStock) {
                        record.productsInStock.map((item) => {
                            arrProductsInStock.push({
                                ...item,
                                type: 'inStock'
                            })
                        })
                    }

                    // const arrProductsInStock = record?.productsInStock.map((item) => {
                    //     return { ...item, type: 'inStock' }
                    // })

                    const allArr = [...arrProducts, ...arrProductsInStock]

                    // console.log('allArr', allArr)

                    // return allArr.map((item, index) => {
                    //     return item.type === 'inStock' ? (
                    //         <ProductItemInStock key={index}>
                    //             <Link to={`/admin/in-stock/?productID=${item._id}`}>
                    //                 {item.nameProduct}
                    //                 <span>ПР</span>
                    //             </Link>
                    //
                    //             <p>{item.price} бел.руб</p>
                    //         </ProductItemInStock>
                    //     ) : (
                    //         <ProductItem key={item._id} className={item.status}>
                    //             {item.nameProduct.length >= 15 ? (
                    //                 <Tooltip title={`${item.nameProduct}`}>
                    //                     <span>
                    //                         {item.nameProduct.length >= 15
                    //                             ? `${item.nameProduct.substr(0, 15)}...`
                    //                             : item.nameProduct}
                    //                     </span>
                    //                 </Tooltip>
                    //             ) : (
                    //                 item.nameProduct
                    //             )}
                    //
                    //             {item.checkDefects && <b className='checkDefects'>(Б)</b>}
                    //
                    //             {item.commentAdmin && (
                    //                 <PopoverComment
                    //                     comment={item.commentAdmin}
                    //                     className='popoverPosition'
                    //                 />
                    //             )}
                    //
                    //             {item.productLink ? (
                    //                 <a
                    //                     href={`${item.productLink}`}
                    //                     target='_blank'
                    //                     rel='noopener noreferrer'
                    //                 >
                    //                     <LinkOutlined className='linkIcon' />
                    //                 </a>
                    //             ) : null}
                    //         </ProductItem>
                    //     )
                    // })

                    // if (record?.productsInStock?.length) {
                    //     return record?.productsInStock.map((item, index) => (
                    //         <ProductItemInStock key={index}>
                    //             <Link to={`/admin/in-stock/?productID=${item._id}`}>
                    //                 {item.nameProduct}
                    //                 <span>ПР</span>
                    //             </Link>
                    //
                    //             <p>{item.price} бел.руб</p>
                    //         </ProductItemInStock>
                    //     ))
                    // }
                    //
                    return text.map((item) => (
                        <ProductItem key={item._id} className={item.status}>
                            {item.nameProduct.length >= 15 ? (
                                <Tooltip title={`${item.nameProduct}`}>
                                    <span>
                                        {item.nameProduct.length >= 15
                                            ? `${item.nameProduct.substr(0, 15)}...`
                                            : item.nameProduct}
                                    </span>
                                </Tooltip>
                            ) : (
                                item.nameProduct
                            )}

                            {item.checkDefects && <b className='checkDefects'>(Б)</b>}

                            {item.commentAdmin && (
                                <PopoverComment comment={item.commentAdmin} className='popoverPosition' />
                            )}

                            {item.productLink ? (
                                <a href={`${item.productLink}`} target='_blank' rel='noopener noreferrer'>
                                    <LinkOutlined className='linkIcon' />
                                </a>
                            ) : null}
                        </ProductItem>
                    ))
                }
            },

            {
                title: 'Цвет',
                dataIndex: 'products',
                render: (text, record, index) => {
                    return text.map((item) => (
                        <Fragment key={item._id}>
                            {item.color.length >= 10 ? (
                                <Tooltip title={`${item.color}`}>
                                    <span>
                                        {item.color.length >= 10 ? (
                                            <ProductItem key={item._id}>{`${item.color.substr(0, 10)}...`}</ProductItem>
                                        ) : (
                                            <ProductItem key={item._id}>{item.color}</ProductItem>
                                        )}
                                    </span>
                                </Tooltip>
                            ) : (
                                <ProductItem>{item.color}</ProductItem>
                            )}
                        </Fragment>
                    ))
                }
            },

            {
                title: 'Размер',
                dataIndex: 'products',
                render: (text, record, index) => {
                    return text.map((item) =>
                        item.size.length >= 8 ? (
                            <Tooltip title={`${item.size}`} key={item._id}>
                                <span>
                                    {item.size.length >= 8 ? (
                                        <ProductItem key={item._id}>{`${item.size.substr(0, 8)}...`}</ProductItem>
                                    ) : (
                                        <ProductItem key={item._id}>{item.size}</ProductItem>
                                    )}
                                </span>
                            </Tooltip>
                        ) : (
                            <ProductItem key={item._id}>{item.size}</ProductItem>
                        )
                    )
                }
            },

            {
                title: 'Кол-во',
                dataIndex: 'products',
                render: (text, record, index) => {
                    return text.map((item) => (
                        <ProductItem key={item._id} data-preQuantity={item.preQuantity ? 'Y' : 'N'}>
                            {!item.preQuantity ? `${item.quantity}` : `${item.preQuantity} из ${item.quantity}`}
                        </ProductItem>
                    ))
                }
            },

            {
                title: 'Цена рос.руб',
                dataIndex: 'products',
                width: 75,
                render: (text, record, index) => {
                    return text.map((item) => <ProductItem key={item._id}>{item.price}</ProductItem>)
                }
            },

            {
                title: 'Общая рос.руб',
                dataIndex: 'products',
                width: 75,
                render: (text, record, index) => {
                    if (text.length !== 0) {
                        return <TotalPriceRU items={text} />
                    }
                    return 0
                }
            },

            {
                title: 'Общая бел.руб',
                dataIndex: 'products',
                width: 75,
                render: (text, record, index) => {
                    if (text.length !== 0 || (record?.productsInStock && record?.productsInStock?.length > 0)) {
                        return (
                            <TotalSumProductsBy
                                order={record}
                                products={text}
                                productsInStock={record?.productsInStock}
                                formula={formula}
                                personalPercent={record?.author?.personalPercent}
                                personalPercentAdmdin={record?.personalPercentAdmdin}
                                purchaseCourse={purchase && purchase[0]?.purchaseCourse}
                            />
                        )
                    }
                    return 0
                }
            }
        ],
        [params.id, searchText, searchedColumn, formula, purchase]
    )

    const onChangeHandle = (pagination, filters, sorter, extra) => {
        if (extra.action === 'paginate') {
            setPage(pagination.current)
        }
        return
    }

    const showModal = () => {
        setIsModalVisible((prev) => !prev)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    useEffect(() => {
        dispatch(getOrdersAdmin(params.id, token))
    }, [token, params.id, dispatch])

    useEffect(() => {
        const getFormula = async () => {
            try {
                const { data } = await axios.get(`/settings/formula`, {
                    headers: { Authorization: token }
                })

                setFormula(data)
                setIsLoadingFormula(false)
            } catch (err) {
                setErrorFormula(err.response?.data?.msg)
                setIsLoadingFormula(false)
            }
        }
        getFormula()
    }, [token])

    useEffect(() => {
        const getPurchase = async () => {
            setIsLoadingPurchase(true)
            try {
                const res = await axios.get(`/purchase/${params.id}`, {
                    headers: { Authorization: token }
                })
                setPurchase(res.data)
                setIsLoadingPurchase(false)
            } catch (err) {
                console.log('err', err)
                setIsLoadingPurchase(false)
            }
        }
        getPurchase()
    }, [token, params.id])

    if (isLoadingFormula || isLoading || isLoadingPurchase) {
        return <div className='container'>Loading...</div>
    }

    if (errorFormula) {
        return <div className='container'>Что-то пошло не так... Попробуйте перезагрузить страницу.</div>
    }

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <TitleWrap>
                        <h2>Итоговая таблица по закупке - {titlePurshuase && titlePurshuase}:</h2>

                        <Space>
                            <Button variant='secondary' onClick={showModal}>
                                Добавить заказ
                            </Button>

                            {articleOrder && (
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        history.push({
                                            pathname: `/admin/table-orders/${purchase[0]._id}?title=${titlePurshuase}`,
                                            search: ''
                                        })
                                    }}
                                >
                                    Сбросить
                                </Button>
                            )}
                        </Space>
                    </TitleWrap>

                    <TableWrap>
                        <Table
                            columns={columns}
                            // dataSource={filteredOrders}
                            dataSource={dataTable}
                            onChange={onChangeHandle}
                            rowKey='_id'
                            rowClassName={(record) => record?.status && `${record?.status}`}
                            pagination={{
                                pageSize: pageSize,
                                total: filteredOrders.length,
                                current: page,
                                onShowSizeChange: onShowSizeChange
                            }}
                        />
                    </TableWrap>
                </div>
            </PageInner>

            <Modal
                title='Оформление заказа от имени админа:'
                visible={isModalVisible}
                footer={null}
                onCancel={handleCancel}
            >
                <AddOrderInfoFromAdmin
                    paramId={params.id}
                    orders={filteredOrders}
                    showModal={showModal}
                    titlePurchase={titlePurshuase}
                />
            </Modal>
        </section>
    )
}

export default AdminTableOrdersPage
