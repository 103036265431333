import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Space, message, Popover, Popconfirm, Modal, Form, Input, InputNumber } from 'antd'

const REQUIRED_FIELD = 'Обязательное поле!'

const Images = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 10px;

    img {
        width: 100%;
        max-width: calc(33.333333% - 10px);
        object-fit: cover;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
    }
`

const InputImageWrap = styled.div`
    margin-bottom: 20px;
`

function FormAddProductInStock({
    form,
    name,
    initialValues = {},
    onFinish,
    onFinishFailed,
    previewImages,
    uploadImage,
    isLoading,
    textBtn,
    setImagesBase64
}) {
    // const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue({ ...initialValues })

        return () => {
            form.resetFields()
            // setImagesBase64(null)
        }
    }, [initialValues])

    return (
        <Form
            form={form}
            name={name}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            size='large'
            initialValues={{ ...initialValues }}
        >
            {previewImages && previewImages.length > 0 && (
                <Images>
                    {previewImages.map((item, index) => (
                        <img src={item} alt='' key={index} />
                    ))}
                </Images>
            )}

            <InputImageWrap>
                <input
                    type='file'
                    name='photo'
                    multiple
                    onChange={(e) => {
                        uploadImage(e)
                    }}
                />
            </InputImageWrap>

            <Form.Item
                className='label'
                name='nameProduct'
                rules={[
                    {
                        required: true,
                        message: 'Название товара - ' + REQUIRED_FIELD
                    }
                ]}
            >
                <Input size='large' placeholder='Наименование товара*' />
            </Form.Item>

            <Form.Item
                name='size'
                rules={[
                    {
                        required: true,
                        message: 'Размер - ' + REQUIRED_FIELD
                    }
                ]}
            >
                <Input placeholder='Размер*' />
            </Form.Item>

            <Form.Item
                name='price'
                rules={[
                    {
                        required: true,
                        message: 'Цена - ' + REQUIRED_FIELD
                    },

                    {
                        validator(rule, value) {
                            if (value < 0) {
                                return Promise.reject('Цена не может быть меньше нуля!')
                            }
                            return Promise.resolve()
                        }
                    }
                ]}
            >
                <InputNumber placeholder='Цена (в бел.руб.)*' type='number' />
            </Form.Item>

            <Form.Item
                name='comment'
                rules={[
                    {
                        required: true,
                        message: 'Описание - ' + REQUIRED_FIELD
                    }
                ]}
            >
                <Input.TextArea placeholder='Описение/Комментарий' autoSize={{ minRows: 6 }} />
            </Form.Item>

            <Form.Item>
                <Button type='primary' htmlType='submit' size='large' disabled={isLoading}>
                    {/* Добавить товар */}

                    {textBtn}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default FormAddProductInStock
