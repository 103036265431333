import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Table, Tag, Input, Space, Button, Popover, message } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { PageInner } from '../../components/shared/PageInner'
import EditPersonalPercent from '../../components/EditPersonalPercent/EditPersonalPercent'
import AddPenalty from './AddPenalty'

const TitleWrap = styled.div`
    text-align: center;
    margin-bottom: 15px;

    h2 {
        margin-bottom: 15px;
    }
`

const TableWrap = styled.div`
    overflow-x: scroll;

    width: 100%;
    margin-top: 30px;

    table {
        .ant-table-cell {
            padding: 8px;
        }

        .ant-table-row {
            &.packed {
                background-color: #fff9a8;
            }

            &.paidFor {
                background-color: #ffd7d0a7;
            }

            &.completed {
                background-color: #affaea;
            }
        }

        .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
        .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
            background: #f5f1f1;
        }

        .ant-table-filter-column {
            svg {
                color: inherit;
                width: 16px;
                height: 16px;
            }
        }
    }
`

function AdminTableClientsPage() {
    const token = useSelector((state) => state.token)

    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])

    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const searchInput = useRef(null)

    function handleSearch(selectedKeys, confirm, dataIndex) {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    function handleReset(clearFilters) {
        clearFilters()
        setSearchText('')
    }

    const handleUpdatePersonalPercent = useCallback(
        (personalPercent, id) => {
            const newArr = users.map((item) => {
                if (item._id === id) {
                    const newUser = { ...item, personalPercent: personalPercent }
                    return newUser
                }
                return item
            })
            setUsers(newArr)
        },
        [users]
    )

    const handleDeletePersonalPercent = useCallback(
        (id) => {
            const newArr = users.map((item) => {
                if (item._id === id) {
                    const newUser = { ...item, personalPercent: null }
                    return newUser
                }
                return item
            })
            setUsers(newArr)
        },
        [users]
    )

    const addPenalty = async (phone, userId, msg) => {
        try {
            setIsLoading(true)
            const { data } = await axios.post(
                `/penalty/add-penalty/`,
                { phone, message: msg, userId },
                {
                    headers: { Authorization: token }
                }
            )

            if (data.status === 'success') {
                message.success(data.message)

                const arr = users.map((item) => {
                    if (item._id === userId) {
                        return { ...item, isBlackList: true }
                    }

                    return item
                })

                setUsers(arr)
            }

            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log('addPenalty', err)
        }
    }

    const deletePenalty = async (phone, userId) => {
        try {
            setIsLoading(true)
            const { data } = await axios.post(
                `/penalty/delete-penalty/`,
                { phone, userId },
                {
                    headers: { Authorization: token }
                }
            )

            if (data.status === 'success') {
                message.success(data.message)

                const arr = users.map((item) => {
                    if (item._id === userId) {
                        return { ...item, isBlackList: false }
                    }

                    return item
                })

                setUsers(arr)
            }

            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log('addPenalty', err)
        }
    }

    const columns = useMemo(
        () => [
            {
                title: 'Email',
                dataIndex: 'email'
            },

            {
                title: 'Телефон',
                dataIndex: 'phoneLogin',
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={searchInput}
                            placeholder={`Поиск по телефону`}
                            value={selectedKeys[0]}
                            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, confirm, 'phoneLogin')}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />

                        <Space>
                            <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm, 'phoneLogin')}
                                icon={<SearchOutlined />}
                                size='small'
                                style={{ width: 90 }}
                            >
                                Поиск
                            </Button>

                            <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
                                Сброс
                            </Button>
                        </Space>
                    </div>
                ),

                filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

                onFilter: (value, record) =>
                    record.phoneLogin && record.phoneLogin.toString().toLowerCase().includes(value.toLowerCase()),

                onFilterDropdownVisibleChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current.select())
                    }
                },

                render: (text, record, index) => {
                    // return text

                    return (
                        // <Popover content={contentBlackList} title='Черный список' trigger='click'>
                        //     <Button>{text}</Button>
                        // </Popover>

                        <AddPenalty
                            phone={record?.phoneLogin}
                            isBan={record?.isBlackList}
                            addPenalty={addPenalty}
                            deletePenalty={deletePenalty}
                            user={record}
                        />
                    )
                }
            },

            {
                title: 'Дата регистарции',
                dataIndex: 'createdAt',
                render: (text, record, index) => {
                    const today = new Date(text)
                    return <p>{today.toLocaleString('ru-US')}</p>
                }
            },

            {
                title: 'Роль',
                dataIndex: 'role',
                render: (text, record, index) => {
                    if (text === 1) return <Tag color='volcano'>Админ</Tag>
                    if (text === 0) return <Tag color='cyan'>Клиент</Tag>
                    if (text === 3) return <Tag color='green'>Менеджер</Tag>
                }
            },

            {
                title: 'Процент',
                dataIndex: 'personalPercent',
                render: (text, record, index) => {
                    return (
                        <EditPersonalPercent
                            currentPercent={text}
                            userId={record._id}
                            handleUpdatePersonalPercent={handleUpdatePersonalPercent}
                            handleDeletePersonalPercent={handleDeletePersonalPercent}
                        />
                    )
                }
            }
        ],
        [users]
    )

    const onChangeHandle = (pagination, filters, sorter, extra) => {
        console.log('pagination', pagination)
    }

    useEffect(() => {
        setIsLoading(true)

        const getUsers = async () => {
            try {
                const res = await axios.get(`/user/all_infor`, {
                    headers: { Authorization: token }
                })
                setUsers(res.data)
                setIsLoading(false)
            } catch (err) {
                setIsLoading(false)
                console.log('err', err)
            }
        }

        getUsers()
    }, [token])

    return (
        <section>
            <PageInner>
                <div className='container'>
                    <TitleWrap>
                        <h2>Клиенты:</h2>
                    </TitleWrap>

                    {!isLoading ? (
                        <TableWrap>
                            <Table columns={columns} dataSource={users} onChange={onChangeHandle} rowKey='_id' />
                        </TableWrap>
                    ) : (
                        <p>Loading....</p>
                    )}
                </div>
            </PageInner>
        </section>
    )
}

export default AdminTableClientsPage
